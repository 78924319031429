import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import { makeStyles } from '@material-ui/core/styles';
import ptlocales from '@fullcalendar/core/locales/pt-br';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Menu from '../Menu';
import api from '../../services/api';
import { getClinica } from '../../services/auth';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
  CircularProgress,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { usePermissoes } from '../../hooks/permissoes';
import Notificacao from '../../utils/notificacao';
import PersonIcon from '@material-ui/icons/Person';

import './styles.css';
import { useConfig } from '../../hooks/config';
import { calcularIdade } from '../../utils/funcoes';
import { Autocomplete } from '@material-ui/lab';

export default function Agenda() {
  let permissaoNotificao = '';
  const [loading, setLoading] = useState(true);
  const [desativar, setDesativar] = useState(false);
  const { permissoes } = usePermissoes();
  const { configuracoes } = useConfig();
  const [eventos, setEventos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [dentistas, setDentistas] = useState([]);
  const [feriados, setFeriados] = useState([]);
  const [abrirModal, setAbrirModal] = useState(false);
  const [ModalCadastro, setAbrirModalCadastro] = useState(false);
  const [ModalConfirmExclusao, setModalConfirmExclusao] = useState(false);
  const [pesquisaDentista, setPesquisaDentista] = useState('');
  const [title, setTitle] = useState(null);
  const [idTratamento, setIdTratamento] = useState(null);
  const [idEvento, setIdEvento] = useState('');
  const [idCliente, setIdCliente] = useState(null);
  const [idDentista, setIdDentista] = useState(null);
  const [dataInicio, setDataIncio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [horaInicio, setHoraInicio] = useState('');
  const [horaFim, setHoraFim] = useState('');
  const [telefoneCliente, setTelefoneCliente] = useState(null);
  const [celularCliente, setCelularCliente] = useState(null);
  const [dataNascCliente, setDataNascCliente] = useState(null);
  const [obsEvento, setObsEvento] = useState('');
  const [presenca, setPresenca] = useState('#6c757d');
  const [descFeriado, setDescricaoFeriado] = useState('');
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // useEffect(()=>{
  //     let interval = setInterval(()=>{
  //         verificarAgendamento();
  //     }, 10000);
  //     return () => clearInterval(interval);
  // },[eventos])

  // const verificarAgendamento = () =>{
  //     eventos.map(e => {
  //         let hoje = new Date();
  //         let agendamento = new Date(e.start);
  //         console.log(agendamento === hoje.);
  //         if(agendamento === hoje){
  //             let img = '/to-do-notifications/img/icon-128.png';
  //             let text = 'HEY! Your task "' + 'Teste' + '" is now overdue.';
  //             let notification = new Notification('To do list', { body: text, icon: img });
  //         }
  //     })

  // }

  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    select: {
      marginBottom: theme.spacing(1),
    },
    textField: {
      marginTop: theme.spacing(1),
    },
    buttonLinkUser: {
      marginTop: '17px',
      height: '55px',
    },
  }));

  const atualizar = async () => {
    // Notification.requestPermission();
    await api
      .get('Agenda/list.php', { params: { pdo: getClinica() } })
      .then(res => {
        setEventos(res.data);
      })
      .catch(error => {
        console.log(error);
      });
    await api
      .get('Feriado/list.php', { params: { pdo: getClinica() } })
      .then(res => {
        const feriados = [];
        setFeriados(res.data);
        let ano = new Date();
        for (let i = 0; i < res.data.length; i++) {
          feriados.push({
            start: `${ano.getFullYear()}-${res.data[i].mes}-${res.data[i].dia}`,
            end: `${ano.getFullYear()}-${res.data[i].mes}-${res.data[i].dia}`,
            display: 'background',
            color: '#FF0000',
          });
        }
        setEventos(eventos => eventos.concat(feriados));
      });
    await api
      .get('Usuario/list_select.php', { params: { pdo: getClinica() } })
      .then(res => {
        setClientes([{ id: '1', nome: 'Cliente sem cadastro' }, ...res.data]);
      })
      .catch(error => {
        console.log(error);
      });
    await api
      .post(`Usuario/list_func_select.php?pdo=${getClinica()}`)
      .then(res => {
        setDentistas(res.data);
      })
      .catch(error => {
        console.log(error);
      });
    setLoading(false);
  };

  const agendamentosDentista = async () => {
    setLoading(true);
    if (pesquisaDentista === '-1') {
      atualizar();
    } else {
      await api
        .get(
          'Agenda/list_Dentista.php?id=' +
            pesquisaDentista +
            '&pdo=' +
            getClinica(),
        )
        .then(res => {
          setEventos(res.data);
          setLoading(false);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const fecharModal = () => {
    setAbrirModal(false);
  };

  const abrirModalCadastro = arg => {
    limparCampos();
    if (!existeFeriado(arg.dateStr)) {
      setDataIncio(arg.dateStr);
      setAbrirModalCadastro(true);
    } else {
      abrirAviso('info', 'Feriado: ' + descFeriado);
    }
  };
  const fecharModalCadastro = () => {
    setAbrirModalCadastro(false);
    limparCampos();
  };

  const eventoClick = arg => {
    let dia = new Date(arg.event.start);
    let diaFuturo =
      dia.getFullYear() +
      '-' +
      ('0' + (dia.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + dia.getDate()).slice(-2);
    if (!existeFeriado(diaFuturo)) {
      let dados = arg.event.extendedProps;

      setIdEvento(arg.event.id);
      let horaI = new Date(arg.event.start),
        horaF = new Date(arg.event.end);
      setHoraInicio(
        ('00' + horaI.getHours()).slice(-2) +
          ':' +
          ('00' + horaI.getMinutes()).slice(-2),
      );
      setHoraFim(
        ('00' + horaF.getHours()).slice(-2) +
          ':' +
          ('00' + horaF.getMinutes()).slice(-2),
      );
      setDataIncio(
        horaI.getFullYear() +
          '-' +
          ('00' + (horaI.getMonth() + 1)).slice(-2) +
          '-' +
          horaI.getDate(),
      );
      setDataFim(
        horaF.getFullYear() +
          '-' +
          ('00' + (horaF.getMonth() + 1)).slice(-2) +
          '-' +
          horaF.getDate(),
      );
      if (arg.event.backgroundColor === '') {
        setPresenca(0);
      } else {
        setPresenca(arg.event.backgroundColor);
      }

      dados.idTratamento ? setDesativar(true) : setDesativar(false);
      setObsEvento(dados.observacao);
      setTitle(arg.event.title);
      setIdTratamento(dados.idTratamento);
      setIdCliente({ id: dados.idCliente, nome: nomeCliente(dados.idCliente) });
      setIdDentista(dados.idDentis);
      setTelefoneCliente(dados.telefone);
      setCelularCliente(dados.celular);
      setDataNascCliente(dados.dataNasc);
      setAbrirModal(true);
    } else {
      abrirAviso('info', 'Feriado: ' + descFeriado);
    }
  };

  const nomeCliente = id => {
    for (let i = 0; i < clientes.length; i++) {
      if (clientes[i].id === id) {
        return clientes[i].nome;
      }
    }
  };

  const validarCampos = () => {
    if (horaInicio !== '' && horaFim !== '' && idCliente?.id && idDentista) {
      return true;
    }
    return false;
  };

  const existeFeriado = data => {
    let ano = new Date();
    for (let i = 0; i < feriados.length; i++) {
      if (
        data ===
        ano.getFullYear() + '-' + feriados[i].mes + '-' + feriados[i].dia
      ) {
        setDescricaoFeriado(feriados[i].descricao);
        return true;
      }
    }
    return false;
  };

  const limparCampos = () => {
    setIdEvento('');
    setIdCliente(null);
    setIdDentista(null);
    setDataIncio('');
    setDataFim('');
    setHoraInicio('');
    setHoraFim('');
    setObsEvento('');
    setPresenca('');
  };

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const fecharAviso = () => {
    setAviso(false);
  };

  const alterarEvento = async () => {
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.agenAlt !== '0' && permissoes !== false) {
        if (validarCampos()) {
          let titulo = null;
          if (idCliente.id === '1') {
            titulo = 'S/C: ' + obsEvento;
          } else {
            if (idTratamento) {
              titulo = title;
            } else {
              titulo = idCliente.nome;
            }
          }

          await api
            .post('Agenda/update.php', {
              pdo: getClinica(),
              title: titulo,
              color: presenca,
              start: dataInicio + ' ' + horaInicio,
              end: dataFim + ' ' + horaFim,
              idCli: idCliente.id,
              idDentis: idDentista,
              observacao: obsEvento,
              id: idEvento,
            })
            .then(res => {
              abrirAviso('info', 'Agendamento atualizado.');
              setAbrirModal(false);
              limparCampos();
              setEventos(state =>
                state.map(e => {
                  if (e.id !== res.data.id) {
                    return e;
                  }
                  e = res.data;
                  return e;
                }),
              );
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          abrirAviso('warning', 'Preencha todos os campos.');
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
  };

  const excluirEvento = () => {
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.agenDel !== '0' && permissoes !== false) {
        api
          .post('Agenda/remove.php?id=' + idEvento + '&pdo=' + getClinica())
          .then(() => {
            abrirAviso('info', 'Agendamento removido.');
            limparCampos();
            setEventos(eventos => eventos.filter(e => e.id !== idEvento));
            setModalConfirmExclusao(false);
            setAbrirModal(false);
          });
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
  };

  const cadastrarEvento = async () => {
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.agenCad !== '0' && permissoes !== false) {
        if (validarCampos()) {
          let titulo = '';
          if (idCliente?.id === '1') {
            titulo = 'S/C: ' + obsEvento;
          } else {
            titulo = idCliente.nome;
          }
          await api
            .post('Agenda/save.php', {
              pdo: getClinica(),
              title: titulo,
              color: presenca,
              start: dataInicio + ' ' + horaInicio,
              end: dataInicio + ' ' + horaFim,
              idCli: idCliente.id,
              idDentis: idDentista,
              observacao: obsEvento,
              idTratamento: null,
            })
            .then(res => {
              abrirAviso('success', 'Evento Cadastrado.');
              limparCampos();
              setEventos([...eventos, res.data]);
              fecharModalCadastro();
            })
            .catch(error => console.log(error));
        } else {
          abrirAviso('warning', 'Preencha os campos obrigatórios.');
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
  };

  const classes = useStyles();

  const direcionarPaginaCliente = () => {
    let cod = btoa(
      JSON.stringify({
        id: idCliente.id,
        nome: idCliente.nome,
      }),
    );
    window.location.pathname = `/cad_edit_paciente/${cod}`;
  };

  useEffect(() => {
    atualizar();
  }, []);

  useEffect(() => {
    let data = new Date(),
      diaVencimento = parseInt(configuracoes.diaVencimento) - 1;
    if (diaVencimento === data.getDate()) {
      abrirAviso(
        'warning',
        'Sua fatura vence amanhã. Caso tenha efetuado o pagamento desconsidere essa mensagem.',
      );
    }
  }, [configuracoes]);

  return (
    <div>
      <Menu titulo="Agenda" />
      <div style={{ marginTop: '80px' }}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              select
              fullWidth
              label="Profissional"
              size="small"
              className={classes.select}
              value={pesquisaDentista}
              onChange={e => setPesquisaDentista(e.target.value)}
              variant="outlined"
            >
              <MenuItem value="-1">Todos</MenuItem>
              {dentistas.map(dentista => (
                <MenuItem key={dentista.id} value={dentista.id}>
                  {dentista.nome}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <Button
              size="medium"
              onClick={agendamentosDentista}
              variant="contained"
              color="primary"
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </div>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <div>
          <FullCalendar
            initialView="dayGridMonth"
            height={800}
            plugins={[
              listPlugin,
              interactionPlugin,
              timeGridPlugin,
              dayGridPlugin,
            ]}
            headerToolbar={{
              left: 'prev,next,today',
              center: 'title',
              right: 'timeGridDay,timeGridWeek,dayGridMonth,listWeek',
            }}
            dayMaxEvents={4}
            locale={ptlocales}
            events={eventos}
            eventClick={eventoClick}
            dateClick={abrirModalCadastro}
            eventColor="#6c757d"
          />
        </div>
      )}
      {/* vizualizar ou alterar agendamento */}
      <Dialog
        fullScreen={fullScreen}
        open={abrirModal}
        onClose={fecharModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{'Agendamento'}</DialogTitle>
        <DialogContent>
          {permissoes.paciente !== '0' && (
            <DialogContentText>
              Agendamento do paciente
              <br />
              <b>Idade:</b> {calcularIdade(dataNascCliente)}
              <br />
              <b>Telefone:</b> {telefoneCliente}
              <br />
              <b>Celular:</b> {celularCliente}
            </DialogContentText>
          )}
          <form className={classes.root} noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={permissoes.paciente !== '0' ? 11 : 12}>
                <Autocomplete
                  fullWidth
                  autoFocus
                  disabled={desativar}
                  className={classes.select}
                  options={clientes}
                  value={idCliente}
                  getOptionLabel={option => option.nome}
                  onChange={(event, newValue) => {
                    setIdCliente(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      className={classes.InputsOrcamento}
                      {...params}
                      label="Paciente"
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              {permissoes.paciente !== '0' && (
                <Grid item xs={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.buttonLinkUser}
                    onClick={() => {
                      direcionarPaginaCliente();
                    }}
                    title="Ir para cadastro do paciente"
                  >
                    <PersonIcon />
                  </Button>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  label="Profissional"
                  className={classes.select}
                  value={idDentista}
                  onChange={e => setIdDentista(e.target.value)}
                  variant="outlined"
                  autoFocus
                >
                  {dentistas.map(dentista => (
                    <MenuItem key={dentista.id} value={dentista.id}>
                      {dentista.nome}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Hora inicio"
                  fullWidth
                  value={horaInicio}
                  onChange={e => setHoraInicio(e.target.value)}
                  type="time"
                  defaultValue="07:30"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Hora fim"
                  fullWidth
                  value={horaFim}
                  type="time"
                  onChange={e => setHoraFim(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Observação"
                  fullWidth
                  margin="normal"
                  value={obsEvento}
                  onChange={e => setObsEvento(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  label="Presença"
                  fullWidth
                  value={presenca}
                  onChange={e => setPresenca(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="">Selecione...</MenuItem>
                  <MenuItem value="#6c757d">Aguardando</MenuItem>
                  <MenuItem value="#28a745">Confirmada</MenuItem>
                  <MenuItem value="#ff8c00">Desmarcada</MenuItem>
                  <MenuItem value="#daa520">Cliente Desmarcou</MenuItem>
                  <MenuItem value="#ff0000">Falta</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setModalConfirmExclusao(true)}
            variant="contained"
            color="secondary"
          >
            Excluir
          </Button>
          <Button onClick={alterarEvento} variant="contained" color="primary">
            Alterar
          </Button>
          <Button onClick={fecharModal} variant="contained" color="default">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      {/* cadastrar agendamento */}
      <Dialog
        fullScreen={fullScreen}
        open={ModalCadastro}
        onClose={fecharModalCadastro}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Cadastrar Agendamento'}
        </DialogTitle>
        <DialogContent>
          <form className={classes.root} noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Autocomplete
                  className={classes.select}
                  options={clientes}
                  getOptionLabel={option => option.nome}
                  onChange={(event, newValue) => {
                    setIdCliente(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      className={classes.InputsOrcamento}
                      {...params}
                      label="Paciente"
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  label="Profissional"
                  className={classes.select}
                  value={idDentista}
                  onChange={e => setIdDentista(e.target.value)}
                  variant="outlined"
                  autoFocus
                >
                  {dentistas.map(dentista => (
                    <MenuItem key={dentista.id} value={dentista.id}>
                      {dentista.nome}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Data do Agendamento"
                  fullWidth
                  type="date"
                  value={dataInicio}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Hora inicio"
                  className={classes.textField}
                  fullWidth
                  value={horaInicio}
                  onChange={e => setHoraInicio(e.target.value)}
                  type="time"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Hora fim"
                  className={classes.textField}
                  fullWidth
                  value={horaFim}
                  type="time"
                  onChange={e => setHoraFim(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Observação"
                  fullWidth
                  margin="normal"
                  value={obsEvento}
                  onChange={e => setObsEvento(e.target.value)}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={cadastrarEvento} variant="contained" color="primary">
            Cadastrar
          </Button>
          <Button
            onClick={fecharModalCadastro}
            variant="contained"
            color="default"
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      {/* confirmacao de remocao de agendamento */}
      <Dialog
        fullScreen={fullScreen}
        open={ModalConfirmExclusao}
        onClose={() => setModalConfirmExclusao(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Excluir Agendamento'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {' '}
            Tem certeza que deseja excluir o agendamento? Após executar esta
            ação não será possível recuperar o agendamento removido.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={excluirEvento} variant="contained" color="primary">
            Sim
          </Button>
          <Button
            onClick={() => setModalConfirmExclusao(false)}
            variant="contained"
            color="secondary"
          >
            Não
          </Button>
        </DialogActions>
      </Dialog>
      <Notificacao
        aviso={aviso}
        fecharAviso={fecharAviso}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </div>
  );
}
