import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from '@material-ui/core';
import ReactQuill from 'react-quill';
import api from '../../services/api';
import { getClinica } from '../../services/auth';

export default function CadastrarEditarReceituario({
  modal,
  fecharModal,
  receituario,
  receituarios,
  setReceituarios,
  abrirAviso,
  modules,
  formats,
  padrao,
}) {
  const [desabilitar, setDesabilitar] = useState(false);
  const [titulo, setTitulo] = useState(null);
  const [texto, setTexto] = useState(null);

  const salvar = () => {
    !receituario ? criar() : alterar();
  };

  const criar = async () => {
    setDesabilitar(true);
    if (titulo && texto) {
      await api
        .post('Receituario/save.php', {
          pdo: getClinica(),
          titulo: titulo,
          texto: texto,
        })
        .then(res => {
          abrirAviso('success', 'Receituário cadastrado.');
          setReceituarios([...receituarios, res.data]);
          fecharModal();
        })
        .catch(error => console.log(error));
    } else {
      abrirAviso('warning', 'Preencha todos os campos.');
    }
    setDesabilitar(false);
  };

  const alterar = async () => {
    setDesabilitar(true);
    if (titulo && texto) {
      await api
        .post('Receituario/update.php', {
          pdo: getClinica(),
          titulo: titulo,
          texto: texto,
          ativo: receituario.status,
          id: receituario.id,
        })
        .then(res => {
          console.log(res.data);
          abrirAviso('success', 'Receituário alterado.');
          setReceituarios(state =>
            state.map(e => {
              if (e.id !== res.data.id) {
                return e;
              }
              e = res.data;
              return e;
            }),
          );
          fecharModal();
        })
        .catch(error => console.log(error));
    } else {
      abrirAviso('warning', 'Preencha todos os campos.');
    }
    setDesabilitar(false);
  };

  useEffect(() => {
    if (receituario) {
      setTitulo(receituario.titulo);
      setTexto(receituario.texto);
    } else {
      setTexto(padrao);
    }
  }, [modal]);

  return (
    <>
      <Dialog
        open={modal}
        onClose={fecharModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {receituario ? 'Editar Receituário' : 'Cadastrar Receituário'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Titulo (Obrigatório)"
                value={titulo}
                onChange={e => setTitulo(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                onChange={e => setTexto(e)}
                value={texto}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={salvar}
            disabled={desabilitar}
          >
            Salvar
          </Button>
          <Button color="default" variant="contained" onClick={fecharModal}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
