import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../services/api';
import { getClinica } from '../services/auth';
const ConfigContext = createContext({});

export const ConfigProvider = ({ children }) =>{
    const [configuracoes, setConfiguracoes] = useState({});

    useEffect(()=>{
        atualizar();
    },[]);
    
    const atualizar = () => {
        api.get('Config/getConfig.php',{params:{pdo:getClinica()}})
        .then(res =>{
            setConfiguracoes(res.data[0]);
        }).catch(error => {console.log(error)}) 
    }
    return(
        <ConfigContext.Provider value={{configuracoes}}>
            {children}
        </ConfigContext.Provider>
    );
};

export function useConfig(){
    const context = useContext(ConfigContext);
    if(!context){
        throw new Error('Deve ser utilizado em um ConfigContext');
    }
    return context;
}