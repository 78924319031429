import React, { useState } from 'react';
import api from '../../../services/api';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { getClinica } from '../../../services/auth';
import { useEffect } from 'react';

export default function InserirProcedimentoEstetico({
  idOrcamento,
  listaProcedimentos,
  planoTratamento,
  setPlanoTratamento,
  idProcedimento,
  setIdProcedimento,
  valorProcedimento,
  listaProdutos,
  produto,
  setProduto,
  areaTratada,
  setAreaTratada,
  quantidade,
  setQuantidade,
  modal,
  setModal,
  abrirAviso,
  empresa,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [desabilitarButton, setDesabilitarButton] = useState(false);
  const [valor, setValor] = useState(0);
  const [valorProduto, setValorProduto] = useState(0);

  const incluirProcedimento = () => {
    setDesabilitarButton(true);
    if (idProcedimento) {
      if (idOrcamento !== null) {
        api
          .post('PlanoEstetico/save.php', {
            pdo: getClinica(),
            idEstetico: idOrcamento,
            procedimento: idProcedimento,
            areaTratada: areaTratada,
            produto: produto,
            valorProduto: valorProduto,
            quantidade: quantidade,
            desconto: 0,
            valor: valor,
          })
          .then(res => {
            abrirAviso('success', 'Procedimento inserido.');
            setPlanoTratamento([...planoTratamento, res.data]);
            setModal(false);
            limparCampos();
          });
      } else {
        const incluir = {
          area: areaTratada,
          produto: produto,
          valorProduto: valorProduto,
          quantidade: quantidade,
          idProcedimento: idProcedimento,
          valor: valor,
        };
        setPlanoTratamento([...planoTratamento, incluir]);
        abrirAviso('success', 'Procedimento inserido.');
        setModal(false);
        limparCampos();
      }
    } else {
      abrirAviso('warning', 'Preencha os campos obrigatórios.');
    }
    setDesabilitarButton(false);
  };

  const limparCampos = () => {
    setProduto(null);
    setAreaTratada(null);
    setQuantidade(0);
    setIdProcedimento(null);
  };

  useEffect(() => {
    let total = valorProcedimento(idProcedimento) * quantidade;
    setValor(total);
  }, [quantidade, idProcedimento]);

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Plano de tratamento'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={empresa.insumosTerceiros === '1' ? 6 : 8}>
              <TextField
                fullWidth
                select
                label="Produto"
                value={produto}
                onChange={e => setProduto(e.target.value)}
                variant="outlined"
              >
                <MenuItem value="" selected>
                  Selecione
                </MenuItem>
                {listaProdutos.map(p => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.nome}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={empresa.insumosTerceiros === '1' ? 3 : 4}>
              <TextField
                fullWidth
                type="number"
                label="Quantidade"
                value={quantidade}
                variant="outlined"
                onChange={e => setQuantidade(e.target.value)}
              />
            </Grid>
            {empresa.insumosTerceiros === '1' && (
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  type="number"
                  label="Valor do Produto"
                  value={valorProduto}
                  variant="outlined"
                  onChange={e => setValorProduto(e.target.value)}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Área tratada"
                value={areaTratada}
                onChange={e => setAreaTratada(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                select
                label="Procedimento"
                value={idProcedimento}
                onChange={e => setIdProcedimento(e.target.value)}
                variant="outlined"
              >
                <MenuItem value="" selected>
                  Selecione
                </MenuItem>
                {listaProcedimentos.map(p => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.descricao}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullScreen
                type="number"
                label="Valor"
                value={valor}
                variant="outlined"
                onChange={e => setValor(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={incluirProcedimento}
            variant="contained"
            color="primary"
            disabled={desabilitarButton}
          >
            Inserir
          </Button>
          <Button
            variant="contained"
            color="default"
            onClick={() => setModal(false)}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
