import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { getClinica } from '../../services/auth';
import { formatReal } from '../../utils/funcoes';
import Menu from '../Menu';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ListAltIcon from '@material-ui/icons/ListAlt';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SaveIcon from '@material-ui/icons/Save';
import Notificacao from '../../utils/notificacao';
import CadastroProcedimento from './cadastrarProcedimento';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Agendamentos from './agendamentos';
import ContasTratamento from './contasTratamento';
import { useParams } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    marginTop: '60px',
    margin: theme.spacing(1),
  },
  buttonAddProcedimento: {
    marginTop: '-20px',
  },
  table: {
    minWidth: 650,
  },
}));

export default function TratamentoOrtodontico() {
  const { trat } = useParams();
  const Tratamento = JSON.parse(atob(trat));
  const [dataInicial, setDataInicial] = useState(Tratamento.dataInicial);
  const [dataFinal, setDataFinal] = useState(Tratamento.dataFinal);
  const [idDentista, setIdDentista] = useState(Tratamento.idDentista);
  const [dentistas, setDentistas] = useState([]);
  const [statusTratamento, setStatusTratamento] = useState(Tratamento.status);
  const [valorTotal, setValorTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [planosTratamento, setPlanosTratamentos] = useState([]);
  const [agendamentos, setAgendamentos] = useState([]);
  const [contas, setContas] = useState([]);
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [modalCadProcedimento, setModalCadProcedimento] = useState(false);

  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    atualizar();
  }, []);

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const fecharAviso = () => {
    setAviso(false);
  };

  const atualizar = async () => {
    await api
      .post('Usuario/list_func_select.php?pdo=' + getClinica())
      .then(res => {
        setDentistas(res.data);
      })
      .catch(error => {
        console.log(error);
      });
    atualizarPlanosOrto();
    atualizarAgendamento();
    atualizarContas();
    setLoading(false);
  };

  const atualizarPlanosOrto = async () => {
    await api
      .get(
        'PlanoOrtodontia/list.php?pdo=' + getClinica() + '&id=' + Tratamento.id,
      )
      .then(res => {
        setPlanosTratamentos(res.data);
        let total = res.data.reduce((soma, i) => soma + parseFloat(i.valor), 0);
        setValorTotal(total);
      });
  };

  const atualizarAgendamento = async () => {
    await api
      .get(
        'Agenda/listEventsTreatment.php?pdo=' +
          getClinica() +
          '&idTratamento=' +
          Tratamento.id,
      )
      .then(res => setAgendamentos(res.data));
  };

  const atualizarContas = async () => {
    let consulta = `AND c.idTratamento='Orto. Nº ${Tratamento.id}'`;
    await api
      .post('Receber/search.php', { pdo: getClinica(), consulta: consulta })
      .then(res => {
        res.data.length > 0 ? setContas(res.data) : setContas([]);
      })
      .catch(error => console.log(error));
  };

  const editar = async () => {
    if (dataInicial && dataFinal) {
      await api
        .post('Ortodontia/update.php', {
          pdo: getClinica(),
          dataInicial: dataInicial,
          dataFinal: dataFinal,
          idDentista: idDentista,
          status: statusTratamento,
          id: Tratamento.id,
        })
        .then(() => {
          abrirAviso('success', 'Tratamento alterado.');
        });
    }
  };

  return (
    <div>
      <Menu
        titulo={
          'Paciente ► Ortodontia ► ' +
          Tratamento.Cliente +
          ' ► Nº ' +
          Tratamento.id
        }
      />
      <Box
        display="flex"
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={() => window.history.back()}
          startIcon={<NavigateBeforeIcon />}
        >
          Voltar
        </Button>
      </Box>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
              aria-label="scrollable force tabs example"
            >
              <Tab
                label="Tratamento"
                icon={<AssignmentIcon fontSize="large" />}
                {...a11yProps(0)}
              />
              <Tab
                label="Procedimentos"
                icon={<ListAltIcon fontSize="large" />}
                {...a11yProps(1)}
              />
              <Tab
                label="Agendamentos"
                icon={<DateRangeIcon fontSize="large" />}
                {...a11yProps(2)}
              />
              <Tab
                label="Financeiro"
                icon={<MonetizationOnIcon fontSize="large" />}
                {...a11yProps(3)}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Box
              display="flex"
              justifyContent="flex-end"
              m={1}
              p={1}
              bgcolor="background.paper"
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonAddProcedimento}
                onClick={editar}
                startIcon={<SaveIcon />}
              >
                Salvar
              </Button>
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  type="date"
                  label="Data Inicial (obrigatório)"
                  fullWidth
                  value={dataInicial}
                  onChange={e => setDataInicial(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="date"
                  label="Data Final (obrigatório)"
                  fullWidth
                  value={dataFinal}
                  onChange={e => setDataFinal(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  label="Dentista"
                  type="date"
                  fullWidth
                  value={idDentista}
                  onChange={e => setIdDentista(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                >
                  <MenuItem value="">Selecione...</MenuItem>
                  {dentistas.map(c => (
                    <MenuItem key={c.id} value={c.id}>
                      {c.nome}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  label="Status"
                  fullWidth
                  value={statusTratamento}
                  onChange={e => setStatusTratamento(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                >
                  <MenuItem value="0">ANDAMENTO</MenuItem>
                  <MenuItem value="1">FINALIZADO</MenuItem>
                  <MenuItem value="2">CANCELADO</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box
              display="flex"
              justifyContent="flex-end"
              m={1}
              p={1}
              bgcolor="background.paper"
            >
              <Button
                variant="contained"
                color="secondary"
                className={classes.buttonAddProcedimento}
                onClick={() => setModalCadProcedimento(true)}
                startIcon={<AddCircleIcon />}
              >
                Adicionar Procedimento
              </Button>
            </Box>
            <div>
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Procedimento</TableCell>
                      <TableCell>Valor</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {planosTratamento.map(plano => (
                      <TableRow key={plano.id}>
                        <TableCell>{plano.id}</TableCell>
                        <TableCell component="th" scope="row">
                          {plano.descricaoProcedimento}
                        </TableCell>
                        <TableCell>{formatReal(plano.valor)}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell align="left" colSpan={2}>
                        <b>Total</b>
                      </TableCell>
                      <TableCell>{formatReal(valorTotal)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Agendamentos agendamentos={agendamentos} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ContasTratamento contas={contas} />
          </TabPanel>
        </div>
      )}
      {modalCadProcedimento && (
        <CadastroProcedimento
          IdOrto={Tratamento.id}
          IdPaciente={Tratamento.idCliente}
          idDentista={Tratamento.idDentista}
          openModal={modalCadProcedimento}
          setOpenModal={setModalCadProcedimento}
          planosTratamento={planosTratamento}
          setPlanosTratamentos={setPlanosTratamentos}
          abrirAviso={abrirAviso}
          atualizarLista={atualizarPlanosOrto}
        />
      )}
      <Notificacao
        aviso={aviso}
        fecharAviso={fecharAviso}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </div>
  );
}
