import React, { useState, useEffect, Fragment } from 'react';
import Menu from '../Menu';
import api from '../../services/api';
import { getClinica } from '../../services/auth';
import { usePermissoes } from '../../hooks/permissoes';
import axios from 'axios';
import {
  Grid,
  TextField,
  MenuItem,
  makeStyles,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  IconButton,
  Button,
  CircularProgress,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  Avatar,
} from '@material-ui/core';
import { mask, unMask } from 'remask';
import PropTypes from 'prop-types';
import RoomIcon from '@material-ui/icons/Room';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@material-ui/icons/Save';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MenuOpcoes from './menuOpcoes';
import Notificacao from '../../utils/notificacao';
import { useConfig } from '../../hooks/config';
import avatarDefault from '../../assets/avatar.png';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { useParams } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    marginTop: '60px',
    margin: theme.spacing(1),
  },
  titulos: {
    margin: '8px 0px',
  },
  menuOpcoes: {
    marginTop: theme.spacing(7.5),
  },
  large: {
    border: '6px solid',
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  input: {
    display: 'none',
  },
}));

export default function CadastrarEditarPaciente(props) {
  const { id } = useParams();
  const Paciente = JSON.parse(atob(id));
  const { permissoes } = usePermissoes();
  const { configuracoes } = useConfig();
  const [loading, setLoading] = useState(true);
  const [desabilitar, setDesabilitar] = useState(false);
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const [convenios, setConvenios] = useState([]);
  const [idPac, setIdPac] = useState('');
  const [cpf, setCpf] = useState('');
  const [vCpf, setVCpf] = useState('');
  const [rg, setRg] = useState('');
  const [nome, setNome] = useState('');
  const [dataNasc, setDataNasc] = useState('');
  const [sexo, setSexo] = useState('');
  const [naturalidade, setNaturalidade] = useState('');
  const [nacionalidade, setNacionalidade] = useState('');
  const [estadoCivil, setEstadoCivil] = useState('');
  const [profissao, setProfissao] = useState('');
  const [ativo, setAtivo] = useState('');
  const [telefone, setTelefone] = useState('');
  const [celular, setCelular] = useState('');
  const [instagram, setInstagram] = useState('');
  const [email, setEmail] = useState('');
  const [convenio, setConvenio] = useState('');
  const [nCartao, setNCartao] = useState('');
  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [complemento, setComplemento] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [cep2, setCep2] = useState('');
  const [logradouro2, setLogradouro2] = useState('');
  const [numero2, setNumero2] = useState('');
  const [bairro2, setBairro2] = useState('');
  const [complemento2, setComplemento2] = useState('');
  const [cidade2, setCidade2] = useState('');
  const [estado2, setEstado2] = useState('');
  const [exibirResponsavel, setExibirResponsavel] = useState(false);
  const [responsavel, setResponsavel] = useState('');
  const [respCpf, setRespCpf] = useState('');
  const [respRg, setRespRg] = useState('');
  const [foto, setFoto] = useState(null);
  const [avatar, setAvatar] = useState(null);

  const classes = useStyles();
  const [value, setValue] = useState(0);

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const selecionarAba = (event, newValue) => {
    setValue(newValue);
  };

  const consultarCepR = () => {
    axios
      .get('https://api.postmon.com.br/v1/cep/' + cep)
      .then(res => {
        setCep(res.data.cep);
        setLogradouro(res.data.logradouro);
        setBairro(res.data.bairro);
        setCidade(res.data.cidade);
        setEstado(res.data.estado);
      })
      .catch(() => abrirAviso('error', 'CEP não encontrado'));
  };

  const consultarCepP = () => {
    axios
      .get('https://api.postmon.com.br/v1/cep/' + cep2)
      .then(res => {
        setCep2(res.data.cep);
        setLogradouro2(res.data.logradouro);
        setBairro2(res.data.bairro);
        setCidade2(res.data.cidade);
        setEstado2(res.data.estado);
      })
      .catch(() => abrirAviso('error', 'CEP não encontrado'));
  };

  const atualizar = async () => {
    await api
      .get('Convenio/list.php?pdo=' + getClinica())
      .then(res => {
        setConvenios(res.data);
      })
      .catch(error => console.log(error));
    if (Paciente) {
      await api
        .get('Usuario/find.php', {
          params: { id: Paciente.id, pdo: getClinica() },
        })
        .then(res => setarDadosPaciente(res.data));
    }
    setLoading(false);
  };

  const setarDadosPaciente = async paciente => {
    setIdPac(paciente.id);
    setCpf(paciente.cpf);
    setVCpf(paciente.cpf);
    setRg(paciente.rg);
    setNome(paciente.nome);
    setDataNasc(paciente.dataNasc);
    setSexo(paciente.sexo);
    setNaturalidade(paciente.naturalidade);
    setNacionalidade(paciente.nacionalidade);
    setEstadoCivil(paciente.estadoCivil);
    setAtivo(paciente.ativo);
    setProfissao(paciente.profissao);
    setTelefone(paciente.telefone);
    setCelular(paciente.celular);
    setInstagram(paciente.instagram === 'null' ? null : paciente.instagram);
    setConvenio(paciente.convenio);
    setEmail(paciente.login);
    setCep(paciente.endCepR);
    setLogradouro(paciente.endLograR);
    setNumero(paciente.endNumR);
    setBairro(paciente.endBairroR);
    setCidade(paciente.endCidadeR);
    setEstado(paciente.endEstadoR);
    setComplemento(paciente.endCompleR);
    setCep2(paciente.endCepP);
    setLogradouro2(paciente.endLograP);
    setNumero2(paciente.endNumP);
    setBairro2(paciente.endBairroP);
    setCidade2(paciente.endCidadeP);
    setEstado2(paciente.endEstadoP);
    setComplemento2(paciente.endCompleP);
    setResponsavel(
      paciente.responsavel === 'null' ? null : paciente.responsavel,
    );
    setRespCpf(paciente.respCpf === 'null' ? null : paciente.respCpf);
    setRespRg(paciente.respRg);
    setAvatar(paciente.foto);
  };

  const validarCampos = () => {
    if (
      cpf !== '' &&
      rg !== '' &&
      nome !== '' &&
      dataNasc !== '' &&
      sexo !== ''
    ) {
      return true;
    }
    return false;
  };

  const verificarCpf = async cpf => {
    let ret = false;
    await api
      .get('Usuario/verificarCpf.php?pdo=' + getClinica() + '&cpf=' + cpf)
      .then(res => {
        ret = res.data;
      });
    return ret;
  };

  const limparCampos = () => {
    setCpf('');
    setRg('');
    setNome('');
    setDataNasc('');
    setSexo('');
    setNaturalidade('');
    setNacionalidade('');
    setEstadoCivil('');
    setProfissao('');
    setTelefone('');
    setCelular('');
    setEmail('');
    setConvenio('');
    setNCartao('');
    setCep('');
    setLogradouro('');
    setNumero('');
    setBairro('');
    setComplemento('');
    setCidade('');
    setEstado('');
    setCep2('');
    setLogradouro2('');
    setNumero2('');
    setBairro2('');
    setComplemento2('');
    setCidade2('');
    setEstado2('');
  };

  const salvar = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.pacCad !== '0' && permissoes !== false) {
        if (validarCampos()) {
          if (!Paciente) {
            let existeCPF = await verificarCpf(cpf);
            if (existeCPF) {
              abrirAviso('warning', 'CPF já cadastrado.');
              setDesabilitar(false);
              return;
            }
          } else if (Paciente) {
            if (vCpf !== cpf) {
              let existeCPF = await verificarCpf(cpf);
              if (existeCPF) {
                abrirAviso('warning', 'CPF já cadastrado.');
                setDesabilitar(false);
                return;
              } else {
              }
            }
          }

          const metodo = Paciente ? 'Usuario/update.php' : 'Usuario/save.php';

          const fd = new FormData();
          fd.append('pdo', getClinica());
          fd.append('cpf', cpf);
          fd.append('rg', rg);
          fd.append('nome', nome);
          fd.append('dataNasc', dataNasc);
          fd.append('sexo', sexo);
          fd.append('naturalidade', naturalidade);
          fd.append('nacionalidade', nacionalidade);
          fd.append('estadoCivil', estadoCivil);
          fd.append('profissao', profissao);
          fd.append('telefone', telefone);
          fd.append('celular', celular);
          fd.append('instagram', instagram);
          fd.append('convenio', convenio);
          fd.append('nCartao', nCartao);
          fd.append('responsavel', responsavel);
          fd.append('respCpf', respCpf);
          fd.append('respRg', respRg ? respRg : 0);
          fd.append('login', email);
          fd.append('ativo', 0);
          fd.append('senha', '');
          fd.append('tipo', 0);
          fd.append('cro', '');
          fd.append('endCepR', cep);
          fd.append('endLograR', logradouro);
          fd.append('endNumR', numero);
          fd.append('endBairroR', bairro);
          fd.append('endCidadeR', cidade);
          fd.append('endEstadoR', estado);
          fd.append('endCompleR', complemento);
          fd.append('endCepP', cep2);
          fd.append('endLograP', logradouro2);
          fd.append('endNumP', numero2);
          fd.append('endBairroP', bairro2);
          fd.append('endCidadeP', cidade2);
          fd.append('endEstadoP', estado2);
          fd.append('endCompleP', complemento2);
          fd.append('comissao', 0);
          fd.append('foto', foto);

          if (Paciente) {
            fd.append('id', idPac);
          }

          await api
            .post(metodo, fd)
            .then(res => {
              abrirAviso(
                'success',
                Paciente ? 'Salvo.' : 'Paciente cadastrado.',
              );
              if (Paciente) {
                window.location.pathname = `/cad_edit_paciente/${btoa(
                  JSON.stringify({ id: res.data.id, nome: res.data.nome }),
                )}`;
              } else {
                limparCampos();
              }
              setDesabilitar(false);
            })
            .catch(error => {
              console.log(error);
              abrirAviso(
                'error',
                'Erro ao realizar operação. Tente novamente.',
              );
            });
        } else {
          abrirAviso('warning', 'Preencha todos os campos obrigatórios.');
          setDesabilitar(false);
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
        setDesabilitar(false);
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
      setDesabilitar(false);
    }
  };

  const desativarAtivar = e => {
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.pacDes !== '0' && permissoes !== false) {
        setDesabilitar(true);
        api
          .post('Usuario/disable_user.php', {
            pdo: getClinica(),
            ativo: e,
            id: idPac,
          })
          .then(() => {
            e === '-1'
              ? abrirAviso('success', 'Paciente desativado.')
              : abrirAviso('success', 'Paciente ativado.');
            setDesabilitar(false);
            setAtivo(e);
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
        setDesabilitar(false);
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
      setDesabilitar(false);
    }
  };

  const onChangeFoto = event => {
    setFoto(event.target.files[0]);
  };

  useEffect(() => {
    atualizar();
  }, []);

  useEffect(() => {
    if (dataNasc) {
      let hoje = new Date();
      let dataN = new Date(dataNasc);
      let idade = hoje.getFullYear() - dataN.getFullYear();
      idade < 18 ? setExibirResponsavel(true) : setExibirResponsavel(false);
    }
  }, [dataNasc]);

  return (
    <div>
      <Menu
        titulo={
          Paciente
            ? `Pacientes ► ${Paciente.nome} ► Editar`
            : 'Pacientes ► Cadastro'
        }
      />
      <Box
        display="flex"
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={() => (window.location.pathname = '/pacientes')}
          startIcon={<NavigateBeforeIcon />}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={salvar}
          startIcon={<SaveIcon />}
          disabled={desabilitar}
        >
          Salvar
        </Button>
        {Paciente && ativo === '0' && (
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            disabled={desabilitar}
            onClick={() => desativarAtivar('-1')}
            startIcon={<RemoveCircleIcon />}
          >
            Desativar
          </Button>
        )}
        {Paciente && ativo === '-1' && (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={desabilitar}
            onClick={() => desativarAtivar('0')}
            startIcon={<CheckCircleIcon />}
          >
            Ativar
          </Button>
        )}
        {/* <Button variant="contained" color="primary" className={classes.button} onClick={salvar} startIcon={<SaveIcon />} disabled={desabilitar}>Opções</Button> */}
        {Paciente !== null && (
          <div className={classes.menuOpcoes}>
            <MenuOpcoes paciente={Paciente} />
          </div>
        )}
      </Box>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={selecionarAba}
              variant="fullWidth"
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                label="Dados Cadastrais"
                icon={<PersonPinIcon />}
                {...a11yProps(0)}
              />
              <Tab label="Endereços" icon={<RoomIcon />} {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Box
              display="flex"
              justifyContent="center"
              m={1}
              p={1}
              bgcolor="background.paper"
            >
              <Avatar
                alt="Avatar"
                src={avatar ? `https://sigecli.com.br${avatar}` : avatarDefault}
                className={classes.large}
                style={{ borderColor: foto ? '#4caf50' : '#DDD' }}
              />
              <input
                accept="image/png,image/jpeg"
                className={classes.input}
                id="icon-button-file"
                onChange={onChangeFoto}
                type="file"
              />
              <label htmlFor="icon-button-file">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <PhotoCamera />
                </IconButton>
              </label>
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  label="CPF (obrigatório)"
                  fullWidth
                  value={cpf}
                  onChange={e =>
                    setCpf(mask(unMask(e.target.value), ['99999999999']))
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Rg (obrigatório)"
                  fullWidth
                  value={rg}
                  onChange={e => setRg(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  label="Nome (obrigatório)"
                  fullWidth
                  value={nome}
                  onChange={e => setNome(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Nascimento (obrigatório)"
                  type="date"
                  fullWidth
                  value={dataNasc}
                  onChange={e => setDataNasc(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  select
                  label="Sexo (obrigatório)"
                  fullWidth
                  value={sexo}
                  onChange={e => setSexo(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="">Selecione</MenuItem>
                  <MenuItem value="m">Masculino</MenuItem>
                  <MenuItem value="f">Feminino</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Naturalidade"
                  fullWidth
                  value={naturalidade}
                  onChange={e => setNaturalidade(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Nacionalidade"
                  fullWidth
                  value={nacionalidade}
                  onChange={e => setNacionalidade(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  select
                  label="Estado civil"
                  fullWidth
                  value={estadoCivil}
                  onChange={e => setEstadoCivil(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="">Selecione</MenuItem>
                  <MenuItem value="1">Solteiro(a)</MenuItem>
                  <MenuItem value="2">Casado(a)</MenuItem>
                  <MenuItem value="3">Separado(a)</MenuItem>
                  <MenuItem value="4">Divorciado(a)</MenuItem>
                  <MenuItem value="5">Viúvo(a)</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Profissão"
                  fullWidth
                  value={profissao}
                  onChange={e => setProfissao(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Telefone"
                  fullWidth
                  value={telefone}
                  onChange={e =>
                    setTelefone(mask(unMask(e.target.value), ['(99)9999-9999']))
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Celular"
                  fullWidth
                  value={celular}
                  onChange={e =>
                    setCelular(mask(unMask(e.target.value), ['(99)99999-9999']))
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Instagram"
                  fullWidth
                  value={instagram}
                  onChange={e => setInstagram(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="E-mail"
                  fullWidth
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  select
                  label="Convênio"
                  fullWidth
                  value={convenio}
                  onChange={e => setConvenio(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="">Selecione</MenuItem>
                  {convenios.map(convenio => (
                    <MenuItem key={convenio.id} value={convenio.id}>
                      {convenio.nome}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Nº do cartão de convênio"
                  fullWidth
                  value={nCartao}
                  onChange={e => setNCartao(e.target.value)}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            {exibirResponsavel && (
              <>
                <h4 className={classes.titulos}>Responsável Legal</h4>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <TextField
                      label="CPF"
                      fullWidth
                      value={respCpf}
                      onChange={e =>
                        setRespCpf(
                          mask(unMask(e.target.value), ['99999999999']),
                        )
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Rg"
                      fullWidth
                      value={respRg}
                      onChange={e => setRespRg(e.target.value)}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Nome"
                      fullWidth
                      value={responsavel}
                      onChange={e => setResponsavel(e.target.value)}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <h4 className={classes.titulos}>Endereço residencial</h4>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="cep">Cep</InputLabel>
                  <OutlinedInput
                    id="cep"
                    type="text"
                    value={cep}
                    onChange={e =>
                      setCep(mask(unMask(e.target.value), ['99999999']))
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={() => consultarCepR()} edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Logradouro"
                  fullWidth
                  value={logradouro}
                  onChange={e => setLogradouro(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  label="Número"
                  fullWidth
                  value={numero}
                  onChange={e => setNumero(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Bairro"
                  fullWidth
                  value={bairro}
                  onChange={e => setBairro(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Cidade"
                  fullWidth
                  value={cidade}
                  onChange={e => setCidade(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  label="Estado"
                  fullWidth
                  value={estado}
                  onChange={e => setEstado(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Complemento"
                  fullWidth
                  value={complemento}
                  onChange={e => setComplemento(e.target.value)}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <h4 className={classes.titulos}>Endereço profissional</h4>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="cep">Cep</InputLabel>
                  <OutlinedInput
                    id="cep"
                    type="text"
                    value={cep2}
                    onChange={e =>
                      setCep2(mask(unMask(e.target.value), ['99999999']))
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={() => consultarCepP()} edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Logradouro"
                  fullWidth
                  value={logradouro2}
                  onChange={e => setLogradouro2(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  label="Número"
                  fullWidth
                  value={numero2}
                  onChange={e => setNumero2(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Bairro"
                  fullWidth
                  value={bairro2}
                  onChange={e => setBairro2(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Cidade"
                  fullWidth
                  value={cidade2}
                  onChange={e => setCidade2(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  label="Estado"
                  fullWidth
                  value={estado2}
                  onChange={e => setEstado2(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Complemento"
                  fullWidth
                  value={complemento2}
                  onChange={e => setComplemento2(e.target.value)}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </TabPanel>
        </div>
      )}
      <Notificacao
        aviso={aviso}
        fecharAviso={() => setAtivo(false)}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </div>
  );
}
