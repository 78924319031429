import React from 'react';
import Cabecalho from '../../utils/cabecalho';
import Rodape from '../../utils/rodape';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import PrintIcon from '@material-ui/icons/Print';
import { formatData, formatReal } from '../../utils/funcoes';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(15),
    right: theme.spacing(1.5),
  },
  titulo: {
    textAlign: 'center',
  },
  totalizador: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
}));

export default function ImprimirRelatorioProducaoEstetico() {
  const reg = JSON.parse(atob(sessionStorage.getItem('relPE')));
  const registros = JSON.parse(reg.registros);
  const classes = useStyles();

  const imprimir = () => {
    window.document.getElementById('button-print').style.display = 'none';
    window.print();
    window.document.getElementById('button-print').style.display = 'block';
    window.close();
  };

  return (
    <>
      <Cabecalho />
      <h3 className={classes.titulo}>Relatório de Produção (Estético)</h3>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Data</b>
              </TableCell>
              <TableCell>
                <b>Procedimento</b>
              </TableCell>
              <TableCell>
                <b>Paciente</b>
              </TableCell>
              <TableCell>
                <b>Profissional</b>
              </TableCell>
              <TableCell>
                <b>
                  {reg.empresa?.insumosTerceiros === '1'
                    ? 'Insumos'
                    : 'Convênio'}
                </b>
              </TableCell>
              <TableCell>
                <b>Valor</b>
              </TableCell>
              <TableCell>
                <b>% Profissional</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {registros.map(registro => {
              return (
                <TableRow key={registro.id}>
                  <TableCell>{formatData(registro.dataCriacao)}</TableCell>
                  <TableCell>{registro.descricaoProcedimento}</TableCell>
                  <TableCell>{registro.paciente}</TableCell>
                  <TableCell>{registro.dentista}</TableCell>
                  <TableCell>
                    {reg.empresa?.insumosTerceiros === '1'
                      ? formatReal(registro.valorProduto)
                      : registro.convenio}
                  </TableCell>
                  <TableCell>{formatReal(registro.valor)}</TableCell>
                  <TableCell>
                    {reg.empresa?.insumosTerceiros === '1'
                      ? formatReal(
                          parseFloat(registro.porcentagem) +
                            parseFloat(registro.valorProduto),
                        )
                      : formatReal(registro.porcentagem)}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell
                colSpan={reg.empresa?.insumosTerceiros === '1' ? 2 : 3}
                className={classes.totalizador}
              >
                Total
              </TableCell>
              <TableCell colSpan={2} className={classes.totalizador}>
                {registros.length} registro(s)
              </TableCell>
              {reg.empresa?.insumosTerceiros === '1' && (
                <TableCell className={classes.totalizador}>
                  {formatReal(reg.insumos)}
                </TableCell>
              )}
              <TableCell className={classes.totalizador}>
                {formatReal(reg.total)}
              </TableCell>
              <TableCell className={classes.totalizador}>
                {reg.empresa?.insumosTerceiros === '1'
                  ? formatReal(parseFloat(reg.porc) + parseFloat(reg.insumos))
                  : formatReal(reg.porc)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <Rodape />
      <Fab
        color="primary"
        size="large"
        id="button-print"
        className={classes.fab}
        onClick={imprimir}
      >
        <PrintIcon />
      </Fab>
    </>
  );
}
