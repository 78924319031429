import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import { getClinica } from '../../../services/auth';
import { formatReal } from '../../../utils/funcoes';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  useMediaQuery,
  useTheme,
  makeStyles,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { usePermissoes } from '../../../hooks/permissoes';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  total: {
    fontSize: '20px',
    padding: 0,
  },
}));

export default function TabelaPlanoTratamento({
  idOrcamento,
  planoTratamento,
  setPlanoTratamento,
  listaProcedimentos,
  abrirAviso,
  tipoDesc,
  setTipoDesc,
  total,
  totalFinal,
  setTotalFinal,
  desconto,
  setDesconto,
  ratear,
  setRatear,
}) {
  const { permissoes } = usePermissoes();
  const [modalEditar, setModalEditar] = useState(false);
  const [valorProc, setValorProc] = useState(null);
  const [idPlano, setIdPlano] = useState('');
  const [estadoAtual, setEstadoAtual] = useState('');
  const [idProcedimento, setIdProcedimento] = useState(null);
  const [face, setFace] = useState(null);
  const [desc, setDesc] = useState(0);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const [state, setState] = useState({
    checkedV: false,
    checkedD: false,
    checkedLP: false,
    checkedM: false,
    checkedO: false,
  });

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    setTotalFinal(total);
    confirmarDesconto();
  }, [planoTratamento]);

  useEffect(() => {
    confirmarDesconto();
  }, [desconto, tipoDesc]);

  const descricaoProcedimento = id => {
    let descricao = '';
    listaProcedimentos.map(procedimento => {
      if (procedimento.id == id) {
        descricao = procedimento.descricao;
      }
    });
    return descricao;
  };

  const alterarProcedimento = obj => {
    setModalEditar(true);
    if (idOrcamento !== null) {
      setIdProcedimento(obj.procedimento);
      setEstadoAtual(obj.estadoAtual);
      setFace(obj.face);
      setDesc(obj.desconto);
      setValorProc(obj.valor);
      setIdPlano(obj.id);
    } else {
      setValorProc(planoTratamento[obj].valor);
      setIdPlano(obj);
    }
  };

  const cancelarProcedimento = id => {
    if (idOrcamento !== null) {
      api
        .post('PlanoTratamento/deletar.php', {
          pdo: getClinica(),
          id: id,
        })
        .then(() => {
          abrirAviso('success', 'procedimento removido.');
          setPlanoTratamento(planoTratamento =>
            planoTratamento.filter(e => e.id !== id),
          );
        });
    } else {
      planoTratamento.splice(id, 1);
      setDesconto(0);
      setPlanoTratamento([...planoTratamento]);
      abrirAviso('success', 'procedimento removido.');
    }
  };

  const confirAltProcedimento = () => {
    let faces = '';
    faces += state.checkedV ? '+V' : '';
    faces += state.checkedD ? '+D' : '';
    faces += state.checkedLP ? '+L/P' : '';
    faces += state.checkedM ? '+M' : '';
    faces += state.checkedO ? '+O' : '';

    if (idOrcamento !== null) {
      if (idPlano !== '' && estadoAtual !== '' && valorProc > 0) {
        api
          .post('PlanoTratamento/updateData.php', {
            pdo: getClinica(),
            procedimento: idProcedimento,
            face: faces.substr(1),
            estadoAtual: estadoAtual,
            desconto: desc,
            valor: valorProc,
            idPlanoTratamento: idPlano,
          })
          .then(res => {
            abrirAviso('success', 'procedimento alterado.');
            setPlanoTratamento(state =>
              state.map(e => {
                if (e.id !== res.data.id) {
                  return e;
                }
                e = res.data;
                return e;
              }),
            );
          });
      } else {
        abrirAviso('warning', 'Preencha os campos obrigatórios.');
      }
    } else {
      if (valorProc > 0) {
        planoTratamento[idPlano].valor = valorProc;
        setPlanoTratamento([...planoTratamento]);
        abrirAviso('success', 'valor alterado.');
      } else {
        abrirAviso('warning', 'Preencha o campo obrigatório.');
      }
    }
    setModalEditar(false);
  };

  const confirmarDesconto = () => {
    let porcentagem = 0;
    let valor = 0;
    let totalDesc = 0;
    if (tipoDesc === '0') {
      if (desconto > 0) {
        porcentagem = parseFloat(desconto);
        let valorDesc = parseFloat(total * (porcentagem / 100));
        totalDesc = total - valorDesc;
        setTotalFinal(totalDesc);
        setRatear(valorDesc);
      } else {
        setTotalFinal(total);
        setRatear(0);
      }
    } else if (tipoDesc === '1') {
      valor = parseFloat(desconto);
      totalDesc = total - valor;
      setTotalFinal(totalDesc);
      setRatear(valor);
    } else {
      abrirAviso('warning', 'Informe o tipo do desconto.');
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Dente</b>
              </TableCell>
              <TableCell align="center">
                <b>Face(s)</b>
              </TableCell>
              <TableCell align="center">
                <b>Estado Atual</b>
              </TableCell>
              <TableCell align="center">
                <b>Procedimento</b>
              </TableCell>
              {!!!+permissoes.orcDesabilitarValores && (
                <TableCell align="center">
                  <b>Valor</b>
                </TableCell>
              )}
              <TableCell align="center">
                <b>Opções</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {planoTratamento.map((pl, index) => (
              <TableRow key={index}>
                {idOrcamento !== null ? (
                  <TableCell component="th" scope="row">
                    {pl.numDente}
                  </TableCell>
                ) : (
                  <TableCell component="th" scope="row">
                    {pl.dente}
                  </TableCell>
                )}
                <TableCell align="center">{pl.face}</TableCell>
                <TableCell align="center">{pl.estadoAtual}</TableCell>
                {idOrcamento !== null ? (
                  <TableCell align="center">
                    {pl.descricaoProcedimento}
                  </TableCell>
                ) : (
                  <TableCell align="center">
                    {descricaoProcedimento(pl.idProcedimento)}
                  </TableCell>
                )}
                {!!!+permissoes.orcDesabilitarValores && (
                  <TableCell align="center">{formatReal(pl.valor)}</TableCell>
                )}
                <TableCell align="center">
                  {!!!+permissoes.orcDesabilitarValores && (
                    <Button
                      className={classes.button}
                      color="primary"
                      title="Editar"
                      onClick={() =>
                        alterarProcedimento(idOrcamento ? pl : index)
                      }
                      variant="contained"
                    >
                      <EditIcon />
                    </Button>
                  )}
                  {idOrcamento !== null ? (
                    <Button
                      color="secondary"
                      onClick={() => cancelarProcedimento(pl.id)}
                      title="Excluir"
                      variant="contained"
                    >
                      <DeleteIcon />
                    </Button>
                  ) : (
                    <Button
                      color="secondary"
                      onClick={() => cancelarProcedimento(index)}
                      title="Excluir"
                      variant="contained"
                    >
                      <DeleteIcon />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {!!!+permissoes.orcDesabilitarValores && (
              <>
                <TableRow>
                  <TableCell colSpan={5}>
                    <b className={classes.total}>SubTotal</b>
                  </TableCell>
                  <TableCell>
                    <b className={classes.total}>{formatReal(total)}</b>
                  </TableCell>
                </TableRow>
                {idOrcamento === null && (
                  <TableRow>
                    <TableCell>
                      <b className={classes.total}>Desconto</b>
                    </TableCell>
                    <TableCell align="right" colSpan={3}>
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        value={tipoDesc}
                        onChange={e => setTipoDesc(e.target.value)}
                      >
                        <FormControlLabel
                          value="0"
                          control={<Radio color="primary" />}
                          label="Porcentagem"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio color="primary" />}
                          label="Valor"
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        fullWidth
                        size="small"
                        value={desconto}
                        onChange={e => {
                          e.target.value >= 0
                            ? setDesconto(e.target.value)
                            : setDesconto(0);
                        }}
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell>
                      <b className={classes.total}>{formatReal(ratear)}</b>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell colSpan={5}>
                    <b className={classes.total}>Total</b>
                  </TableCell>
                  <TableCell>
                    <b className={classes.total}>{formatReal(totalFinal)}</b>
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        fullScreen={fullScreen}
        open={modalEditar}
        onClose={() => setModalEditar(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Alterar Valor'}
        </DialogTitle>
        <DialogContent>
          {idOrcamento === null && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  type="number"
                  fullWidth
                  label="Valor"
                  value={valorProc}
                  onChange={e => {
                    setValorProc(e.target.value);
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          )}
          {idOrcamento !== null && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  label="Plano de tratamento proposto *"
                  value={idProcedimento}
                  onChange={e => setIdProcedimento(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="" selected>
                    Selecione...
                  </MenuItem>
                  {listaProcedimentos.map(pro => (
                    <MenuItem key={pro.id} value={pro.id}>
                      {pro.descricao}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Desconto"
                  value={desc}
                  onChange={e => setDesc(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Valor *"
                  value={valorProc}
                  onChange={e => setValorProc(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Estado atual do dente"
                  value={estadoAtual}
                  onChange={e => setEstadoAtual(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <>
                  <b>Selecione a(s) face(s): </b>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedB}
                        onChange={handleChange}
                        name="checkedV"
                        color="primary"
                      />
                    }
                    label="V"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedB}
                        onChange={handleChange}
                        name="checkedD"
                        color="primary"
                      />
                    }
                    label="D"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedB}
                        onChange={handleChange}
                        name="checkedLP"
                        color="primary"
                      />
                    }
                    label="L/P"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedM}
                        onChange={handleChange}
                        name="checkedM"
                        color="primary"
                      />
                    }
                    label="M"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedO}
                        onChange={handleChange}
                        name="checkedO"
                        color="primary"
                      />
                    }
                    label="O"
                  />
                </>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={confirAltProcedimento}
            variant="contained"
            color="primary"
          >
            Alterar
          </Button>
          <Button
            variant="contained"
            color="default"
            onClick={() => setModalEditar(false)}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
