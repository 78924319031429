import React, { useState, useEffect, useRef } from 'react';
import Menu from '../../Menu';
import api from '../../../services/api';
import { getClinica } from '../../../services/auth';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import Notificacao from '../../../utils/notificacao';
import { usePermissoes } from '../../../hooks/permissoes';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useConfig } from '../../../hooks/config';
import TabelaProcedimentos from './tabelaProcedimentos';
import InserirProcedimentoEstetico from './inserirProcedimento';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import LensIcon from '@material-ui/icons/Lens';
import CreateIcon from '@material-ui/icons/Create';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: '60px',
    margin: theme.spacing(1),
  },
  buttonInsert: {
    float: 'right',
    marginBottom: '10px',
  },
  buttonMarcadorVermelho: {
    color: '#dc3545',
    '&:hover': {
      color: '#b52c39',
    },
  },
  buttonMarcadorVerde: {
    color: '#32CD32',
    '&:hover': {
      color: '#099c42',
    },
  },
  buttonMarcadorAmarelo: {
    color: '#FFFB00',
    '&:hover': {
      color: '#cecb0d',
    },
  },
  buttonLapisVermelho: {
    color: '#dc3545',
    '&:hover': {
      color: '#b52c39',
    },
  },
  buttonLapisAzul: {
    color: '#1512f1',
    '&:hover': {
      color: '#1411b7',
    },
  },
}));

export default function EditarOrcamentoEstetico() {
  const { orc } = useParams();
  const Orcamento = JSON.parse(atob(orc));
  const { permissoes } = usePermissoes();
  const [loading, setLoading] = useState(true);
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const [procedimentos, setProcedimentos] = useState([]);
  const [planoEstetico, setPlanoEstetico] = useState([]);
  const [opcao, setOpcao] = useState(0);
  const [quantidade, setQuantidade] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [total, setTotal] = useState(0);
  const [tipoDesc, setTipoDesc] = useState('0');
  const [totalFinal, setTotalFinal] = useState(total);
  const [ratear, setRatear] = useState(0);
  const [desconto, setDesconto] = useState(0);
  const [clientes, setClientes] = useState([]);
  const [especialistas, setEspecialistas] = useState([]);
  const [idEspecialista, setIdEspecialista] = useState('');
  const [idCliente, setIdCliente] = useState('');
  const [idProcedimento, setIdProcedimento] = useState(null);
  const [idProduto, setIdProduto] = useState(null);
  const [areaTratada, setAreaTratada] = useState('');
  const [imagem, setImagem] = useState(null);
  const { configuracoes } = useConfig();
  const [modalInsert, setModalInsert] = useState(false);
  const canvasRef = useRef(null);
  const classes = useStyles();

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const atualizar = async () => {
    await api
      .get('Procedimento/list.php?pdo=' + getClinica() + '&tipo=2')
      .then(res => setProcedimentos(res.data));
    await api
      .get('PlanoEstetico/list.php?id=' + Orcamento.id + '&pdo=' + getClinica())
      .then(res => {
        setPlanoEstetico(res.data);
      });
    await api
      .get('Estetico/find.php?id=' + Orcamento.id + '&pdo=' + getClinica())
      .then(res => setImagem(res.data.img));
    await api.get('Produto/list.php?pdo=' + getClinica()).then(res => {
      setProdutos(res.data);
    });
    setLoading(false);
  };

  const desenharImagem = () => {
    const canvas = canvasRef.current;
    let ctx = null;
    if (canvas !== null) {
      let img = new Image();
      ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      img.src = imagem;
    }
  };

  const desenhar = () => {
    const canvas = canvasRef.current;
    let ctx = null;
    if (canvas !== null) {
      ctx = canvas.getContext('2d');
    }
    if (opcao !== 0) {
      if (opcao === 1) {
        canvas.onmousedown = function (event) {
          const x = event.offsetX;
          const y = event.offsetY;
          ctx.fillStyle = 'red';
          ctx.beginPath();
          ctx.arc(x, y, 5, 0, 2 * Math.PI);
          ctx.fill();
        };
        canvas.onmouseup = function () {
          canvas.onmousemove = null;
        };
      } else if (opcao === 2) {
        canvas.onmousedown = function (event) {
          const x = event.offsetX;
          const y = event.offsetY;
          ctx.fillStyle = '#32CD32';
          ctx.beginPath();
          ctx.arc(x, y, 7, 0, 2 * Math.PI);
          ctx.fill();
        };
        canvas.onmouseup = function () {
          canvas.onmousemove = null;
        };
      } else if (opcao === 3) {
        canvas.onmousedown = function (event) {
          canvas.onmousemove = function (event) {
            const x = event.offsetX;
            const y = event.offsetY;
            ctx.fillStyle = 'blue';
            ctx.beginPath();
            ctx.arc(x, y, 2, 0, 2 * Math.PI);
            ctx.fill();
          };
          canvas.onmouseup = function () {
            canvas.onmousemove = null;
          };
        };
      } else if (opcao === 4) {
        canvas.onmousedown = function (event) {
          const x = event.offsetX;
          const y = event.offsetY;
          ctx.fillStyle = '#FFFB00';
          ctx.beginPath();
          ctx.arc(x, y, 6, 0, 2 * Math.PI);
          ctx.fill();
        };
        canvas.onmouseup = function () {
          canvas.onmousemove = null;
        };
      } else if (opcao === 5) {
        canvas.onmousedown = function (event) {
          canvas.onmousemove = function (event) {
            const x = event.offsetX;
            const y = event.offsetY;
            ctx.fillStyle = 'red';
            ctx.beginPath();
            ctx.arc(x, y, 2, 0, 2 * Math.PI);
            ctx.fill();
          };
          canvas.onmouseup = function () {
            canvas.onmousemove = null;
          };
        };
      }
    }
  };

  const soma = () => {
    let soma = planoEstetico.reduce((sum, x) => sum + parseFloat(x.valor), 0);
    setTotal(soma);
    setTotalFinal(soma);
  };

  const limpar = () => {
    setOpcao(0);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.onmousedown = function (event) {
      canvas.onmousemove = function (event) {
        const x = event.offsetX;
        const y = event.offsetY;
        ctx.clearRect(x - 5, y - 5, 12, 12);
      };
      canvas.onmouseup = function () {
        canvas.onmousemove = null;
      };
    };
  };

  const limparTudo = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const valorProcedimento = id => {
    let valor = 0;
    procedimentos.map(procedimento => {
      if (procedimento.id == id) {
        valor = procedimento.valor;
      }
    });
    return valor;
  };

  useEffect(() => {
    atualizar();
  }, []);

  useEffect(() => {
    soma();
  }, [planoEstetico]);

  useEffect(() => {
    desenhar();
  }, [opcao]);

  useEffect(() => {
    if (imagem !== null) {
      desenharImagem();
    }
  }, [imagem]);

  return (
    <>
      <Menu
        titulo={`Pacientes ► ${Orcamento.Cliente} ► Orçamentos Estéticos ► Editar Orçamento Nº ${Orcamento.id}`}
      />
      <Box
        display="flex"
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={() => window.history.back()}
          startIcon={<NavigateBeforeIcon />}
        >
          Voltar
        </Button>
      </Box>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <Grid container spacing={1}>
          {/* <Grid item xs={3}>
                        <canvas id="desenho" ref={canvasRef} onClick={desenhar} width={282} height={390}/>
                        <ButtonGroup size="small" aria-label="small outlined button group">
                            <Button onClick={()=>setOpcao(1)} className={classes.buttonMarcadorVermelho} title="Marcador Vermelho"><LensIcon/></Button>
                            <Button onClick={()=>setOpcao(2)} className={classes.buttonMarcadorVerde} title="Marcador Verde"><LensIcon/></Button>
                            <Button onClick={()=>setOpcao(4)} className={classes.buttonMarcadorAmarelo} title="Marcador Amarelo"><LensIcon/></Button>
                            <Button onClick={()=>setOpcao(3)} className={classes.buttonLapisAzul} title="Lápis Azul"><CreateIcon/></Button>
                            <Button onClick={()=>setOpcao(5)} className={classes.buttonLapisVermelho} title="Lápis Vermelho"><CreateIcon/></Button>
                            <Button onClick={limpar} title="Limpar"><ClearIcon/></Button>
                            <Button onClick={limparTudo} title="Descartar tudo"><DeleteIcon/></Button>
                        </ButtonGroup>
                    </Grid> */}
          <Grid item xs={12} className={classes.gridTable}>
            <Button
              startIcon={<AddCircleIcon />}
              variant="contained"
              color="primary"
              className={classes.buttonInsert}
              onClick={() => setModalInsert(true)}
            >
              Inserir Procedimento
            </Button>
            <TabelaProcedimentos
              idOrcamento={Orcamento.id}
              listaProcedimentos={procedimentos}
              setProcedimentos={setProcedimentos}
              planoTratamento={planoEstetico}
              setPlanoTratamento={setPlanoEstetico}
              listaProdutos={produtos}
              abrirAviso={abrirAviso}
              tipoDesc={tipoDesc}
              setTipoDesc={setTipoDesc}
              total={total}
              setTotal={setTotal}
              totalFinal={totalFinal}
              setTotalFinal={setTotalFinal}
              desconto={desconto}
              setDesconto={setDesconto}
              ratear={ratear}
              setRatear={setRatear}
            />
            {modalInsert && (
              <InserirProcedimentoEstetico
                idOrcamento={Orcamento.id}
                listaProcedimentos={procedimentos}
                planoTratamento={planoEstetico}
                setPlanoTratamento={setPlanoEstetico}
                idProcedimento={idProcedimento}
                setIdProcedimento={setIdProcedimento}
                valorProcedimento={valorProcedimento}
                listaProdutos={produtos}
                produto={idProduto}
                setProduto={setIdProduto}
                areaTratada={areaTratada}
                setAreaTratada={setAreaTratada}
                quantidade={quantidade}
                setQuantidade={setQuantidade}
                modal={modalInsert}
                setModal={setModalInsert}
                abrirAviso={abrirAviso}
              />
            )}
          </Grid>
        </Grid>
      )}
      <Notificacao
        aviso={aviso}
        fecharAviso={() => setAviso(false)}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </>
  );
}
