import React from 'react';
import Cabecalho from '../../../utils/cabecalho';
import Rodape from '../../../utils/rodape';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import PrintIcon from '@material-ui/icons/Print';
import { formatData, formatReal } from '../../../utils/funcoes';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 'auto',
  },
  tableCell: {
    fontSize: '12px',
    padding: '4px 4px',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(15),
    right: theme.spacing(1.5),
  },
  titulo: {
    textAlign: 'center',
  },
  saldo: {
    margin: '10px 0px',
  },
}));

export default function ImprimirMovimentacao() {
  const mov = JSON.parse(atob(sessionStorage.getItem('mov')));
  const movimentacao = JSON.parse(mov.movimentacao);
  const classes = useStyles();

  const tipoContaIcone = tipo => {
    return tipo === 'SAIDA' ? (
      <ArrowUpwardIcon color="error" />
    ) : (
      <ArrowDownwardIcon color="primary" />
    );
  };

  const imprimir = () => {
    window.document.getElementById('button-print').style.display = 'none';
    window.print();
    window.document.getElementById('button-print').style.display = 'block';
    window.close();
  };

  return (
    <>
      <Cabecalho />
      <h3 className={classes.titulo}>Movimentação Financeira</h3>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="movimentacao">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>Data</TableCell>
              <TableCell className={classes.tableCell}>Tipo</TableCell>
              <TableCell className={classes.tableCell}>Lançamento</TableCell>
              <TableCell className={classes.tableCell}>Usuário</TableCell>
              <TableCell className={classes.tableCell}>Valor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {movimentacao.map((lancamento, index) => (
              <TableRow key={index}>
                <TableCell
                  className={classes.tableCell}
                  component="th"
                  scope="row"
                >
                  {formatData(lancamento.data)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {tipoContaIcone(lancamento.tipo)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {lancamento.nome}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {lancamento.funcionario}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {formatReal(lancamento.valor)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <h4 className={classes.saldo}>Recebido: {formatReal(mov.recebido)}</h4>
      <h4 className={classes.saldo}>Pago: {formatReal(mov.pago)}</h4>
      <h3 className={classes.saldo}>Saldo: {formatReal(mov.total)}</h3>
      <br />
      <Rodape />
      <Fab
        color="primary"
        size="large"
        id="button-print"
        className={classes.fab}
        onClick={imprimir}
      >
        <PrintIcon />
      </Fab>
    </>
  );
}
