import React, { useState, useEffect } from 'react';
import Menu from '../Menu';
import { getClinica } from '../../services/auth';
import Notificacao from '../../utils/notificacao';
import MenuOpcoes from '../Pacientes/menuOpcoes';
import api from '../../services/api';
import {
  Box,
  Button,
  makeStyles,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  CircularProgress,
  Backdrop,
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ImageIcon from '@material-ui/icons/Image';
import InserirAnexo from './inserirAnexo';
import InfoIcon from '@material-ui/icons/Info';
import ModalImage from 'react-modal-image';
import InfoAnexo from './infoAnexo';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '90%',
    // height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  button: {
    marginTop: '60px',
    margin: theme.spacing(1),
  },
  menuOpcoes: {
    marginTop: theme.spacing(7.5),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function Anexos(props) {
  const [loading, setLoading] = useState(true);
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const Paciente = JSON.parse(atob(props.match.params.id));
  const [imagens, setImagens] = useState([]);
  const [imagem, setImagem] = useState(null);
  const [modalInserir, setModalInserir] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);

  const classes = useStyles();

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const atualizar = async () => {
    await api
      .get(`Anexo/list.php?pdo=${getClinica()}&id=${Paciente.id}`)
      .then(res => setImagens(res.data));
    setLoading(false);
  };

  const infoAnexo = imagem => {
    setImagem(imagem);
    setModalInfo(true);
  };

  useEffect(() => {
    atualizar();
  }, []);

  return (
    <>
      <Backdrop className={classes.backdrop} open={openUpload}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Menu titulo={`Pacientes ► ${Paciente.nome} ► Anexos`} />
      <Box
        display="flex"
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={() => (window.location.pathname = '/pacientes')}
          startIcon={<NavigateBeforeIcon />}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<ImageIcon />}
          onClick={() => setModalInserir(true)}
        >
          Anexar
        </Button>
        <div className={classes.menuOpcoes}>
          <MenuOpcoes paciente={Paciente} />
        </div>
      </Box>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <div className={classes.root}>
          <GridList cellHeight={300} className={classes.gridList}>
            {/* <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
                        <ListSubheader component="div">December</ListSubheader>
                    </GridListTile> */}
            {imagens.map(img => (
              <GridListTile key={img.id}>
                <ModalImage
                  small={`https://sigecli.com.br${img.caminho}`}
                  large={`https://sigecli.com.br${img.caminho}`}
                  alt={img.name}
                  showRotate={true}
                />
                <GridListTileBar
                  title={img.name}
                  subtitle={<span>Upado por: {img.usuario}</span>}
                  actionIcon={
                    <IconButton
                      aria-label={`info about ${img.name}`}
                      className={classes.icon}
                      onClick={() => infoAnexo(img)}
                    >
                      <InfoIcon />
                    </IconButton>
                  }
                />
              </GridListTile>
            ))}
          </GridList>
        </div>
      )}
      {modalInserir && (
        <InserirAnexo
          modal={modalInserir}
          setModal={setModalInserir}
          imagens={imagens}
          setImagens={setImagens}
          idCliente={Paciente.id}
          abrirAviso={abrirAviso}
          openUpload={openUpload}
          setOpenUpload={setOpenUpload}
        />
      )}
      {modalInfo && (
        <InfoAnexo
          modal={modalInfo}
          setModal={setModalInfo}
          imagem={imagem}
          imagens={imagens}
          setImagens={setImagens}
          abrirAviso={abrirAviso}
        />
      )}
      <Notificacao
        aviso={aviso}
        fecharAviso={() => setAviso(false)}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </>
  );
}
