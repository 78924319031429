import React from 'react';
import Cabecalho from '../../utils/cabecalho';
import Rodape from '../../utils/rodape';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import PrintIcon from '@material-ui/icons/Print';
import { formatData } from '../../utils/funcoes';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(15),
    right: theme.spacing(1.5),
  },
  titulo: {
    textAlign: 'center',
  },
  totalizador: {
    fontSize: '11px',
    textAlign: 'end',
  },
}));

export default function ImprimirRelatorioAniversariantes() {
  const reg = JSON.parse(atob(sessionStorage.getItem('relNiver')));
  const registros = JSON.parse(reg.registros);
  const classes = useStyles();

  const imprimir = () => {
    window.document.getElementById('button-print').style.display = 'none';
    window.print();
    window.document.getElementById('button-print').style.display = 'block';
    window.close();
  };

  return (
    <>
      <Cabecalho />
      <h3 className={classes.titulo}>Relatório Aniversáriantes do Mês</h3>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Nome</b>
              </TableCell>
              <TableCell>
                <b>Nascimento</b>
              </TableCell>
              <TableCell>
                <b>Sexo</b>
              </TableCell>
              <TableCell>
                <b>Telefone</b>
              </TableCell>
              <TableCell>
                <b>Celular</b>
              </TableCell>
              <TableCell>
                <b>Convênio</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {registros.map(registro => {
              return (
                <TableRow key={registro.id}>
                  <TableCell>{registro.nome}</TableCell>
                  <TableCell>{formatData(registro.dataNasc)}</TableCell>
                  <TableCell>
                    {registro.sexo === 'f' ? 'Feminino' : 'Masculino'}
                  </TableCell>
                  <TableCell>{registro.telefone}</TableCell>
                  <TableCell>{registro.celular}</TableCell>
                  <TableCell>{registro.convenio}</TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell colSpan={9} className={classes.totalizador}>
                {registros.length} registro(s)
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <Rodape />
      <Fab
        color="primary"
        size="large"
        id="button-print"
        className={classes.fab}
        onClick={imprimir}
      >
        <PrintIcon />
      </Fab>
    </>
  );
}
