import React, { useState, useEffect } from 'react';
import Menu from '../Menu';
import apiWhats from '../../services/apiWhats';
import {
  Button,
  CircularProgress,
  Container,
  makeStyles,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import QRCode from 'qrcode.react';
import socketIOClient from 'socket.io-client';
const SERVER = 'http://127.0.0.1:4001';

const useStyles = makeStyles(theme => ({
  info: {
    fontSize: 30,
    marginTop: '90px',
    display: 'flex',
    justifyContent: 'center',
  },
  qrCode: {
    marginTop: '150px',
    marginLeft: '22vh',
  },
  botaoDesconectar: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 50,
    width: '100%',
  },
}));

export default function ConfiguracaoWhatsApp() {
  const [qrCode, setQrCode] = useState(null);
  const [auth, setAuth] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    const socket = socketIOClient(SERVER);
    socket.on('qrcode', data => {
      console.log(data);
      setQrCode(data);
    });

    socket.on('checkAuth', data => {
      console.log(data);
      setAuth(data);
    });
  }, []);

  const desconectar = async () => {
    await apiWhats.post('/desconectar');

    window.location.reload();
  };

  return (
    <Container maxWidth="md">
      <Menu titulo="Configuração de WhatsApp" />
      {auth !== 'CONNECTED' && !qrCode && (
        <>
          <CircularProgress
            style={{
              marginTop: '250px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
          <b className={classes.info}>AGUARDE ENQUANTO O QRCODE É GERADO</b>
          <i className={classes.info}>Isso pode levar alguns instantes...</i>
        </>
      )}
      {auth === 'DISCONNECTED' && (
        <>
          {qrCode && (
            <>
              <QRCode value={qrCode} className={classes.qrCode} size={500} />
              <b className={classes.info}>APROXIME O CELULAR NO QRCODE</b>
            </>
          )}
        </>
      )}

      {auth === 'CONNECTED' && (
        <div>
          <b className={classes.info}>WHATSAPP CONECTADO</b>
          <CheckCircleIcon
            style={{
              marginLeft: '45vh',
              color: '#15E84B',
              fontSize: 100,
            }}
          />
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={desconectar}
            className={classes.botaoDesconectar}
          >
            Desconectar
          </Button>
        </div>
      )}
    </Container>
  );
}
