import React, { useState, useEffect } from 'react';
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Box,
  Typography,
  Avatar,
  makeStyles,
  Container,
} from '@material-ui/core';
import logo from '../../assets/logo.png';
import api from '../../services/api';
import Notificacao from '../../utils/notificacao';
import { login, login_clinica, makeid } from '../../services/auth';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://sigeclin.com.br/">
        Sigeclin Sistemas
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(7),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(0, 0, 1),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export default function Login() {
  const [clinica, setClinica] = useState('');
  const [user, setUser] = useState('');
  const [senha, setSenha] = useState('');
  const [lembrar, setLembrar] = useState(false);
  const classes = useStyles();
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const fecharAviso = () => {
    setAviso(false);
  };

  useEffect(() => {
    if (localStorage.getItem('Crd')) {
      let dados = localStorage.getItem('Crd');
      let decode = JSON.parse(atob(dados.substr(19, dados.length)));
      setClinica(decode.clinica);
      setUser(decode.login);
      setSenha(atob(decode.senha));
    }
  }, []);

  const validarCampos = () => {
    if (clinica !== '' && user !== '' && senha !== '') {
      return true;
    }
    return false;
  };

  const logar = e => {
    e.preventDefault();
    if (validarCampos()) {
      api
        .post('Usuario/validate.php', {
          pdo: clinica,
          login: user,
          senha: senha,
        })
        .then(res => {
          if (res.data.id !== undefined) {
            if (lembrar) {
              localStorage.removeItem('Crd');
              let credencial = {
                clinica: clinica,
                login: user,
                senha: btoa(senha),
              };
              let dados = JSON.stringify(credencial);
              let encode = btoa(dados);
              localStorage.setItem('Crd', makeid() + encode);
            }
            login(res.data.id);
            login_clinica(clinica);
            localStorage.setItem('avatar', res.data.foto);
            window.location.pathname = '/app';
          } else {
            abrirAviso('error', 'Login ou senha inválidos.');
          }
        })
        .catch(error => abrirAviso('error', 'Clínica não encontrada.'));
    } else {
      abrirAviso('warning', 'preencha todos os campos');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar alt="SigeOdonto" src={logo} className={classes.large} />
        <Typography component="h1" variant="h5">
          SigeOdonto
        </Typography>
        <form className={classes.form} onSubmit={logar}>
          <TextField
            variant="outlined"
            margin="normal"
            value={clinica}
            onChange={e => setClinica(e.target.value)}
            fullWidth
            label="Clínica"
            name="Clinica"
          />
          <TextField
            variant="outlined"
            margin="normal"
            value={user}
            onChange={e => setUser(e.target.value)}
            fullWidth
            label="Login"
            name="email"
          />
          <TextField
            variant="outlined"
            margin="normal"
            value={senha}
            onChange={e => setSenha(e.target.value)}
            fullWidth
            label="Senha"
            type="password"
          />
          <FormControlLabel
            control={
              <Checkbox
                value={lembrar}
                color="primary"
                onChange={() => setLembrar(!lembrar)}
              />
            }
            label="Lembrar"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Entrar
          </Button>
          {/* <Grid container>
                <Grid item xs>
                    <Link href="#" variant="body2">Esqueceu sua senha?</Link>
                </Grid>
            </Grid> */}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
      <Notificacao
        aviso={aviso}
        fecharAviso={fecharAviso}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </Container>
  );
}
