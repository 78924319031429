import React, { useState } from 'react';
import api from '../../services/api';
import { getClinica, getToken } from '../../services/auth';
import { usePermissoes } from '../../hooks/permissoes';
import { useConfig } from '../../hooks/config';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';

export default function InserirAnexo({
  modal,
  setModal,
  imagens,
  setImagens,
  idCliente,
  abrirAviso,
  openUpload,
  setOpenUpload,
}) {
  const [img, setImg] = useState(null);
  const { permissoes } = usePermissoes();
  const { configuracoes } = useConfig();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const inserirImagem = async () => {
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.anexoCad !== '0' && permissoes !== false) {
        const fd = new FormData();
        fd.append('pdo', getClinica());
        fd.append('idCliente', idCliente);
        fd.append('user', getToken());
        for (let i = 0; i < img.length; i++) {
          if (img[i].size < 5000000) {
            fd.append('imagens[]', img[i]);
          }
        }
        setOpenUpload(true);
        setModal(false);
        await api
          .post('Anexo/save.php', fd)
          .then(res => {
            setImagens([...imagens, ...res.data]);
            abrirAviso('success', 'Anexo inserido.');
          })
          .catch(error => {
            console.log(error);
            abrirAviso('error', `Não foi possível realizar o upload.`);
          });
        setOpenUpload(false);
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
  };

  const onChangeImg = event => {
    setImg(event.target.files);
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Anexar (Max. 5MB)'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <b>OBS:</b> Imagem que ultrapassar o tamanho permitido será
            automaticamente removido do upload.
          </DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {/* <TextField fullWidth type="file" label="Imagem" onChange={onChangeImg} variant="outlined" InputLabelProps={{shrink: true,}}/> */}
              <input
                type="file"
                id="filesIMG"
                accept="image/png,image/jpeg"
                multiple
                onChange={onChangeImg}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={inserirImagem} variant="contained" color="primary">
            Inserir
          </Button>
          <Button
            variant="contained"
            color="default"
            onClick={() => setModal(false)}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
