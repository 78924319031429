import axios from 'axios';

const api = axios.create({
  baseURL: 'https://sigecli.com.br/api-sigeodonto/',
  auth: {
    username: 'sigeclin',
    password: 'sigeclin_sigeodonto@_2019',
  },
});
export default api;
