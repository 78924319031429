import React from 'react';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem,
  Button,
} from '@material-ui/core';

export default function CadastrarEditarProcedimento({
  modal,
  fecharModal,
  idProcedimento,
  descricao,
  setDescricao,
  valor,
  setValor,
  tipo,
  setTipo,
  configuracoes,
  ativo,
  setAtivo,
  fullScreen,
  classes,
  salvar,
  desabilitar,
}) {
  return (
    <>
      {/* cadastrar/editar procedimento */}
      <Dialog
        fullScreen={fullScreen}
        open={modal}
        onClose={fecharModal}
        aria-labelledby="responsive-dialog-title"
      >
        {idProcedimento === '' && (
          <DialogTitle id="responsive-dialog-title">
            {'Cadastrar Procedimento'}
          </DialogTitle>
        )}
        {idProcedimento !== '' && (
          <DialogTitle id="responsive-dialog-title">
            {'Editar Procedimento'}
          </DialogTitle>
        )}
        <DialogContent>
          <form className={classes.form} noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Descrição do procedimento"
                  value={descricao}
                  onChange={e => setDescricao(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Valor do procedimento"
                  value={valor}
                  onChange={e => setValor(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  select
                  label="Tipo procedimento"
                  value={tipo}
                  onChange={e => setTipo(e.target.value)}
                  variant="outlined"
                >
                  {configuracoes.moduloOdonto !== '0' && (
                    <MenuItem value="1">Odontológico</MenuItem>
                  )}
                  {configuracoes.moduloEstetico !== '0' && (
                    <MenuItem value="2">Estético</MenuItem>
                  )}
                </TextField>
              </Grid>
              {idProcedimento !== '' && (
                <Grid item xs={6}>
                  <TextField
                    select
                    fullWidth
                    label="Status"
                    value={ativo}
                    onChange={e => setAtivo(e.target.value)}
                    variant="outlined"
                  >
                    <MenuItem value="0">Ativo</MenuItem>
                    <MenuItem value="-1">Inativo</MenuItem>
                  </TextField>
                </Grid>
              )}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={salvar}
            variant="contained"
            color="primary"
            disabled={desabilitar}
          >
            Salvar
          </Button>
          <Button variant="contained" color="default" onClick={fecharModal}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
