import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useConfig } from '../../hooks/config';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import NoteIcon from '@material-ui/icons/Note';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DescriptionIcon from '@material-ui/icons/Description';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { FaTooth, FaNotesMedical } from 'react-icons/fa';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(1),
  },
  reactIcon: {
    marginLeft: '2px',
  },
}));

let cod = null;
export default function MenuOpcoes({ paciente }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { configuracoes } = useConfig();
  const classes = useStyles();

  const abrirOpcoes = (event, paciente) => {
    setAnchorEl(event.currentTarget);
    cod = btoa(JSON.stringify({ id: paciente.id, nome: paciente.nome }));
  };

  const fecharOpcoes = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        className={classes.button}
        startIcon={<MenuIcon />}
        endIcon={<ExpandMoreIcon />}
        variant="contained"
        color="primary"
        onClick={e => abrirOpcoes(e, paciente)}
      >
        Opções
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={fecharOpcoes}
      >
        <StyledMenuItem
          onClick={() =>
            (window.location.pathname = `/cad_edit_paciente/${cod}`)
          }
        >
          <ListItemIcon>
            <PersonIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText primary="Dados" />
        </StyledMenuItem>
        {configuracoes.moduloOdonto !== '0' && (
          <StyledMenuItem
            onClick={() =>
              (window.location.pathname = `/orcamentos_odonto/${cod}`)
            }
          >
            <ListItemIcon>
              <NoteIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary="Orç. Odontológicos" />
          </StyledMenuItem>
        )}
        {configuracoes.moduloEstetico !== '0' && (
          <StyledMenuItem
            onClick={() =>
              (window.location.pathname = `/orcamentos_esteticos/${cod}`)
            }
          >
            <ListItemIcon>
              <NoteIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary="Orç. Estéticos" />
          </StyledMenuItem>
        )}
        {configuracoes.moduloOdonto !== '0' && (
          <StyledMenuItem
            onClick={() =>
              (window.location.pathname = `/tratamentos_odonto/${cod}`)
            }
          >
            <ListItemIcon>
              <AssignmentIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary="Trat. Odontológicos" />
          </StyledMenuItem>
        )}
        {configuracoes.moduloEstetico !== '0' && (
          <StyledMenuItem
            onClick={() =>
              (window.location.pathname = `/tratamentos_esteticos/${cod}`)
            }
          >
            <ListItemIcon>
              <AssignmentIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary="Trat. Estéticos" />
          </StyledMenuItem>
        )}
        {configuracoes.moduloOdonto !== '0' && (
          <StyledMenuItem
            onClick={() => (window.location.pathname = `/ortodontia/${cod}`)}
          >
            <ListItemIcon>
              <FaTooth className={classes.reactIcon} size={20} />
            </ListItemIcon>
            <ListItemText primary="Ortodontia" />
          </StyledMenuItem>
        )}
        {configuracoes.moduloOdonto !== '0' && (
          <StyledMenuItem
            onClick={() => (window.location.pathname = `/prontuario/${cod}`)}
          >
            <ListItemIcon>
              <FaNotesMedical className={classes.reactIcon} size={21} />
            </ListItemIcon>
            <ListItemText primary="Prontuário" />
          </StyledMenuItem>
        )}
        <StyledMenuItem
          onClick={() => (window.location.pathname = `/atestado/${cod}`)}
        >
          <ListItemIcon>
            <LocalHospitalIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText primary="Atestado" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => (window.location.pathname = `/receituario/${cod}`)}
        >
          <ListItemIcon>
            <InsertDriveFileIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText primary="Receituário" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => (window.location.pathname = `/anexos/${cod}`)}
        >
          <ListItemIcon>
            <AttachFileIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText primary="Anexos" />
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => (window.location.pathname = `/ficha_paciente/${cod}`)}
        >
          <ListItemIcon>
            <AttachMoneyIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText primary="Ficha Financeira" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
