import React, { useState } from 'react';
import api from '../../../services/api';
import { getClinica } from '../../../services/auth';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export default function InserirProcedimentosTodos({
  idOrcamento,
  listaProcedimentos,
  planoTratamento,
  setPlanoTratamento,
  idProcedimento,
  setIdProcedimento,
  valorProcedimento,
  modal,
  setModal,
  tipoArcada,
  setTipoArcada,
  arcadaDentaria,
  setArcadaDentaria,
  estadoAtual,
  setEstadoAtual,
  abrirAviso,
  adultoCima,
  setAdultoCima,
  adultoBaixo,
  setAdultoBaixo,
  criancaCima,
  setCriancaCima,
  criancaBaixo,
  setCriancaBaixo,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const validarSelectTodos = () => {
    if (
      tipoArcada !== '' &&
      arcadaDentaria !== '' &&
      estadoAtual !== '' &&
      idProcedimento !== ''
    ) {
      return true;
    }
    return false;
  };

  const limparCampos = () => {
    setTipoArcada('');
    setArcadaDentaria('');
    setEstadoAtual('');
    setIdProcedimento('');
  };

  const incluirTodos = () => {
    if (validarSelectTodos()) {
      if (tipoArcada === 'a') {
        if (arcadaDentaria === 'c') {
          for (let i = 0; i < adultoCima.length; i++) {
            adultoCima[i].class9 = 'marcadoAzulClaro';
            adultoCima[i].alterou = 1;
            adultoBaixo[i].class9 = 'marcadoAzulClaro';
            adultoBaixo[i].alterou = 1;
          }
          if (idOrcamento !== null) {
            api
              .post('PlanoTratamento/save.php', {
                pdo: getClinica(),
                idTratamento: idOrcamento,
                procedimento: idProcedimento,
                idDente: 0,
                numDente: 'Adulto - Todos',
                face: '',
                tipo: 'todosA',
                estadoAtual: estadoAtual,
                desconto: 0,
                valor: valorProcedimento(idProcedimento),
                status: 0,
              })
              .then(res => {
                abrirAviso('success', 'Procedimento inserido.');
                setPlanoTratamento([...planoTratamento, res.data]);
                setModal(false);
                limparCampos();
              });
          } else {
            const incluir = {
              id: 0,
              dente: 'Adulto - Todos',
              tipo: 'todosA',
              face: '',
              estadoAtual: estadoAtual,
              idProcedimento: idProcedimento,
              valor: valorProcedimento(idProcedimento),
            };
            setPlanoTratamento([...planoTratamento, incluir]);
            setAdultoCima([...adultoCima]);
            setAdultoBaixo([...adultoBaixo]);
          }
        } else if (arcadaDentaria === 's') {
          for (let i = 0; i < adultoCima.length; i++) {
            adultoCima[i].class9 = 'marcadoAzulClaro';
            adultoCima[i].alterou = 1;
          }
          if (idOrcamento !== null) {
            api
              .post('PlanoTratamento/save.php', {
                pdo: getClinica(),
                idTratamento: idOrcamento,
                procedimento: idProcedimento,
                idDente: 0,
                numDente: 'Adulto - Arc. Superior',
                face: '',
                tipo: 'superiorA',
                estadoAtual: estadoAtual,
                desconto: 0,
                valor: valorProcedimento(idProcedimento),
                status: 0,
              })
              .then(res => {
                abrirAviso('success', 'Procedimento inserido.');
                setPlanoTratamento([...planoTratamento, res.data]);
                setModal(false);
                limparCampos();
              });
          } else {
            const incluir = {
              id: 0,
              dente: 'Adulto - Arc. Superior',
              tipo: 'superiorA',
              face: '',
              estadoAtual: estadoAtual,
              idProcedimento: idProcedimento,
              valor: valorProcedimento(idProcedimento),
            };
            setPlanoTratamento([...planoTratamento, incluir]);
            setAdultoCima([...adultoCima]);
          }
        } else if (arcadaDentaria === 'i') {
          for (let i = 0; i < adultoBaixo.length; i++) {
            adultoBaixo[i].class9 = 'marcadoAzulClaro';
            adultoBaixo[i].alterou = 1;
          }
          if (idOrcamento !== null) {
            api
              .post('PlanoTratamento/save.php', {
                pdo: getClinica(),
                idTratamento: idOrcamento,
                procedimento: idProcedimento,
                idDente: 0,
                numDente: 'Adulto - Arc. Inferior',
                face: '',
                tipo: 'inferiorA',
                estadoAtual: estadoAtual,
                desconto: 0,
                valor: valorProcedimento(idProcedimento),
                status: 0,
              })
              .then(res => {
                abrirAviso('success', 'Procedimento inserido.');
                setPlanoTratamento([...planoTratamento, res.data]);
                setModal(false);
                limparCampos();
              });
          } else {
            const incluir = {
              id: 0,
              dente: 'Adulto - Arc. Inferior',
              tipo: 'inferiorA',
              face: '',
              estadoAtual: estadoAtual,
              idProcedimento: idProcedimento,
              valor: valorProcedimento(idProcedimento),
            };
            setPlanoTratamento([...planoTratamento, incluir]);
            setAdultoBaixo([...adultoBaixo]);
          }
        }
      } else if (tipoArcada === 'i') {
        if (arcadaDentaria === 'c') {
          for (let i = 0; i < criancaCima.length; i++) {
            criancaCima[i].class9 = 'marcadoAzulClaro';
            criancaCima[i].alterou = 1;
            criancaBaixo[i].class9 = 'marcadoAzulClaro';
            criancaBaixo[i].alterou = 1;
          }
          if (idOrcamento !== null) {
            api
              .post('PlanoTratamento/save.php', {
                pdo: getClinica(),
                idTratamento: idOrcamento,
                procedimento: idProcedimento,
                idDente: 0,
                numDente: 'Infantil - Todos',
                face: '',
                tipo: 'todosI',
                estadoAtual: estadoAtual,
                desconto: 0,
                valor: valorProcedimento(idProcedimento),
                status: 0,
              })
              .then(res => {
                abrirAviso('success', 'Procedimento inserido.');
                setPlanoTratamento([...planoTratamento, res.data]);
                setModal(false);
                limparCampos();
              });
          } else {
            const incluir = {
              id: 0,
              dente: 'Infantil - Todos',
              tipo: 'todosI',
              face: '',
              estadoAtual: estadoAtual,
              idProcedimento: idProcedimento,
              valor: valorProcedimento(idProcedimento),
            };
            setPlanoTratamento([...planoTratamento, incluir]);
            setCriancaCima([...criancaCima]);
            setCriancaBaixo([...criancaBaixo]);
          }
        } else if (arcadaDentaria === 's') {
          for (let i = 0; i < criancaCima.length; i++) {
            criancaCima[i].class9 = 'marcadoAzulClaro';
            criancaCima[i].alterou = 1;
          }
          if (idOrcamento !== null) {
            api
              .post('PlanoTratamento/save.php', {
                pdo: getClinica(),
                idTratamento: idOrcamento,
                procedimento: idProcedimento,
                idDente: 0,
                numDente: 'Infantil - Arc. Superior',
                face: '',
                tipo: 'superiorI',
                estadoAtual: estadoAtual,
                desconto: 0,
                valor: valorProcedimento(idProcedimento),
                status: 0,
              })
              .then(res => {
                abrirAviso('success', 'Procedimento inserido.');
                setPlanoTratamento([...planoTratamento, res.data]);
                setModal(false);
                limparCampos();
              });
          } else {
            const incluir = {
              id: 0,
              dente: 'Infantil - Arc. Superior',
              tipo: 'superiorI',
              face: '',
              estadoAtual: estadoAtual,
              idProcedimento: idProcedimento,
              valor: valorProcedimento(idProcedimento),
            };
            setPlanoTratamento([...planoTratamento, incluir]);
            setCriancaCima([...criancaCima]);
          }
        } else if (arcadaDentaria === 'i') {
          for (let i = 0; i < criancaBaixo.length; i++) {
            criancaBaixo[i].class9 = 'marcadoAzulClaro';
            criancaBaixo[i].alterou = 1;
          }
          if (idOrcamento !== null) {
            api
              .post('PlanoTratamento/save.php', {
                pdo: getClinica(),
                idTratamento: idOrcamento,
                procedimento: idProcedimento,
                idDente: 0,
                numDente: 'Infantil - Arc. Inferior',
                face: '',
                tipo: 'inferiorI',
                estadoAtual: estadoAtual,
                desconto: 0,
                valor: valorProcedimento(idProcedimento),
                status: 0,
              })
              .then(res => {
                abrirAviso('success', 'Procedimento inserido.');
                setPlanoTratamento([...planoTratamento, res.data]);
                setModal(false);
                limparCampos();
              });
          } else {
            const incluir = {
              id: 0,
              dente: 'Infantil - Arc. Inferior',
              tipo: 'inferiorI',
              face: '',
              estadoAtual: estadoAtual,
              idProcedimento: idProcedimento,
              valor: valorProcedimento(idProcedimento),
            };
            setPlanoTratamento([...planoTratamento, incluir]);
            setCriancaBaixo([...criancaBaixo]);
          }
        }
      }
      abrirAviso('success', 'Procedimento inserido.');
      limparCampos();
      setModal(false);
    } else {
      abrirAviso('warning', 'Preencha todos os campos.');
    }
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Selecionar todos os dentes'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="Plano de tratamento proposto"
                value={idProcedimento}
                onChange={e => setIdProcedimento(e.target.value)}
                variant="outlined"
              >
                <MenuItem value="" selected>
                  Selecione...
                </MenuItem>
                {listaProcedimentos.map(pro => (
                  <MenuItem key={pro.id} value={pro.id}>
                    {pro.descricao}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="Tipo de arcada dentária"
                value={tipoArcada}
                onChange={e => setTipoArcada(e.target.value)}
                variant="outlined"
              >
                <MenuItem value="" selected>
                  Selecione
                </MenuItem>
                <MenuItem value="a" selected>
                  Adulta
                </MenuItem>
                <MenuItem value="i" selected>
                  Infantil
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Estado atual do dente"
                value={estadoAtual}
                onChange={e => setEstadoAtual(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="Selecionar arcada"
                value={arcadaDentaria}
                onChange={e => setArcadaDentaria(e.target.value)}
                variant="outlined"
              >
                <MenuItem value="" selected>
                  Selecione
                </MenuItem>
                <MenuItem value="c" selected>
                  Completa
                </MenuItem>
                <MenuItem value="s" selected>
                  Superior
                </MenuItem>
                <MenuItem value="i" selected>
                  Inferior
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={incluirTodos} variant="contained" color="primary">
            Inserir
          </Button>
          <Button
            variant="contained"
            color="default"
            onClick={() => setModal(false)}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
