import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import { getClinica } from '../../../services/auth';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem,
  Button,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { formatReal } from '../../../utils/funcoes';

const columns = [
  { field: 'id', headerName: 'ID', width: 100 },
  {
    field: 'areaTratada',
    headerName: 'Área Tratada',
    width: 220,
  },
  {
    field: 'produto',
    headerName: 'Produto',
    width: 200,
  },
  {
    field: 'quantidade',
    headerName: 'Quantidade',
    width: 150,
  },
  {
    field: 'procedimento',
    headerName: 'Procedimento',
    width: 280,
  },
  {
    field: 'valor',
    headerName: 'Valor',
    width: 160,
  },
];

export default function ReutilizarOrcamento({
  modal,
  fecharModal,
  orcamento,
  setOrcamentos,
  abrirAviso,
}) {
  const [desabilitar, setDesabilitar] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [planoEstetico, setPlanoEstetico] = useState([]);

  const listarProcedimentos = async () => {
    await api
      .post(
        'PlanoEstetico/list.php?id=' + orcamento.id + '&pdo=' + getClinica(),
      )
      .then(res => {
        console.log(res.data);
        let linhas = [];
        res.data.map(procedimento => {
          linhas.push({
            id: procedimento.id,
            areaTratada: procedimento.areaTratada,
            produto: procedimento.nomeProduto,
            quantidade: procedimento.quantidade,
            procedimento: procedimento.descricaoProcedimento,
            valor: formatReal(procedimento.valor),
          });
        });
        setPlanoEstetico(res.data);
        setRows(linhas);
      });
  };

  const confirmar = async () => {
    setDesabilitar(true);
    if (selected.length > 0) {
      let planos = [];
      selected.map(s => {
        planoEstetico.map(plano => {
          if (s === plano.id) {
            planos.push({
              idProcedimento: plano.tipo,
              area: plano.areaTratada,
              produto: plano.produto,
              quantidade: plano.quantidade,
              valor: plano.valor,
            });
          }
        });
      });

      await api
        .post('Estetico/save.php', {
          pdo: getClinica(),
          idDentista: orcamento.idDentista,
          idCliente: orcamento.idCliente,
          planosTratamento: planos,
          ratear: 0,
          img: null,
          refTratamento: orcamento.id,
        })
        .then(res => {
          abrirAviso('success', `Orçamento Nº${res.data} cadastrado.`);
          fecharModal();
          setTimeout(function () {
            window.location.reload();
          }, 1400);
        });
    } else {
      abrirAviso('warning', `Selecione os procedimentos.`);
    }
    setDesabilitar(false);
  };

  useEffect(() => {
    listarProcedimentos();
  }, [modal]);

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={modal}
        onClose={fecharModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{`Reutilizar procedimentos do orçamento Nº ${orcamento.id}`}</DialogTitle>
        <DialogContent>
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              checkboxSelection
              autoHeight
              onSelectionModelChange={selected => setSelected(selected)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={confirmar}
            variant="contained"
            color="primary"
            disabled={desabilitar}
          >
            Confirmar
          </Button>
          <Button variant="contained" color="default" onClick={fecharModal}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
