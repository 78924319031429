import React, { useState, useEffect } from 'react';
import {
  MenuItem,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from '@material-ui/core';
import { usePermissoes } from '../../hooks/permissoes';
import { useConfig } from '../../hooks/config';
import api from '../../services/api';
import { getClinica } from '../../services/auth';

export default function CadastrarEditarCentroCusto({
  fullScreen,
  modal,
  setModal,
  centro,
  centroCustos,
  setCentroCustos,
  abrirAviso,
}) {
  const { configuracoes } = useConfig();
  const { permissoes } = usePermissoes();
  const [desabilitar, setDesabilitar] = useState(false);
  const [descricao, setDescricao] = useState(null);
  const [ativo, setAtivo] = useState(null);

  const salvar = () => {
    centro ? alterar() : cadastrar();
  };

  const cadastrar = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.centroCad !== '0' && permissoes !== false) {
        if (descricao) {
          await api
            .post('CentroCusto/save.php', {
              pdo: getClinica(),
              descricao: descricao,
            })
            .then(res => {
              setCentroCustos([...centroCustos, res.data]);
              abrirAviso('success', 'Centro de custo cadastrado.');
              setModal(false);
            })
            .catch(error => console.log(error));
        } else {
          abrirAviso('warning', 'Preencha todos os campos obrigatórios.');
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
    setDesabilitar(false);
  };

  const alterar = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.centroAlt !== '0' && permissoes !== false) {
        if (descricao) {
          await api
            .post('CentroCusto/update.php', {
              pdo: getClinica(),
              descricao: descricao,
              ativo: ativo,
              id: centro.id,
            })
            .then(res => {
              setCentroCustos(state =>
                state.map(e => {
                  if (e.id !== res.data.id) {
                    return e;
                  }
                  e = res.data;
                  return e;
                }),
              );
              abrirAviso('success', 'Centro de custo alterado.');
              setModal(false);
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          abrirAviso('warning', 'Preencha todos os campos obrigatórios.');
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
    setDesabilitar(false);
  };

  useEffect(() => {
    if (centro) {
      setDescricao(centro.descricao);
      setAtivo(centro.ativo);
    }
  }, [modal]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={modal}
      onClose={() => setModal(false)}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {!centro ? 'Cadastrar Centro de Custo' : 'Editar Centro de Custo'}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Descrição"
              value={descricao}
              onChange={e => setDescricao(e.target.value)}
              variant="outlined"
            />
          </Grid>
          {centro && (
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Status"
                value={ativo}
                onChange={e => setAtivo(e.target.value)}
                variant="outlined"
              >
                <MenuItem value={1}>Ativo</MenuItem>
                <MenuItem value={0}>Inativo</MenuItem>
              </TextField>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={salvar}
          variant="contained"
          color="primary"
          disabled={desabilitar}
        >
          Salvar
        </Button>
        <Button
          variant="contained"
          color="default"
          onClick={() => setModal(false)}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
