import React from 'react';
import Cabecalho from '../../utils/cabecalho';
import Rodape from '../../utils/rodape';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import PrintIcon from '@material-ui/icons/Print';
import { formatData, formatReal } from '../../utils/funcoes';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(15),
    right: theme.spacing(1.5),
  },
  titulo: {
    textAlign: 'center',
  },
  totalizador: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
}));

export default function ImprimirRelatorioProducaoOdonto() {
  const reg = JSON.parse(atob(sessionStorage.getItem('relPO')));
  const registros = JSON.parse(reg.registros);
  const classes = useStyles();

  const imprimir = () => {
    window.document.getElementById('button-print').style.display = 'none';
    window.print();
    window.document.getElementById('button-print').style.display = 'block';
    window.close();
  };

  return (
    <>
      <Cabecalho />
      <h3 className={classes.titulo}>Relatório de Produção (Odonto)</h3>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Data</b>
              </TableCell>
              <TableCell>
                <b>Procedimento</b>
              </TableCell>
              <TableCell>
                <b>Paciente</b>
              </TableCell>
              <TableCell>
                <b>Profissional</b>
              </TableCell>
              <TableCell>
                <b>Convênio</b>
              </TableCell>
              <TableCell>
                <b>Valor</b>
              </TableCell>
              <TableCell>
                <b>% Profissional</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {registros.map(registro => {
              return (
                <TableRow key={registro.id}>
                  <TableCell>{formatData(registro.dataCriacao)}</TableCell>
                  <TableCell>{registro.descricaoProcedimento}</TableCell>
                  <TableCell>{registro.paciente}</TableCell>
                  <TableCell>{registro.dentista}</TableCell>
                  <TableCell>{registro.convenio}</TableCell>
                  <TableCell>{formatReal(registro.valor)}</TableCell>
                  <TableCell>{formatReal(registro.porcentagem)}</TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell colSpan={3} className={classes.totalizador}>
                Total
              </TableCell>
              <TableCell colSpan={2} className={classes.totalizador}>
                {registros.length} registro(s)
              </TableCell>
              <TableCell className={classes.totalizador}>
                {formatReal(reg.total)}
              </TableCell>
              <TableCell className={classes.totalizador}>
                {formatReal(reg.porc)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <Rodape />
      <Fab
        color="primary"
        size="large"
        id="button-print"
        className={classes.fab}
        onClick={imprimir}
      >
        <PrintIcon />
      </Fab>
    </>
  );
}
