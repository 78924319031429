import React, { useState, useEffect } from 'react';
import api from '../services/api';
import { getClinica } from '../services/auth';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  textEmpresa: {
    textAlign: 'center',
  },
}));

export default function Rodape() {
  const [empresa, setEmpresa] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    api.get('Empresa/list.php?pdo=' + getClinica()).then(res => {
      setEmpresa(res.data[0]);
    });
  }, []);

  return (
    <>
      {empresa && (
        <div>
          {empresa.logoCabecalho !== null && (
            <div className={classes.textEmpresa}>
              <p>
                <i>
                  {empresa.logradouro}, {empresa.numero} {empresa.complemento} -{' '}
                  {empresa.bairro} - {empresa.cidade} - {empresa.estado}
                </i>
                <br />
                <i>
                  Contato: {empresa.telefone}
                  {empresa.telefone2 !== '' && <i>/{empresa.telefone2}</i>}{' '}
                  {empresa.email !== '' && <i> - Email: {empresa.email}</i>}
                </i>
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
}
