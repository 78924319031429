import React from 'react';
import {
  Grid,
  TextField,
  MenuItem,
  Paper,
  makeStyles,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
    marginTop: '8px',
  },
}));

export default function PerfilPsicologico({
  pPCalma,
  setPPCalma,
  pPAgitada,
  setPPAgitada,
  pPExtrovertida,
  setPPExtrovertida,
  pPIntrovertida,
  setPPIntrovertida,
  pPComunicativa,
  setPPComunicativa,
  pPReservada,
  setPPReservada,
  pPObs,
  setPPObs,
}) {
  const classes = useStyles();
  return (
    <>
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <FormControlLabel
              checked={pPCalma}
              control={<Checkbox color="primary" />}
              onChange={() => setPPCalma(!pPCalma)}
              label="Calma"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              checked={pPAgitada}
              control={<Checkbox color="primary" />}
              onChange={() => setPPAgitada(!pPAgitada)}
              label="Agitada"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              checked={pPExtrovertida}
              control={<Checkbox color="primary" />}
              onChange={() => setPPExtrovertida(!pPExtrovertida)}
              label="Extrovertida"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              checked={pPIntrovertida}
              control={<Checkbox color="primary" />}
              onChange={() => setPPIntrovertida(!pPIntrovertida)}
              label="Introvertida"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              checked={pPComunicativa}
              control={<Checkbox color="primary" />}
              onChange={() => setPPComunicativa(!pPComunicativa)}
              label="Comunicativa"
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              checked={pPReservada}
              control={<Checkbox color="primary" />}
              onChange={() => setPPReservada(!pPReservada)}
              label="Reservada"
              labelPlacement="end"
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={2} className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              label="Observações"
              fullWidth
              value={pPObs}
              onChange={e => setPPObs(e.target.value)}
              variant="outlined"
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
