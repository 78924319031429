import React, { useState, useEffect } from 'react';
import {
  MenuItem,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  useTheme,
  useMediaQuery,
  Box,
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import api from '../../services/api';
import { getClinica, getToken } from '../../services/auth';
import { statusOrdemServico } from '../../utils/funcoes';
import GerarFinanceiroOS from './gerarFinanceiro';

export default function CadastrarEditarOs({
  modal,
  fecharModal,
  ordemServico,
  ordensServico,
  setOrdensServico,
  clientes,
  profissionais,
  laborarios,
  abrirAviso,
}) {
  const [desabilitar, setDesabilitar] = useState(false);
  const [cliente, setCliente] = useState();
  const [profissional, setProfissional] = useState();
  const [laboratorio, setLaboratorio] = useState();
  const [descricao, setDescricao] = useState(null);
  const [status, setStatus] = useState(null);
  const [dataPrevisao, setDataPrevisao] = useState(null);
  const [valor, setValor] = useState(0);
  const [cor, setCor] = useState(null);
  const [ativo, setAtivo] = useState(null);
  const [centrosCusto, setCentrosCusto] = useState([]);
  const [observacao, setObservacao] = useState(null);
  const [modalGerarFinanceiro, setModalGerarFinanceiro] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const cadastrar = async () => {
    setDesabilitar(true);
    if (
      profissional &&
      cliente &&
      descricao &&
      laboratorio &&
      dataPrevisao &&
      valor
    ) {
      await api
        .post('OrdemServico/save.php', {
          pdo: getClinica(),
          idDentista: profissional,
          idPaciente: cliente,
          descricao: descricao,
          status: 1,
          cor: cor,
          idLaboratorio: laboratorio,
          valor: valor,
          gerarFinanceiro: 0,
          dataEntrega: dataPrevisao,
        })
        .then(res => {
          setOrdensServico([...ordensServico, res.data]);
          abrirAviso('success', 'Ordem de serviço cadastrada.');
          fecharModal();
        })
        .catch(error => {
          console.log(error);
          abrirAviso('error', 'Ocorreu um problema. Tente novamente.');
        });
    } else {
      abrirAviso('error', 'Preencha todos os campos.');
    }
    setDesabilitar(false);
  };

  const alterar = async () => {
    setDesabilitar(true);
    if (
      profissional &&
      cliente &&
      descricao &&
      laboratorio &&
      dataPrevisao &&
      valor
    ) {
      await api
        .post('OrdemServico/update.php', {
          pdo: getClinica(),
          idDentista: profissional,
          idPaciente: cliente,
          descricao: descricao,
          status: status,
          idLaboratorio: laboratorio,
          valor: valor,
          cor: cor,
          dataEntrega: dataPrevisao,
          user: getToken(),
          observacao: observacao,
          id: ordemServico.id,
        })
        .then(res => {
          setOrdensServico(state =>
            state.map(e => {
              if (e.id !== res.data.id) {
                return e;
              }
              e = res.data;
              return e;
            }),
          );
          abrirAviso('success', 'Ordem de serviço alterada.');
          fecharModal();
        })
        .catch(error => {
          console.log(error);
          abrirAviso('error', 'Ocorreu um problema. Tente novamente.');
        });
    } else {
      abrirAviso('error', 'Preencha todos os campos.');
    }
    setDesabilitar(false);
  };

  const atualizar = async () => {
    await api
      .post('CentroCusto/search.php', {
        pdo: getClinica(),
        consulta: 'AND ativo=1',
      })
      .then(res => {
        res.data ? setCentrosCusto(res.data) : setCentrosCusto([]);
      })
      .catch(error => console.log(error));
  };

  useEffect(() => {
    if (ordemServico) {
      setProfissional(ordemServico.idDentista);
      setCliente(ordemServico.idPaciente);
      setDescricao(ordemServico.descricao);
      setLaboratorio(ordemServico.idLaboratorio);
      setDataPrevisao(ordemServico.dataEntrega);
      setCor(ordemServico.cor);
      setValor(ordemServico.valor);
      setStatus(ordemServico.status);
      atualizar();
    }
  }, [ordemServico]);

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={modal}
        onClose={fecharModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {!ordemServico
            ? 'Cadastrar Ordem de Serviço'
            : `Editar Ordem de Serviço Nº ${ordemServico.id}`}
        </DialogTitle>
        <DialogContent>
          {ordemServico && (
            <Box
              display="flex"
              justifyContent="flex-end"
              m={1}
              bgcolor="background.paper"
            >
              <Button
                variant="contained"
                startIcon={<AttachMoneyIcon />}
                disabled={!!parseInt(ordemServico.gerarFinanceiro)}
                style={{
                  color: !!!parseInt(ordemServico.gerarFinanceiro)
                    ? '#fff'
                    : '#939393',
                  marginLeft: 5,
                  background: !!!parseInt(ordemServico.gerarFinanceiro)
                    ? '#4caf50'
                    : '#E0E0E0',
                }}
                onClick={() => {
                  setModalGerarFinanceiro(true);
                }}
              >
                {!!!parseInt(ordemServico.gerarFinanceiro)
                  ? 'Gerar Financeiro'
                  : 'Financeiro Gerado'}
              </Button>
            </Box>
          )}
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Profissional"
                value={profissional}
                onChange={e => setProfissional(e.target.value)}
                variant="outlined"
              >
                <MenuItem>Selecione</MenuItem>
                {profissionais.map(p => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.nome}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Paciente"
                value={cliente}
                onChange={e => setCliente(e.target.value)}
                variant="outlined"
              >
                <MenuItem>Selecione</MenuItem>
                {clientes.map(p => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.nome}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                rows={5}
                fullWidth
                label="Descrição"
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Laboratório"
                value={laboratorio}
                onChange={e => setLaboratorio(e.target.value)}
                variant="outlined"
              >
                <MenuItem>Selecione</MenuItem>
                {laborarios.map(lab => (
                  <MenuItem key={lab.id} value={lab.id}>
                    {lab.nome}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="date"
                fullWidth
                label="Previsão de Entrega"
                value={dataPrevisao}
                onChange={e => setDataPrevisao(e.target.value)}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Cor"
                value={cor}
                onChange={e => setCor(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="number"
                fullWidth
                label="Valor"
                value={valor}
                onChange={e => setValor(e.target.value)}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {ordemServico && (
              <>
                <Grid item xs={4}>
                  <TextField
                    select
                    fullWidth
                    label="Status"
                    value={status}
                    onChange={e => {
                      setStatus(e.target.value);
                    }}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {statusOrdemServico.map(p => (
                      <MenuItem key={p.value} value={p.value}>
                        {p.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <h5>Histórico</h5>
                  <TextField
                    multiline
                    rows={2}
                    fullWidth
                    label="Observações"
                    value={observacao}
                    onChange={e => setObservacao(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={ordemServico ? alterar : cadastrar}
            variant="contained"
            color="primary"
            disabled={desabilitar}
          >
            Salvar
          </Button>
          <Button variant="contained" color="default" onClick={fecharModal}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      {modalGerarFinanceiro && (
        <GerarFinanceiroOS
          modal={modalGerarFinanceiro}
          fecharModal={() => setModalGerarFinanceiro(false)}
          ordemServico={ordemServico}
          centrosCusto={centrosCusto}
          setOrdensServico={setOrdensServico}
          abrirAviso={abrirAviso}
        />
      )}
    </>
  );
}
