import React, { useState } from 'react';
import clsx from 'clsx';
import { usePermissoes } from '../../hooks/permissoes';
import { useConfig } from '../../hooks/config';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Drawer from '@material-ui/core/Drawer';
import EventIcon from '@material-ui/icons/Event';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import BuildIcon from '@material-ui/icons/Build';
import PeopleIcon from '@material-ui/icons/People';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import DescriptionIcon from '@material-ui/icons/Description';
import NoteIcon from '@material-ui/icons/Note';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import BusinessIcon from '@material-ui/icons/Business';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Menu from '@material-ui/core/Menu';
import { logout, logout_clinica } from '../../services/auth';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { FaFlask } from 'react-icons/fa';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const useStyles = makeStyles(theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  title: {
    flexGrow: 1,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const StyledBadge = withStyles(theme => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

export default function SwipeableTemporaryDrawer(props) {
  const { permissoes } = usePermissoes();
  const { configuracoes } = useConfig();
  const classes = useStyles();
  const [state, setState] = React.useState({ left: false });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openOrcamento, setOpenOrcamento] = useState(false);
  const [openFinanceiro, setOpenFinanceiro] = useState(false);
  const [openRelatorio, setOpenRelatorio] = useState(false);
  const [openOS, setOpenOS] = useState(false);
  const foto = `https://sigecli.com.br${localStorage.getItem('avatar')}`;

  const toggleDrawer = (anchor, open) => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const sair = e => {
    logout();
    logout_clinica();
    window.location.pathname = '/';
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const opcoesMenu = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {/* agenda */}
        {permissoes.agenda !== '0' && (
          <ListItem button onClick={() => (window.location.pathname = '/app')}>
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText primary="Agenda" />
          </ListItem>
        )}
        {/* pacientes */}
        {permissoes.paciente !== '0' && (
          <ListItem
            button
            onClick={() => (window.location.pathname = '/pacientes')}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Pacientes" />
          </ListItem>
        )}
        {/* funcionarios */}
        {permissoes.funcionario !== '0' && (
          <ListItem
            button
            onClick={() => (window.location.pathname = '/funcionarios')}
          >
            <ListItemIcon>
              <SupervisedUserCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Funcionários" />
          </ListItem>
        )}
        {/* OS Protetico */}
        {configuracoes.moduloOdonto !== '0' && permissoes.ordemServico !== '0' && (
          <ListItem button onClick={() => setOpenOS(!openOS)}>
            <ListItemIcon>
              <PlaylistAddCheckIcon />
            </ListItemIcon>
            <ListItemText primary="Ordem de Serviço" />
            {openOS ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        )}
        {/* itens OS */}
        <Collapse in={openOS} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              onClick={() => (window.location.pathname = '/os_protetico')}
            >
              <ListItemIcon>
                <NoteAddIcon />
              </ListItemIcon>
              <ListItemText primary="Protético" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => (window.location.pathname = '/laboratorio')}
            >
              <ListItemIcon>
                <FaFlask size={21} />
              </ListItemIcon>
              <ListItemText primary="Laboratórios" />
            </ListItem>
          </List>
        </Collapse>
        {/* orcamento */}
        {permissoes.orcamento !== '0' && (
          <ListItem button onClick={() => setOpenOrcamento(!openOrcamento)}>
            <ListItemIcon>
              <NoteIcon />
            </ListItemIcon>
            <ListItemText primary="Orçamento" />
            {openOrcamento ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        )}
        {/* itens orcamento */}
        <Collapse in={openOrcamento} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {configuracoes.moduloOdonto !== '0' && (
              <ListItem
                button
                className={classes.nested}
                onClick={() =>
                  (window.location.pathname = '/cad_orcamento_odonto')
                }
              >
                <ListItemIcon>
                  <NoteAddIcon />
                </ListItemIcon>
                <ListItemText primary="Odontológico" />
              </ListItem>
            )}
            {configuracoes.moduloEstetico !== '0' && (
              <ListItem
                button
                className={classes.nested}
                onClick={() =>
                  (window.location.pathname = '/cad_orcamento_estetico')
                }
              >
                <ListItemIcon>
                  <NoteAddIcon />
                </ListItemIcon>
                <ListItemText primary="Estético" />
              </ListItem>
            )}
          </List>
        </Collapse>
        {/* procedimentos */}
        {permissoes.procedimento !== '0' && (
          <ListItem
            button
            onClick={() => (window.location.pathname = '/procedimentos')}
          >
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Procedimentos" />
          </ListItem>
        )}
        {/* financeiro */}
        {permissoes.financeiro !== '0' && (
          <ListItem button onClick={() => setOpenFinanceiro(!openFinanceiro)}>
            <ListItemIcon>
              <MonetizationOnIcon />
            </ListItemIcon>
            <ListItemText primary="Financeiro" />
            {openFinanceiro ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        )}
        {/* itens financeiro */}
        <Collapse in={openFinanceiro} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              onClick={() => (window.location.pathname = '/contaspagar')}
            >
              <ListItemIcon>
                <ArrowUpwardIcon />
              </ListItemIcon>
              <ListItemText primary="Contas a pagar" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => (window.location.pathname = '/contasreceber')}
            >
              <ListItemIcon>
                <ArrowDownwardIcon />
              </ListItemIcon>
              <ListItemText primary="Contas a receber" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={() => (window.location.pathname = '/movimentacao')}
            >
              <ListItemIcon>
                <ImportExportIcon />
              </ListItemIcon>
              <ListItemText primary="Movimentação financeira" />
            </ListItem>
            {/* centro de custo */}
            {permissoes.centroCusto !== '0' && (
              <ListItem
                button
                className={classes.nested}
                onClick={() => (window.location.pathname = '/centrocusto')}
              >
                <ListItemIcon>
                  <EqualizerIcon />
                </ListItemIcon>
                <ListItemText primary="Centro de Custo" />
              </ListItem>
            )}
          </List>
        </Collapse>
        {/* estoque */}
        {permissoes.estoque !== '0' && (
          <ListItem
            button
            onClick={() => (window.location.pathname = '/estoque')}
          >
            <ListItemIcon>
              <AllInboxIcon />
            </ListItemIcon>
            <ListItemText primary="Estoque" />
          </ListItem>
        )}
        {/* convenios */}
        {permissoes.convenio !== '0' && (
          <ListItem
            button
            onClick={() => (window.location.pathname = '/convenios')}
          >
            <ListItemIcon>
              <FeaturedPlayListIcon />
            </ListItemIcon>
            <ListItemText primary="Convênios" />
          </ListItem>
        )}
        {/* relatorios */}
        <ListItem button onClick={() => setOpenRelatorio(!openRelatorio)}>
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary="Relatórios" />
          {openRelatorio ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {/* itens relatorio */}
        <Collapse in={openRelatorio} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* <ListItem button className={classes.nested}>
                    <ListItemIcon><DescriptionIcon /></ListItemIcon>
                    <ListItemText primary="Relação de pacientes" />
                  </ListItem> */}
            <ListItem
              button
              className={classes.nested}
              onClick={() => (window.location.pathname = '/aniversariantesmes')}
            >
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Aniversáriantes" />
            </ListItem>
            {configuracoes.moduloOdonto !== '0' && (
              <ListItem
                button
                className={classes.nested}
                onClick={() =>
                  (window.location.pathname = '/relatorio_prod_odonto')
                }
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary="Produção Odonto" />
              </ListItem>
            )}
            {configuracoes.moduloEstetico !== '0' && (
              <ListItem
                button
                className={classes.nested}
                onClick={() =>
                  (window.location.pathname = '/relatorio_prod_estetico')
                }
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary="Produção Estético" />
              </ListItem>
            )}
            {configuracoes.moduloOdonto !== '0' &&
              configuracoes.moduloEstetico !== '0' && (
                <ListItem
                  button
                  className={classes.nested}
                  onClick={() =>
                    (window.location.pathname = '/relatorio_prod_unificado')
                  }
                >
                  <ListItemIcon>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary="Produção Unificado" />
                </ListItem>
              )}
            <ListItem
              button
              className={classes.nested}
              onClick={() =>
                (window.location.pathname = '/relatorio_fat_sintetico')
              }
            >
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Faturamento Sintético" />
            </ListItem>
            {/* <ListItem button className={classes.nested}>
                    <ListItemIcon><DescriptionIcon /></ListItemIcon>
                    <ListItemText primary="Relação de funcionários" />
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon><DescriptionIcon /></ListItemIcon>
                    <ListItemText primary="Contas a pagar" />
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon><DescriptionIcon /></ListItemIcon>
                    <ListItemText primary="Contas a receber" />
                  </ListItem> */}
          </List>
        </Collapse>
      </List>
      {/* <Divider/> */}
    </div>
  );

  const sistema = () => {
    let os = navigator.platform;
    const link = document.createElement('a');
    if (os === 'Win16' || os === 'Win32' || os === 'Win16') {
      link.download = 'Anydesk_Windonws';
      link.href = 'https://sigecli.com.br/AnyDesk.exe';
      link.click();
    } else if (os === 'Linux i686' || os === 'Linux armv7l' || os === 'HP-UX') {
      link.download = 'Anydesk_Linux';
      link.href = 'https://sigecli.com.br/anydesk_5.5.1-1_amd64.deb';
      link.click();
    } else if (os === 'MacPPC' || os === 'MacIntel' || os === 'Mac68K') {
      link.download = 'Anydesk_Mac';
      link.href = 'https://sigecli.com.br/anydesk.dmg';
      link.click();
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer('left', true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {props.titulo}
          </Typography>
          <div>
            <IconButton
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
              >
                <Avatar src={foto} />
              </StyledBadge>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => (window.location.pathname = '/configuracoes')}
              >
                <SettingsIcon color="action" /> Configurações
              </MenuItem>
              <MenuItem
                onClick={() => (window.location.pathname = '/meusdados')}
              >
                <AccountCircle color="action" /> Meus Dados
              </MenuItem>
              <MenuItem onClick={() => (window.location.pathname = '/empresa')}>
                <BusinessIcon color="action" /> Dados da Empresa
              </MenuItem>
              <MenuItem
                onClick={() => (window.location.pathname = '/feriados')}
              >
                <DateRangeIcon color="action" /> Feriados
              </MenuItem>
              {/* <MenuItem
                onClick={() =>
                  (window.location.pathname = '/configuracao_whatsapp')
                }
              >
                <WhatsAppIcon color="action" /> Config. WhatsApp
              </MenuItem> */}
              <MenuItem onClick={sistema}>
                <BuildIcon color="action" /> Suporte Remoto
              </MenuItem>
              <Divider />
              <MenuItem onClick={sair}>
                <ExitToAppIcon color="action" /> Sair
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
        <Drawer
          anchor={'left'}
          open={state['left']}
          onClose={toggleDrawer('left', false)}
        >
          {opcoesMenu('left')}
        </Drawer>
      </AppBar>
    </div>
  );
}
