import React, { useState, useEffect } from 'react';
import Menu from '../../Menu';
import api from '../../../services/api';
import { getClinica, getToken } from '../../../services/auth';
import {
  formatData,
  formatReal,
  listaFormasPagamento,
} from '../../../utils/funcoes';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { usePermissoes } from '../../../hooks/permissoes';
import Zoom from '@material-ui/core/Zoom';
import Tooltip from '@material-ui/core/Tooltip';
import Notificacao from '../../../utils/notificacao';
import { useConfig } from '../../../hooks/config';
import ReceiptIcon from '@material-ui/icons/Receipt';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 'auto',
  },
  tableCell: {
    fontSize: '12px',
    padding: '4px 4px',
  },
  saldo: {
    margin: '10px 0px',
  },
  button: {
    marginTop: '65px',
    margin: theme.spacing(1),
  },
  opcoes: {
    marginBottom: '0px',
    marginTop: '60px',
    width: '100%',
  },
  buttonOpcoes: {
    marginRight: '8px',
  },
  textPesquisa: {
    margin: theme.spacing(1),
  },
  textFiltro: {
    margin: theme.spacing(1),
    width: '170px',
  },
  buttonFiltro: {
    margin: theme.spacing(1),
  },
  buttonPagar: {
    backgroundColor: '#4caf50',
    '&:hover': {
      backgroundColor: '#3b8e3e',
    },
    color: '#FFFF',
    margin: theme.spacing(1),
  },
  buttonCancel: {
    margin: theme.spacing(1),
  },
  amarelo: {
    backgroundColor: '#FFC107',
  },
  verde: {
    backgroundColor: '#4caf50',
  },
  vermelho: {
    backgroundColor: '#FF0000',
  },
  contaVencida: {
    backgroundColor: '#f8beb7',
  },
}));

export default function ContasPagar() {
  const [loading, setLoading] = useState(true);
  const { configuracoes } = useConfig();
  const { permissoes } = usePermissoes();
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const [desabilitar, setDesabilitar] = useState(false);
  const [buscar, setBuscar] = useState('');
  const [contas, setContas] = useState([]);
  const [centrosCusto, setCentrosCusto] = useState([]);
  const [centroCusto, setCentroCusto] = useState(null);
  const [descricao, setDescricao] = useState(null);
  const [valor, setValor] = useState(null);
  const [emissao, setEmissao] = useState(null);
  const [vencimento, setVencimento] = useState(null);
  const [obs, setObs] = useState('');
  const [idConta, setIdConta] = useState('');
  const [pagoDia, setPagoDia] = useState(null);
  const [formaPag, setFormaPag] = useState(null);
  const [qtdVezes, setQtdVezes] = useState(1);
  const [repetirLancamento, setRapatirLancamento] = useState('False');
  const [statusConta, setStatusConta] = useState('');
  const [status, setStatus] = useState('');
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [filtro, setFiltro] = useState('');
  const [filtroCentro, setFiltroCentro] = useState('');
  const [totalPagar, setTotalPagar] = useState(0);
  const [totalPago, setTotalPago] = useState(0);
  const [modalCancelar, setModalCancelar] = useState(false);
  const [modalCadastrarEditar, setModalCadastrarEditar] = useState(false);
  const [modalPagar, setModalPagar] = useState(false);
  const [desativarCampos, setDesativarCampos] = useState(false);

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const limparCampos = () => {
    setDescricao(null);
    setValor(null);
    setEmissao(null);
    setVencimento(null);
    setCentroCusto(null);
    setObs('');
    setIdConta('');
    setPagoDia(null);
    setFormaPag(null);
    setStatusConta('');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fecharAviso = () => {
    setAviso(false);
  };

  const atualizar = async () => {
    listarContas('AND status=0 ORDER BY vencimento');
    await api
      .post('CentroCusto/search.php', {
        pdo: getClinica(),
        consulta: 'AND ativo=1',
      })
      .then(res => {
        res.data ? setCentrosCusto(res.data) : setCentrosCusto([]);
      })
      .catch(error => console.log(error));
    setLoading(false);
  };

  const listarContas = async consulta => {
    setContas([]);
    setPage(0);
    await api
      .post('Pagar/search.php', { pdo: getClinica(), consulta: consulta })
      .then(res => {
        if (res.data.length > 0) {
          setContas(res.data);
        }
      })
      .catch(error => console.log(error));
  };

  const totalAPagar = contas => {
    let totalPago = contas
      .filter(c => c.status === '1')
      .reduce((sum, li) => sum + Number(li.valor), 0);
    let totalPagar = contas
      .filter(c => c.status === '0')
      .reduce((sum, li) => sum + Number(li.valor), 0);

    setTotalPagar(totalPagar);
    setTotalPago(totalPago);
  };

  const validacao = () => {
    if (descricao && valor && emissao && vencimento) {
      return true;
    }
    return false;
  };

  const fecharModalEdicarCadastrar = () => {
    limparCampos();
    setModalCadastrarEditar(false);
    setDesativarCampos(false);
  };

  const abrirConta = conta => {
    if (conta.status !== '0') {
      setDesativarCampos(true);
    } else {
      setDesativarCampos(false);
    }
    limparCampos();
    setIdConta(conta.id);
    setDescricao(conta.descricao);
    setCentroCusto(conta.centroCusto);
    setValor(conta.valor);
    setEmissao(conta.emissao);
    setVencimento(conta.vencimento);
    setPagoDia(conta.pagoDia);
    setObs(conta.observacao);
    setStatusConta(conta.status);
    setModalCadastrarEditar(true);
  };

  const cadastrarConta = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.finCad !== '0' && permissoes !== false) {
        if (validacao()) {
          if (qtdVezes > 0) {
            await api
              .post('Pagar/save.php', {
                pdo: getClinica(),
                descricao: descricao,
                centroCusto: centroCusto,
                valor: valor,
                emissao: emissao,
                vencimento: vencimento,
                status: 0,
                observacao: obs,
                userCadastro: getToken(),
                qtdVezes: qtdVezes,
                repetirLancamento: repetirLancamento,
              })
              .then(res => {
                setContas([...contas, res.data]);
                abrirAviso('success', 'Conta cadastrada');
                fecharModalEdicarCadastrar();
              })
              .catch(error => {
                console.log(error);
              });
          } else {
            abrirAviso(
              'warning',
              'Quantidade de vezes deverá ser maior que zero.',
            );
          }
        } else {
          abrirAviso('warning', 'Preencha os campos obrigatórios.');
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
    setDesabilitar(false);
  };

  const alterarConta = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.finAlt !== '0' && permissoes !== false) {
        if (validacao()) {
          await api
            .post('Pagar/update.php', {
              pdo: getClinica(),
              descricao: descricao,
              centroCusto: centroCusto,
              valor: valor,
              emissao: emissao,
              vencimento: vencimento,
              status: 0,
              observacao: obs,
              userUltAlteracao: getToken(),
              id: idConta,
            })
            .then(res => {
              setContas(state =>
                state.map(e => {
                  if (e.id !== res.data.id) {
                    return e;
                  }
                  e = res.data;
                  return e;
                }),
              );
              abrirAviso('info', 'Conta alterada');
              fecharModalEdicarCadastrar();
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          abrirAviso('warning', 'Preencha os campos obrigatórios.');
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
    setDesabilitar(false);
  };

  const salvar = () => {
    if (idConta !== '') {
      alterarConta();
    } else {
      cadastrarConta();
    }
  };

  const pagarConta = async e => {
    setDesabilitar(true);
    e.preventDefault();
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.finAlt !== '0' && permissoes !== false) {
        if (pagoDia && formaPag) {
          await api
            .post('Pagar/pay.php', {
              pdo: getClinica(),
              status: 1,
              dataPagamento: pagoDia,
              idUserBaixa: getToken(),
              userUltAlteracao: getToken(),
              formaPag: formaPag,
              id: idConta,
            })
            .then(res => {
              setContas(state =>
                state.map(e => {
                  if (e.id !== res.data.id) {
                    return e;
                  }
                  e = res.data;
                  return e;
                }),
              );
              abrirAviso('success', 'Conta paga');
              setDesabilitar(false);
              limparCampos();
              setModalPagar(false);
              setModalCadastrarEditar(false);
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          abrirAviso('warning', 'Preencha os campos obrigatórios.');
          setDesabilitar(false);
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
        setDesabilitar(false);
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
      setDesabilitar(false);
    }
  };

  const desativarAtivarConta = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (
        permissoes.finDes !== '0' &&
        permissoes.finAlt !== '0' &&
        permissoes !== false
      ) {
        let acao = 0;
        if (statusConta === '0') {
          acao = -1;
        }
        await api
          .post('Pagar/chargeBack.php', {
            pdo: getClinica(),
            status: acao,
            userUltAlteracao: getToken(),
            id: idConta,
          })
          .then(res => {
            abrirAviso('info', 'Conta alterada.');
            setContas(state =>
              state.map(e => {
                if (e.id !== res.data.id) {
                  return e;
                }
                e = res.data;
                return e;
              }),
            );
            setModalCancelar(false);
            setModalCadastrarEditar(false);
            setDesabilitar(false);
            limparCampos();
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
        setDesabilitar(false);
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
      setDesabilitar(false);
    }
  };

  const filtrar = () => {
    setLoading(true);
    let filtroBusca = '';
    if (status !== '' && status !== ' ') {
      filtroBusca += ` AND ${status}`;
    }

    if (filtroCentro !== '') {
      filtroBusca += ` AND centroCusto=${filtroCentro}`;
    }

    if (filtro !== '') {
      filtroBusca += ` AND ${filtro}`;
    } else if (filtro === '') {
      filtroBusca += ' AND emissao';
    }

    if (dataInicio !== '' && dataFim !== '') {
      filtroBusca += ` BETWEEN '${dataInicio}' AND '${dataFim}'`;
    } else {
      filtroBusca +=
        filtro !== '' ? ` ORDER BY ${filtro} ASC` : ` ORDER BY vencimento ASC`;
    }
    listarContas(filtroBusca);
    setLoading(false);
  };

  const ehContaVencida = (situacao, e) => {
    let vencimento = e.split('-');
    let data = new Date(vencimento[0], vencimento[1] - 1, vencimento[2]);
    let hoje = new Date();
    if (data < hoje && situacao === '0') {
      return classes.contaVencida;
    }
  };

  const situacaoConta = valor => {
    if (valor === '0') {
      return (
        <Tooltip title="CONTA PENDENTE" TransitionComponent={Zoom}>
          <Avatar className={classes.amarelo}>PE</Avatar>
        </Tooltip>
      );
    }
    if (valor === '1') {
      return (
        <Tooltip title="CONTA PAGA" TransitionComponent={Zoom}>
          <Avatar className={classes.verde}>PG</Avatar>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="CONTA CANCELADA" TransitionComponent={Zoom}>
          <Avatar className={classes.vermelho}>CA</Avatar>
        </Tooltip>
      );
    }
  };

  useEffect(() => {
    atualizar();
  }, []);

  useEffect(() => {
    totalAPagar([...contas]);
  }, [contas]);

  const filtradoPorDescricao = contas.filter(conta => {
    return (
      conta.descricao.toLowerCase().indexOf(buscar.toLowerCase()) !== -1 ||
      conta.id.toLowerCase().indexOf(buscar.toLowerCase()) !== -1
    );
  });

  return (
    <div>
      <Menu titulo="Financeiro ► Contas a Pagar" />
      <div className={classes.opcoes}>
        <Box display="flex" p={1} bgcolor="background.paper">
          <Box p={2} width="100%">
            <b>Total a pagar:{formatReal(totalPagar)}</b>
          </Box>
          <Box p={2} width="100%">
            <b>Total pago:{formatReal(totalPago)}</b>
          </Box>
          <Box p={1} flexShrink={0}>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonOpcoes}
              onClick={() => setModalCadastrarEditar(true)}
              startIcon={<AddCircleIcon />}
            >
              Novo
            </Button>
            <Button
              variant="contained"
              color="default"
              onClick={() => window.location.reload()}
              startIcon={<RefreshIcon />}
            >
              Atualizar
            </Button>
          </Box>
        </Box>
      </div>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <Paper>
          <TextField
            className={classes.textPesquisa}
            value={buscar}
            onChange={e => setBuscar(e.target.value)}
            size="small"
            label="Pesquisar"
            variant="outlined"
          />
          <TextField
            select
            className={classes.textFiltro}
            size="small"
            label="Status"
            value={status}
            onChange={e => setStatus(e.target.value)}
            variant="outlined"
          >
            <MenuItem value=" ">Todas</MenuItem>
            <MenuItem value="status=0">Aberto</MenuItem>
            <MenuItem value="status=1">Pago</MenuItem>
            <MenuItem value="status=-1">Cancelado</MenuItem>
          </TextField>
          <TextField
            select
            className={classes.textFiltro}
            size="small"
            label="Centro de Custo"
            value={filtroCentro}
            onChange={e => setFiltroCentro(e.target.value)}
            variant="outlined"
          >
            <MenuItem value="" selected>
              Selecione...
            </MenuItem>
            {centrosCusto.map(c => (
              <MenuItem key={c.id} value={c.id}>
                {c.descricao}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            className={classes.textFiltro}
            size="small"
            label="Filtro por"
            value={filtro}
            onChange={e => setFiltro(e.target.value)}
            variant="outlined"
          >
            <MenuItem value="">Selecione...</MenuItem>
            <MenuItem value="emissao">Emissão</MenuItem>
            <MenuItem value="vencimento">Vencimento</MenuItem>
            <MenuItem value="pagoDia">Pagamento</MenuItem>
          </TextField>
          <TextField
            type="date"
            size="small"
            className={classes.textFiltro}
            label="Periodo Inicial"
            value={dataInicio}
            onChange={e => setDataInicio(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            type="date"
            size="small"
            className={classes.textFiltro}
            label="Periodo Final"
            value={dataFim}
            onChange={e => setDataFim(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <Button
            className={classes.buttonFiltro}
            onClick={filtrar}
            variant="contained"
            color="secondary"
          >
            <SearchIcon />
          </Button>
          <TableContainer className={classes.container}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Descrição</b>
                  </TableCell>
                  <TableCell>
                    <b>Emissao</b>
                  </TableCell>
                  <TableCell>
                    <b>Vencimento</b>
                  </TableCell>
                  <TableCell>
                    <b>Data Pagamento</b>
                  </TableCell>
                  <TableCell>
                    <b>Valor</b>
                  </TableCell>
                  <TableCell>
                    <b>Status</b>
                  </TableCell>
                  <TableCell>
                    <b>Opção</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filtradoPorDescricao
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(conta => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={conta.id}
                        className={ehContaVencida(
                          conta.status,
                          conta.vencimento,
                        )}
                      >
                        <TableCell>{conta.id}</TableCell>
                        <TableCell>{conta.descricao}</TableCell>
                        <TableCell>{formatData(conta.emissao)}</TableCell>
                        <TableCell>{formatData(conta.vencimento)}</TableCell>
                        <TableCell>{formatData(conta.pagoDia)}</TableCell>
                        <TableCell>{formatReal(conta.valor)}</TableCell>
                        <TableCell>{situacaoConta(conta.status)}</TableCell>
                        <TableCell>
                          <Tooltip
                            title="ABRIR CONTA"
                            TransitionComponent={Zoom}
                          >
                            <Button
                              onClick={() => abrirConta(conta)}
                              variant="contained"
                              color="primary"
                            >
                              <OpenInNewIcon />
                            </Button>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={contas.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por pagina"
          />
        </Paper>
      )}

      {/* cadatrar/editar conta */}
      <Dialog
        fullScreen={fullScreen}
        open={modalCadastrarEditar}
        onClose={fecharModalEdicarCadastrar}
        aria-labelledby="responsive-dialog-title"
      >
        {idConta !== '' && (
          <DialogTitle id="responsive-dialog-title">{'Conta'}</DialogTitle>
        )}
        {idConta === '' && (
          <DialogTitle id="responsive-dialog-title">
            {'Cadastrar Conta'}
          </DialogTitle>
        )}
        <DialogContent>
          {idConta !== '' && (
            <DialogContentText id="alert-dialog-slide-description">
              Conta Nº {idConta}
            </DialogContentText>
          )}
          <form noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={desativarCampos}
                  label="Descrição"
                  value={descricao}
                  onChange={e => setDescricao(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  disabled={desativarCampos}
                  label="Centro de Custo"
                  value={centroCusto}
                  onChange={e => setCentroCusto(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="" selected>
                    Selecione...
                  </MenuItem>
                  {centrosCusto.map(c => (
                    <MenuItem key={c.id} value={c.id}>
                      {c.descricao}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled={desativarCampos}
                  type="date"
                  label="Emissão"
                  value={emissao}
                  onChange={e => setEmissao(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled={desativarCampos}
                  type="date"
                  label="Vencimento"
                  value={vencimento}
                  onChange={e => setVencimento(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={idConta === '' ? 4 : 12}>
                <TextField
                  fullWidth
                  disabled={desativarCampos}
                  type="number"
                  label="Valor"
                  value={valor}
                  onChange={e => setValor(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              {idConta === '' && (
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    disabled={desativarCampos}
                    type="number"
                    label="Qtd. Vezes"
                    value={qtdVezes}
                    onChange={e => setQtdVezes(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
              )}
              {idConta === '' && (
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    disabled={desativarCampos}
                    select
                    label="Repetir Lançamento"
                    value={repetirLancamento}
                    onChange={e => setRapatirLancamento(e.target.value)}
                    variant="outlined"
                  >
                    <MenuItem value="False" selected>
                      Não
                    </MenuItem>
                    <MenuItem value="True">Sim</MenuItem>
                  </TextField>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={desativarCampos}
                  label="Observação"
                  value={obs}
                  onChange={e => setObs(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              {idConta !== '' && statusConta === '1' && (
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    disabled={desativarCampos}
                    type="date"
                    label="Data Pagamento"
                    value={pagoDia}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <div style={{ width: '100%' }}>
            <Box
              display="flex"
              justifyContent="flex-start"
              m={1}
              p={1}
              bgcolor="background.paper"
            >
              <Box>
                {statusConta === '0' && idConta !== '' && (
                  <Button
                    onClick={setModalCancelar}
                    className={classes.buttonCancel}
                    variant="contained"
                    title="Cancelar conta"
                    color="secondary"
                  >
                    <DeleteIcon />
                  </Button>
                )}
                {statusConta === '1' && idConta !== '' && (
                  <Button
                    onClick={setModalCancelar}
                    variant="contained"
                    title="Estornar conta"
                    color="default"
                  >
                    <ArrowUpwardIcon />
                    Estornar
                  </Button>
                )}
              </Box>
              {statusConta === '0' && idConta !== '' && (
                <Box>
                  <Button
                    onClick={() => setModalPagar(true)}
                    className={classes.buttonPagar}
                    variant="contained"
                  >
                    <AttachMoneyIcon /> Pagar
                  </Button>
                </Box>
              )}
            </Box>
          </div>
          {statusConta !== '-1' && statusConta !== '1' && (
            <Button
              onClick={salvar}
              variant="contained"
              color="primary"
              disabled={desabilitar}
            >
              Salvar
            </Button>
          )}
          <Button
            variant="contained"
            color="default"
            onClick={fecharModalEdicarCadastrar}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      {/* confirmação de cancelamento/estorno de conta */}
      <Dialog
        fullScreen={fullScreen}
        open={modalCancelar}
        onClose={() => setModalCancelar(false)}
        aria-labelledby="responsive-dialog-title"
      >
        {statusConta === '1' && (
          <DialogTitle id="responsive-dialog-title">
            {'Estornar conta'}
          </DialogTitle>
        )}
        {statusConta === '0' && (
          <DialogTitle id="responsive-dialog-title">
            {'Desativar conta'}
          </DialogTitle>
        )}
        <DialogContent>
          {statusConta === '1' && (
            <DialogContentText id="alert-dialog-slide-description">
              Tem certeza que deseja estornar conta Nº {idConta} ?
            </DialogContentText>
          )}
          {statusConta === '0' && (
            <DialogContentText id="alert-dialog-slide-description">
              Tem certeza que deseja desativar conta Nº {idConta} ?
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={desativarAtivarConta}
            disabled={desabilitar}
            variant="contained"
            color="primary"
          >
            Sim
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setModalCancelar(false)}
          >
            Não
          </Button>
        </DialogActions>
      </Dialog>

      {/* pagamento de conta */}
      <Dialog
        fullScreen={fullScreen}
        open={modalPagar}
        onClose={() => setModalPagar(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{'Pagar conta'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Tem certeza que deseja pagar conta Nº {idConta} ?
          </DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="date"
                label="Data do pagamento"
                value={pagoDia}
                onChange={e => setPagoDia(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="Forma de pagamento"
                value={formaPag}
                onChange={e => setFormaPag(e.target.value)}
                variant="outlined"
              >
                <MenuItem value="" selected>
                  Selecione...
                </MenuItem>
                {listaFormasPagamento.map(pg => (
                  <MenuItem value={pg.value}>{pg.label}</MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={pagarConta}
            disabled={desabilitar}
            variant="contained"
            color="primary"
          >
            Sim
          </Button>
          <Button
            variant="contained"
            disabled={desabilitar}
            color="secondary"
            onClick={() => setModalPagar(false)}
          >
            Não
          </Button>
        </DialogActions>
      </Dialog>
      <Notificacao
        aviso={aviso}
        fecharAviso={fecharAviso}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </div>
  );
}
