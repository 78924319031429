import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import { getClinica, getToken } from '../../../services/auth';
import { formatReal, listaFormasPagamento } from '../../../utils/funcoes';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import CancelIcon from '@material-ui/icons/Cancel';
import { usePermissoes } from '../../../hooks/permissoes';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  total: {
    fontSize: '20px',
    padding: 0,
  },
}));

export default function AceiteOrcamento({
  modal,
  setModal,
  tratamento,
  setOrcamentos,
  abrirAviso,
  configuracoes,
}) {
  const { permissoes } = usePermissoes();
  const [loading, setLoading] = useState(true);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [desabilitar, setDesabilitar] = useState(false);
  const [planoTratamento, setPlanoTratamento] = useState([]);
  const [tipoPag, setTipoPag] = useState('Avista');
  const [total, setTotal] = useState(0);
  const [desconto, setDesconto] = useState(0);
  const [formaPagAvista, setFormaPagAvista] = useState('');
  const [entrada, setEntrada] = useState(0);
  const [parcelas, setParcelas] = useState(1);
  const [formaPagEntrada, setFormaPagEntrada] = useState('');
  const [formaPagParcelas, setFormaPagParcelas] = useState('');
  const [centrosCusto, setCentrosCusto] = useState([]);
  const [centroCusto, setCentroCusto] = useState(null);

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));

  useEffect(() => {
    listarProcedimentos();
  }, [modal]);

  const listarProcedimentos = async () => {
    await api
      .post(
        'PlanoTratamento/list.php?id=' + tratamento.id + '&pdo=' + getClinica(),
      )
      .then(res => {
        setPlanoTratamento(res.data);
        let total = res.data.reduce(
          (acc, proc) => acc + parseFloat(proc.valor),
          0,
        );
        let desconto = res.data.reduce(
          (desc, proc) => desc + parseFloat(proc.desconto),
          0,
        );
        setTotal(total);
        setDesconto(desconto);
      });
    await api
      .post('CentroCusto/search.php', {
        pdo: getClinica(),
        consulta: 'AND ativo=1',
      })
      .then(res => {
        res.data ? setCentrosCusto(res.data) : setCentrosCusto([]);
      })
      .catch(error => console.log(error));
    setLoading(false);
  };

  const recusar = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.orcCad !== '0' && permissoes !== false) {
        if (tratamento.status === '0') {
          await api
            .post('Tratamento/updateStatus.php', {
              pdo: getClinica(),
              status: -1,
              id: tratamento.id,
            })
            .then(res => {
              setOrcamentos(state =>
                state.map(e => {
                  if (e.id !== res.data.id) {
                    return e;
                  }
                  e = res.data;
                  return e;
                }),
              );
              abrirAviso('warning', 'Orçamento recusado.');
              setModal(false);
            });
        } else {
          abrirAviso('warning', 'Orçamento já foi recusado.');
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
    setDesabilitar(false);
  };

  const aceitar = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.orcCad !== '0' && permissoes !== false) {
        let validacao = false;
        if (tipoPag === 'Avista' && formaPagAvista !== '') {
          validacao = true;
        }
        if (tipoPag === 'Aprazo' && formaPagParcelas !== '') {
          validacao = true;
        }
        if (validacao) {
          await api
            .post('Tratamento/confirm.php', {
              pdo: getClinica(),
              idPaciente: tratamento.idCliente,
              entrada: parseFloat(entrada),
              valor: total - desconto,
              centroCusto: centroCusto,
              formaPagAvista: formaPagAvista,
              formaPagEntrada: formaPagEntrada,
              formaPagParcelas: formaPagParcelas,
              parcelas: parcelas,
              idTratamento: tratamento.id,
              userCadastro: getToken(),
              tipoPagamento: tipoPag,
            })
            .then(() => {
              setOrcamentos(orcamentos =>
                orcamentos.filter(e => e.id !== tratamento.id),
              );
              abrirAviso('success', 'Orçamento confirmado.');
              setModal(false);
              setModalConfirm(false);
            });
        } else {
          abrirAviso('error', 'Informe a forma de pagamento.');
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
    setDesabilitar(false);
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={modal}
        onClose={() => setModal(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{`Orçamento Nº ${tratamento.id}`}</DialogTitle>
        <DialogContent>
          {tratamento.refTratamento && (
            <DialogContentText id="alert-dialog-slide-description">
              <b>{`Reutilizado do orçamento Nº ${tratamento.refTratamento}`}</b>
            </DialogContentText>
          )}
          {loading && (
            <CircularProgress
              style={{
                marginTop: '250px',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            />
          )}
          {!loading && (
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Dente</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Face</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Estado Atual</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Procedimento</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Valor</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Desconto</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Valor Final</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {planoTratamento.map(pl => (
                    <TableRow key={pl.id}>
                      <TableCell component="th" scope="row">
                        {pl.numDente}
                      </TableCell>
                      <TableCell align="center">{pl.face}</TableCell>
                      <TableCell align="center">{pl.estadoAtual}</TableCell>
                      <TableCell align="center">
                        {pl.descricaoProcedimento}
                      </TableCell>
                      <TableCell align="center">
                        {formatReal(pl.valor)}
                      </TableCell>
                      <TableCell align="center">
                        {formatReal(pl.desconto)}
                      </TableCell>
                      <TableCell align="center">
                        {formatReal(pl.valor - pl.desconto)}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={6}>
                      <b className={classes.total}>SubTotal</b>
                    </TableCell>
                    <TableCell>
                      <b className={classes.total}>{formatReal(total)}</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <b className={classes.total}>Desconto</b>
                    </TableCell>
                    <TableCell>
                      <b className={classes.total}>{formatReal(desconto)}</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <b className={classes.total}>Total</b>
                    </TableCell>
                    <TableCell>
                      <b className={classes.total}>
                        {formatReal(total - desconto)}
                      </b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={desabilitar}
            color="secondary"
            onClick={recusar}
            startIcon={<ThumbDownIcon />}
          >
            Recusar
          </Button>
          <Button
            variant="contained"
            disabled={desabilitar}
            color="primary"
            onClick={() => setModalConfirm(true)}
            startIcon={<ThumbUpAltIcon />}
          >
            Confirmar
          </Button>
          <Button
            variant="contained"
            color="default"
            startIcon={<CancelIcon />}
            onClick={() => setModal(false)}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={modalConfirm}
        onClose={() => setModalConfirm(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{`Confirmar Orçamento`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Valor do Orçamento: <b>{formatReal(total - desconto)}</b>
          </DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                value={tipoPag}
                onChange={e => setTipoPag(e.target.value)}
              >
                <FormControlLabel
                  value="Avista"
                  control={<Radio color="primary" />}
                  label="À vista"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="Aprazo"
                  control={<Radio color="primary" />}
                  label="À prazo"
                  labelPlacement="end"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="Centro de Custo"
                value={centroCusto}
                onChange={e => setCentroCusto(e.target.value)}
                variant="outlined"
              >
                <MenuItem value="" selected>
                  Selecione...
                </MenuItem>
                {centrosCusto.map(c => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.descricao}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {tipoPag === 'Avista' && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  label="Forma de Pagamento"
                  value={formaPagAvista}
                  onChange={e => setFormaPagAvista(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="" selected>
                    Selecione...
                  </MenuItem>
                  {listaFormasPagamento.map(pg => (
                    <MenuItem value={pg.value}>{pg.label}</MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}

            {tipoPag === 'Aprazo' && (
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <h3>ENTRADA:</h3>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    type="number"
                    fullWidth
                    label="Entrada"
                    value={entrada}
                    onChange={e => {
                      e.target.value >= 0
                        ? setEntrada(e.target.value)
                        : setEntrada(0);
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    select
                    label="Forma de Pagamento"
                    value={formaPagEntrada}
                    onChange={e => setFormaPagEntrada(e.target.value)}
                    variant="outlined"
                  >
                    <MenuItem value="" selected>
                      Selecione...
                    </MenuItem>
                    {listaFormasPagamento.map(pg => (
                      <MenuItem value={pg.value}>{pg.label}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={2}>
                  <h3>PARCELAS:</h3>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    type="number"
                    fullWidth
                    label="Quantidade de Parcelas"
                    value={parcelas}
                    onChange={e => {
                      e.target.value >= 1
                        ? setParcelas(e.target.value)
                        : setParcelas(1);
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    select
                    label="Forma de Pagamento"
                    value={formaPagParcelas}
                    onChange={e => setFormaPagParcelas(e.target.value)}
                    variant="outlined"
                  >
                    <MenuItem value="" selected>
                      Selecione...
                    </MenuItem>
                    {listaFormasPagamento.map(pg => (
                      <MenuItem value={pg.value}>{pg.label}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={desabilitar}
            color="primary"
            onClick={aceitar}
            startIcon={<ThumbUpAltIcon />}
          >
            Confirmar
          </Button>
          <Button
            variant="contained"
            color="default"
            startIcon={<CancelIcon />}
            onClick={() => setModalConfirm(false)}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
