import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import api from '../../services/api';
import { getClinica, getToken } from '../../services/auth';

export default function GerarFinanceiroOS({
  modal,
  fecharModal,
  ordemServico,
  centrosCusto,
  setOrdensServico,
  abrirAviso,
}) {
  const [desabilitar, setDesabilitar] = useState(false);
  const [descricao, setDescricao] = useState(
    `O.S. PROTÉTICO Nº ${ordemServico.id} - ${ordemServico.paciente}`,
  );
  const [centroCusto, setCentroCusto] = useState(null);
  const [vencimento, setVencimento] = useState(null);
  const [qtdVezes, setQtdVezes] = useState(1);
  const [obs, setObs] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const gerar = async () => {
    setDesabilitar(true);
    if (descricao && vencimento) {
      await api
        .post('OrdemServico/generateFinancial.php', {
          pdo: getClinica(),
          descricao: descricao,
          centroCusto: centroCusto,
          valor: ordemServico.valor,
          vencimento: vencimento,
          observacao: obs,
          userCadastro: getToken(),
          qtdVezes: qtdVezes,
          idOrdemServico: ordemServico.id,
        })
        .then(res => {
          setOrdensServico(state =>
            state.map(e => {
              if (e.id !== res.data.id) {
                return e;
              }
              e = res.data;
              return e;
            }),
          );
          ordemServico.gerarFinanceiro = 1;
          abrirAviso('success', 'Conta cadastrada com sucesso.');
          fecharModal();
        })
        .catch(error => {
          console.log(error);
          abrirAviso('error', 'Erro ao cadastrar conta.');
        });
    } else {
      abrirAviso('warning', 'Preencha os campos.');
    }
    setDesabilitar(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={modal}
      onClose={fecharModal}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {'Gerar Financeiro?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <form noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Descrição"
                  value={descricao}
                  onChange={e => setDescricao(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  label="Centro de Custo"
                  value={centroCusto}
                  onChange={e => setCentroCusto(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="" selected>
                    Selecione...
                  </MenuItem>
                  {centrosCusto.map(c => (
                    <MenuItem key={c.id} value={c.id}>
                      {c.descricao}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="date"
                  label="Vencimento"
                  value={vencimento}
                  onChange={e => setVencimento(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Qtd. Vezes"
                  value={qtdVezes}
                  onChange={e => setQtdVezes(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Observação"
                  value={obs}
                  onChange={e => setObs(e.target.value)}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </form>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={gerar}
          color="primary"
          variant="contained"
          disabled={desabilitar}
        >
          Gerar
        </Button>
        <Button onClick={fecharModal} color="default" variant="contained">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
