import React, { useEffect, useState } from 'react';
import Menu from '../Menu';
import api from '../../services/api';
import { getClinica } from '../../services/auth';
import { formatData, formatReal } from '../../utils/funcoes';
import {
  Paper,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Button,
  TableRow,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import PrintIcon from '@material-ui/icons/Print';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  textPesquisa: {
    marginBottom: '10px',
  },
  textFiltro: {
    margin: theme.spacing(1),
    width: '170px',
  },
  button: {
    margin: theme.spacing(1),
    marginTop: '80px',
  },
  buttonFiltro: {
    margin: theme.spacing(1),
  },
  totalizador: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
}));

export default function RelatorioFaturamentoSintetico() {
  const [loading, setLoading] = useState(true);
  const [registros, setRegistros] = useState({});
  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [totalBruto, setTotalBruto] = useState(0);
  const [totalLiquido, setTotalLiquido] = useState(0);
  const classes = useStyles();

  const imprimir = () => {
    const reg = {
      registros: JSON.stringify(registros),
      dataI: dataInicio,
      dataF: dataFim,
      totalBruto: totalBruto,
      totalLiquido: totalLiquido,
    };
    sessionStorage.setItem('relFatSintetico', btoa(JSON.stringify(reg)));
    window.open(
      '/imprimir_fat_sintetico',
      'Faturamento',
      'height=700,width=1100',
    );
    sessionStorage.removeItem('relFatSintetico');
  };

  const atualizar = async () => {
    let date = new Date();
    let primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
    let ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let dateI =
      date.getFullYear() +
      '-' +
      ('00' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('00' + primeiroDia.getDate()).slice(-2);
    let dateF =
      date.getFullYear() +
      '-' +
      ('00' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('00' + ultimoDia.getDate()).slice(-2);
    setDataInicio(dateI);
    setDataFim(dateF);
    await consultar(dateI, dateF);
    setLoading(false);
  };

  const filtrar = async () => {
    setLoading(true);
    await consultar(dataInicio, dataFim);
    setLoading(false);
  };

  const consultar = async (inicio, fim) => {
    await api
      .post('Relatorios/faturamentoSintetico.php', {
        pdo: getClinica(),
        dataInicio: inicio,
        dataFim: fim,
      })
      .then(res => {
        setRegistros(res.data);
        let totalBrut =
          parseFloat(res.data.boletoBruto) +
          parseFloat(res.data.cartaoCreditoBruto) +
          parseFloat(res.data.cartaoDebitoBruto) +
          parseFloat(res.data.chequeBruto) +
          parseFloat(res.data.convenio) +
          parseFloat(res.data.dinheiro) +
          parseFloat(res.data.pix) +
          parseFloat(res.data.transferencia);
        setTotalBruto(totalBrut);
        let totalLiquid =
          parseFloat(res.data.boletoLiquido) +
          parseFloat(res.data.cartaoCreditoLiquido) +
          parseFloat(res.data.cartaoDebitoLiquido) +
          parseFloat(res.data.chequeLiquido) +
          parseFloat(res.data.convenio) +
          parseFloat(res.data.dinheiro) +
          parseFloat(res.data.pix) +
          parseFloat(res.data.transferencia);
        setTotalLiquido(totalLiquid);
      });
  };

  useEffect(() => {
    atualizar();
  }, []);

  return (
    <>
      <Menu titulo="Relatório de Faturamento Sintético" />
      <div>
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={() => window.location.reload()}
          startIcon={<RefreshIcon />}
        >
          Atualizar
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={imprimir}
          startIcon={<PrintIcon />}
        >
          Imprimir
        </Button>
      </div>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <Paper className={classes.root}>
          <TextField
            type="date"
            size="small"
            className={classes.textFiltro}
            label="Periodo Inicial"
            value={dataInicio}
            onChange={e => setDataInicio(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            type="date"
            size="small"
            className={classes.textFiltro}
            label="Periodo Final"
            value={dataFim}
            onChange={e => setDataFim(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <Button
            className={classes.buttonFiltro}
            onClick={filtrar}
            variant="contained"
            color="secondary"
          >
            <SearchIcon />
          </Button>

          <TableContainer>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>RECEBIMENTOS</b>
                  </TableCell>
                  <TableCell>
                    <b>VALOR BRUTO</b>
                  </TableCell>
                  <TableCell>
                    <b>VALOR LÍQUIDO</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>BOLETO</TableCell>
                  <TableCell>{formatReal(registros.boletoBruto)}</TableCell>
                  <TableCell>{formatReal(registros.boletoLiquido)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>CARTÃO CRÉDITO</TableCell>
                  <TableCell>
                    {formatReal(registros.cartaoCreditoBruto)}
                  </TableCell>
                  <TableCell>
                    {formatReal(registros.cartaoCreditoLiquido)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>CARTÃO DÉBITO</TableCell>
                  <TableCell>
                    {formatReal(registros.cartaoDebitoBruto)}
                  </TableCell>
                  <TableCell>
                    {formatReal(registros.cartaoDebitoLiquido)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>CHEQUE</TableCell>
                  <TableCell>{formatReal(registros.chequeBruto)}</TableCell>
                  <TableCell>{formatReal(registros.chequeLiquido)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>CONVÊNIO</TableCell>
                  <TableCell>{formatReal(registros.convenio)}</TableCell>
                  <TableCell>{formatReal(registros.convenio)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>DINHEIRO</TableCell>
                  <TableCell>{formatReal(registros.dinheiro)}</TableCell>
                  <TableCell>{formatReal(registros.dinheiro)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PIX</TableCell>
                  <TableCell>{formatReal(registros.pix)}</TableCell>
                  <TableCell>{formatReal(registros.pix)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>TRANSFERÊNCIA</TableCell>
                  <TableCell>{formatReal(registros.transferencia)}</TableCell>
                  <TableCell>{formatReal(registros.transferencia)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.totalizador}>Total</TableCell>
                  <TableCell className={classes.totalizador}>
                    {formatReal(totalBruto)}
                  </TableCell>
                  <TableCell className={classes.totalizador}>
                    {formatReal(totalLiquido)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </>
  );
}
