import React, { useEffect, useState } from 'react';
import Menu from '../../Menu';
import { getClinica } from '../../../services/auth';
import { formatData } from '../../../utils/funcoes';
import api from '../../../services/api';
import Notificacao from '../../../utils/notificacao';
import { makeStyles } from '@material-ui/core/styles';
import MenuOpcoes from '../../Pacientes/menuOpcoes';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import Zoom from '@material-ui/core/Zoom';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import AceiteOrcamento from './aceiteOrcamentoEstetico';
import { useConfig } from '../../../hooks/config';
import RepeatIcon from '@material-ui/icons/Repeat';
import ReutilizarOrcamento from './reutilizar';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: '60px',
    margin: theme.spacing(1),
  },
  menuOpcoes: {
    marginTop: theme.spacing(7.5),
  },
  opcoesLista: {
    margin: theme.spacing(1),
  },
  amarelo: {
    backgroundColor: '#FFC107',
  },
  vermelho: {
    backgroundColor: '#FF0000',
  },
}));

export default function OrcamentosEsteticos() {
  const { id } = useParams();
  const Paciente = JSON.parse(atob(id));
  const { configuracoes } = useConfig();
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const [orcamentos, setOrcamentos] = useState([]);
  const [orcamento, setOrcamento] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalAceite, setModalAceite] = useState(false);
  const [modalReutilizar, setModalReutilizar] = useState(false);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    atualizar();
  }, []);

  const atualizar = async () => {
    await api
      .post('Estetico/search.php', {
        pdo: getClinica(),
        consulta: 'AND e.status!=1 AND e.idCliente=' + Paciente.id,
      })
      .then(res => {
        res.data.length > 0 ? setOrcamentos(res.data) : setOrcamentos([]);
      });
    setLoading(false);
  };

  const abrirOrcamento = orc => {
    setOrcamento(orc);
    setModalAceite(true);
  };

  const editarOrcamento = orcamento => {
    let orc = {
      Cliente: orcamento.Cliente,
      Dentista: orcamento.Dentista,
      dataCriacao: orcamento.dataCriacao,
      id: orcamento.id,
      idCliente: orcamento.idCliente,
      idDentista: orcamento.idDentista,
      status: orcamento.status,
    };
    let orcamentoEstetico = btoa(JSON.stringify(orc));
    window.location.pathname = `/orcamento_estetico/${orcamentoEstetico}`;
  };

  const imprimirOrc = orcamento => {
    let orc = btoa(JSON.stringify(orcamento));
    window.open(
      `/imprimir_orc_odonto/${orc}`,
      'Recibo',
      'height=700,width=900',
    );
  };

  const reutilizar = orcamento => {
    setOrcamento(orcamento);
    setModalReutilizar(true);
  };

  return (
    <>
      <Menu titulo={`Pacientes ► ${Paciente.nome} ► Orçamentos Estéticos`} />
      <Box
        display="flex"
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={() => (window.location.pathname = '/pacientes')}
          startIcon={<NavigateBeforeIcon />}
        >
          Voltar
        </Button>
        <div className={classes.menuOpcoes}>
          <MenuOpcoes paciente={Paciente} />
        </div>
      </Box>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Nº Tratamento</b>
                  </TableCell>
                  <TableCell>
                    <b>Data Cadastro</b>
                  </TableCell>
                  <TableCell>
                    <b>Profissional</b>
                  </TableCell>
                  <TableCell>
                    <b>Status</b>
                  </TableCell>
                  <TableCell align="center">
                    <b>Opções</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orcamentos
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(orcamento => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={orcamento.id}
                      >
                        <TableCell>{orcamento.id}</TableCell>
                        <TableCell>
                          {formatData(orcamento.dataCriacao)}
                        </TableCell>
                        <TableCell>{orcamento.Dentista}</TableCell>
                        <TableCell>
                          {orcamento.status === '0' ? (
                            <Tooltip
                              title="CONFIRMAÇÃO PENDENTE"
                              TransitionComponent={Zoom}
                            >
                              <Avatar className={classes.amarelo}>PE</Avatar>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title="RECUSADO PELO PACIENTE"
                              TransitionComponent={Zoom}
                            >
                              <Avatar className={classes.vermelho}>RE</Avatar>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <div>
                            <Button
                              size="small"
                              className={classes.opcoesLista}
                              startIcon={<OpenInNewIcon />}
                              variant="contained"
                              color="primary"
                              onClick={() => abrirOrcamento(orcamento)}
                            >
                              Abrir
                            </Button>
                            <Button
                              size="small"
                              className={classes.opcoesLista}
                              startIcon={<EditIcon />}
                              variant="contained"
                              color="secondary"
                              onClick={() => editarOrcamento(orcamento)}
                            >
                              Editar
                            </Button>
                            <Button
                              size="small"
                              className={classes.opcoesLista}
                              startIcon={<RepeatIcon />}
                              variant="outlined"
                              color="secondary"
                              onClick={() => reutilizar(orcamento)}
                            >
                              Reutilizar
                            </Button>
                            {/* <Button size="small" className={classes.opcoesLista} startIcon={<PrintIcon />} variant="contained" color="default" onClick={()=>imprimirOrc(orcamento)}>Imprimir</Button> */}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={orcamentos.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por pagina"
          />
        </Paper>
      )}
      {modalAceite && (
        <AceiteOrcamento
          modal={modalAceite}
          setModal={setModalAceite}
          tratamento={orcamento}
          orcamentos={orcamentos}
          setOrcamentos={setOrcamentos}
          abrirAviso={abrirAviso}
          configuracoes={configuracoes}
        />
      )}
      {modalReutilizar && (
        <ReutilizarOrcamento
          modal={modalReutilizar}
          fecharModal={() => setModalReutilizar(false)}
          orcamento={orcamento}
          setOrcamentos={setOrcamentos}
          abrirAviso={abrirAviso}
        />
      )}
      <Notificacao
        aviso={aviso}
        fecharAviso={() => setAviso(false)}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </>
  );
}
