import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

export default function Queixa({ queixa, setQueixa }) {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            label="Queixa Principal e Evolução da Doença Atual"
            fullWidth
            value={queixa}
            onChange={e => setQueixa(e.target.value)}
            variant="outlined"
            multiline
            rows={32}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    </>
  );
}
