import React, { useState, useEffect } from 'react';
import Menu from '../Menu';
import api from '../../services/api';
import { getClinica } from '../../services/auth';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Notificacao from '../../utils/notificacao';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 500,
  },
  textPesquisa: {
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '5px',
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    marginTop: '65px',
    margin: theme.spacing(1),
  },
  opcoes: {
    marginBottom: '0px',
  },
  ativo: {
    backgroundColor: '#28A745',
  },
  inativo: {
    backgroundColor: 'red',
  },
}));

const dias = [
  { id: '01', valor: '01' },
  { id: '02', valor: '02' },
  { id: '03', valor: '03' },
  { id: '04', valor: '04' },
  { id: '05', valor: '05' },
  { id: '06', valor: '06' },
  { id: '07', valor: '07' },
  { id: '08', valor: '08' },
  { id: '09', valor: '09' },
  { id: '10', valor: '10' },
  { id: '11', valor: '11' },
  { id: '12', valor: '12' },
  { id: '13', valor: '13' },
  { id: '14', valor: '14' },
  { id: '15', valor: '15' },
  { id: '16', valor: '16' },
  { id: '17', valor: '17' },
  { id: '18', valor: '18' },
  { id: '19', valor: '19' },
  { id: '20', valor: '20' },
  { id: '21', valor: '21' },
  { id: '22', valor: '22' },
  { id: '23', valor: '23' },
  { id: '24', valor: '24' },
  { id: '25', valor: '25' },
  { id: '26', valor: '26' },
  { id: '27', valor: '27' },
  { id: '28', valor: '28' },
  { id: '29', valor: '29' },
  { id: '30', valor: '30' },
  { id: '31', valor: '31' },
];

const meses = [
  { id: '01', valor: 'Janeiro' },
  { id: '02', valor: 'Fevereiro' },
  { id: '03', valor: 'Março' },
  { id: '04', valor: 'Abril' },
  { id: '05', valor: 'Maio' },
  { id: '06', valor: 'Junho' },
  { id: '07', valor: 'Julho' },
  { id: '08', valor: 'Agosto' },
  { id: '09', valor: 'Setembro' },
  { id: '10', valor: 'Outubro' },
  { id: '11', valor: 'Novembro' },
  { id: '12', valor: 'Dezembro' },
];

export default function Feriados() {
  const [loading, setLoading] = useState(true);
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const [desabilitar, setDesabilitar] = useState(false);
  const [feriados, setFeriados] = useState([]);
  const [idFeirado, setIdFerido] = useState('');
  const [dia, setDia] = useState('');
  const [mes, setMes] = useState('');
  const [descricao, setDescricao] = useState('');
  const [ativo, setAtivo] = useState('');
  const [buscar, setBuscar] = useState('');
  const [modalCadastro, setModalCadastro] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const fecharAviso = () => {
    setAviso(false);
  };

  const validarCampos = () => {
    if (descricao !== '' && dia !== '' && mes !== '') {
      return true;
    }
    return false;
  };

  const limparCampos = () => {
    setIdFerido('');
    setDia('');
    setMes('');
    setDescricao('');
    setAtivo('');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const abrirModalCadastro = () => {
    limparCampos();
    setModalCadastro(true);
  };

  const fecharModalCadastro = () => {
    setModalCadastro(false);
    limparCampos();
  };

  const fecharModalEditar = () => {
    setModalEditar(false);
    limparCampos();
  };

  const abrirFeriado = feriado => {
    setIdFerido(feriado.id);
    setDia(feriado.dia);
    setMes(feriado.mes);
    setDescricao(feriado.descricao);
    setAtivo(feriado.ativo);
    setModalEditar(true);
  };

  const dataExiste = (dia, mes, tipo) => {
    let cont = 0;
    for (let i = 0; i < feriados.length; i++) {
      if (feriados[i].dia === dia && feriados[i].mes === mes) {
        cont++;
      }
    }
    if (tipo === 'cadastro') {
      if (cont > 0) {
        return true;
      }
      return false;
    }
    if (tipo === 'alteracao') {
      if (cont > 1) {
        return true;
      }
      return false;
    }
  };

  const pegarNomeMes = valor => {
    for (let i = 0; i < meses.length; i++) {
      if (meses[i].id === valor) {
        return meses[i].valor;
      }
    }
  };

  const cadastrarFeriado = async () => {
    setDesabilitar(true);
    if (validarCampos()) {
      if (!dataExiste(dia, mes, 'cadastro')) {
        await api
          .post('Feriado/save.php', {
            pdo: getClinica(),
            descricao: descricao,
            dia: dia,
            mes: mes,
          })
          .then(() => {
            abrirAviso('success', 'Feriado cadastrado.');
            setDesabilitar(false);
            atualizar();
            fecharModalCadastro();
          });
      } else {
        abrirAviso('warning', 'Data já está cadastrada.');
        setDesabilitar(false);
      }
    } else {
      abrirAviso('warning', 'Preencha todos os campos obrigatórios.');
      setDesabilitar(false);
    }
  };

  const alterarFeriado = async () => {
    setDesabilitar(true);
    if (validarCampos()) {
      if (!dataExiste(dia, mes, 'alteracao')) {
        await api
          .post('Feriado/update.php', {
            pdo: getClinica(),
            descricao: descricao,
            dia: dia,
            mes: mes,
            ativo: ativo,
            id: idFeirado,
          })
          .then(() => {
            abrirAviso('info', 'Feriado alterado.');
            atualizar();
            fecharModalEditar();
            setDesabilitar(false);
          });
      } else {
        abrirAviso('warning', 'Data já está cadastrada.');
        setDesabilitar(false);
      }
    } else {
      abrirAviso('warning', 'Preencha todos os campos obrigatórios.');
      setDesabilitar(false);
    }
  };

  const atualizar = async () => {
    await api.get('Feriado/list.php?pdo=' + getClinica()).then(res => {
      setFeriados(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    atualizar();
  }, []);

  const filtro = feriados.filter(f => {
    return f.descricao.toLowerCase().indexOf(buscar.toLowerCase()) !== -1;
  });

  return (
    <div>
      <Menu titulo="Feriados" />
      <Box
        display="flex"
        className={classes.opcoes}
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={abrirModalCadastro}
            startIcon={<AddCircleIcon />}
          >
            Novo
          </Button>
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            onClick={() => window.location.reload()}
            startIcon={<RefreshIcon />}
          >
            Atualizar
          </Button>
        </div>
      </Box>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <Paper className={classes.root}>
          <TextField
            className={classes.textPesquisa}
            value={buscar}
            onChange={e => setBuscar(e.target.value)}
            size="small"
            label="Pesquisar"
            variant="outlined"
          />
          <TableContainer className={classes.container}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Descrição</b>
                  </TableCell>
                  <TableCell>
                    <b>Dia</b>
                  </TableCell>
                  <TableCell>
                    <b>Mês</b>
                  </TableCell>
                  <TableCell>
                    <b>Status</b>
                  </TableCell>
                  <TableCell>
                    <b>Opção</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filtro
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(feriado => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={feriado.id}
                      >
                        <TableCell>{feriado.id}</TableCell>
                        <TableCell>{feriado.descricao}</TableCell>
                        <TableCell>{feriado.dia}</TableCell>
                        <TableCell>{pegarNomeMes(feriado.mes)}</TableCell>
                        <TableCell>
                          {feriado.ativo === '0' ? (
                            <Avatar className={classes.ativo} title="Ativo">
                              <CheckCircleIcon />
                            </Avatar>
                          ) : (
                            <Avatar className={classes.inativo} title="Inativo">
                              <CancelIcon />
                            </Avatar>
                          )}
                        </TableCell>
                        <TableCell>
                          <Button
                            startIcon={<OpenInNewIcon />}
                            onClick={() => abrirFeriado(feriado)}
                            size="small"
                            variant="contained"
                            color="primary"
                          >
                            Abrir
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={feriados.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por pagina"
          />
        </Paper>
      )}

      {/* cadastrar feriado */}
      <Dialog
        fullScreen={fullScreen}
        open={modalCadastro}
        onClose={fecharModalCadastro}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Cadastrar Feriado'}
        </DialogTitle>
        <DialogContent>
          <form className={classes.form} noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Descrição"
                  value={descricao}
                  onChange={e => setDescricao(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  select
                  label="Dia"
                  value={dia}
                  onChange={e => setDia(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="">Selecione</MenuItem>
                  {dias.map(dia => {
                    return <MenuItem value={dia.id}>{dia.valor}</MenuItem>;
                  })}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  select
                  label="Mês"
                  value={mes}
                  onChange={e => setMes(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="">Selecione</MenuItem>
                  {meses.map(mes => {
                    return <MenuItem value={mes.id}>{mes.valor}</MenuItem>;
                  })}
                </TextField>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={cadastrarFeriado}
            variant="contained"
            color="primary"
            disabled={desabilitar}
          >
            Cadastrar
          </Button>
          <Button
            variant="contained"
            color="default"
            onClick={() => setModalCadastro(false)}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      {/* editar feriado */}
      <Dialog
        fullScreen={fullScreen}
        open={modalEditar}
        onClose={fecharModalEditar}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Editar Feriado'}
        </DialogTitle>
        <DialogContent>
          <form className={classes.form} noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Descrição"
                  value={descricao}
                  onChange={e => setDescricao(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  select
                  label="Dia"
                  value={dia}
                  onChange={e => setDia(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="">Selecione</MenuItem>
                  {dias.map(dia => {
                    return <MenuItem value={dia.id}>{dia.valor}</MenuItem>;
                  })}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  select
                  label="Mês"
                  value={mes}
                  onChange={e => setMes(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="">Selecione</MenuItem>
                  {meses.map(mes => {
                    return <MenuItem value={mes.id}>{mes.valor}</MenuItem>;
                  })}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  select
                  label="Status"
                  value={ativo}
                  onChange={e => setAtivo(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="0">Ativo</MenuItem>
                  <MenuItem value="-1">Inativo</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={alterarFeriado}
            variant="contained"
            color="primary"
            disabled={desabilitar}
          >
            Salvar
          </Button>
          <Button
            variant="contained"
            color="default"
            onClick={() => fecharModalEditar()}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <Notificacao
        aviso={aviso}
        fecharAviso={fecharAviso}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </div>
  );
}
