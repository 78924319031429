import React, { useEffect, useState } from 'react';
import Cabecalho from '../../../utils/cabecalho';
import Rodape from '../../../utils/rodape';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import PrintIcon from '@material-ui/icons/Print';
import { mask } from 'remask';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(1.5),
    right: theme.spacing(1.5),
  },
  tituloContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tituloH6: {
    margin: '0px 0px',
  },
}));

export default function Recibo() {
  const { cod } = useParams();
  const parametro = JSON.parse(atob(cod));
  const [exibirResponsavel, setExibirResponsavel] = useState(false);
  const classes = useStyles();

  const imprimir = () => {
    window.document.getElementById('recibo-print').style.display = 'none';
    window.print();
    window.document.getElementById('recibo-print').style.display = 'block';
    window.close();
  };

  useEffect(() => {
    if (parametro.dataNasc) {
      let hoje = new Date();
      let dataN = new Date(parametro.dataNasc);
      let idade = hoje.getFullYear() - dataN.getFullYear();
      idade < 18 ? setExibirResponsavel(true) : setExibirResponsavel(false);
    }
  }, [parametro]);

  return (
    <div>
      <Cabecalho />
      <br />
      <div className="container">
        <div className={classes.tituloContainer}>
          <h2 className={classes.tituloH6}>RECIBO</h2>
          <h2 className={classes.tituloH6}>{parametro.valor}</h2>
        </div>
        {!exibirResponsavel && (
          <div>
            <p align="justify">
              Recebi(emos) de {parametro.nome}, portador(a) do CPF nº{' '}
              {mask(parametro.cpf, '999.999.999-99')}, o valor de{' '}
              {parametro.valorExtenso}, correspondente {parametro.tratamento} e
              para clareza firmo(amos) o presente recibo.
            </p>
            <p>Data do Pagamento: {parametro.pagoDia}</p>
            <p style={{ marginTop: '30px' }} align="center">
              ____________________________________________________
              <br />
              {parametro.cnpj}
              <br />
              {parametro.razao}
            </p>
          </div>
        )}
        {exibirResponsavel && (
          <div>
            <p align="justify">
              Recebi(emos) da responsável legal,{' '}
              {parametro.responsavel
                ? parametro.responsavel
                : '__________________________________________'}
              , portador(a) do CPF sob o nº{' '}
              {parametro.respCpf
                ? mask(parametro.respCpf, '999.999.999-99')
                : '_________________________'}{' '}
              e no RG sob o nº{' '}
              {parametro.respRg ? parametro.respRg : '_________________'}, o
              valor de {parametro.valorExtenso} proveniente{' '}
              {parametro.tratamento} realizado em {parametro.nome}. E para
              clareza firmo(amos) o presente recibo.
            </p>
            <p>Data do Pagamento: {parametro.pagoDia}</p>
            <p style={{ marginTop: '30px' }} align="center">
              ____________________________________________________
              <br />
              {parametro.cnpj}
              <br />
              {parametro.razao}
            </p>
          </div>
        )}
        <div style={{ marginTop: '-10px', fontSize: '14px' }}>
          <Rodape />
        </div>
      </div>
      <div style={{ border: '1px dashed #999', width: '100%' }}></div>
      <div id="recibo-print">
        <Fab className={classes.fab} color="primary" onClick={() => imprimir()}>
          <PrintIcon />
        </Fab>
      </div>
    </div>
  );
}
