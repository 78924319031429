import React, { useState, useEffect } from 'react';
import Menu from '../Menu';
import api from '../../services/api';
import { getClinica, getToken } from '../../services/auth';
import { makeStyles } from '@material-ui/core/styles';
import { formatData } from '../../utils/funcoes';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import InfoIcon from '@material-ui/icons/Info';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { usePermissoes } from '../../hooks/permissoes';
import Notificacao from '../../utils/notificacao';
import { useConfig } from '../../hooks/config';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 500,
  },
  textPesquisa: {
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '5px',
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    marginTop: '65px',
    margin: theme.spacing(1),
  },
  opcoes: {
    marginBottom: '0px',
  },
  opcoesEstoque: {
    margin: '0px 4px',
  },
  opcoesEstoqueInfo: {
    backgroundColor: '#FFC107',
    '&:hover': {
      backgroundColor: '#e0aa08',
    },
  },
  ativo: {
    backgroundColor: '#28A745',
  },
  inativo: {
    backgroundColor: 'red',
  },
}));

export default function Estoque() {
  const [produtos, setProdutos] = useState([]);
  const [loading, setLoading] = useState(true);
  const { permissoes } = usePermissoes();
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const { configuracoes } = useConfig();
  const [desabilitar, setDesabilitar] = useState(false);
  const [buscar, setBuscar] = useState('');

  const [idProduto, setIdProduto] = useState('');
  const [nome, setNome] = useState('');
  const [descricao, setDescricao] = useState('');
  const [tipoUnitario, setTipoUnitario] = useState('');
  const [estoqueMin, setEstoqueMin] = useState(0);
  const [estoqueAtual, setEstoqueAtual] = useState(0);
  const [ativo, setAtivo] = useState('');
  const [produto, setProduto] = useState('');
  const [quantidade, setQuantidade] = useState('');
  const [logProduto, setLogProduto] = useState([]);

  const [modalCadastro, setModalCadastro] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalInserir, setModalInserir] = useState(false);
  const [modalRetirar, setModalRetirar] = useState(false);
  const [modalMovimentacao, setModalMovimentacao] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const fecharAviso = () => {
    setAviso(false);
  };

  const limparCampos = () => {
    setIdProduto('');
    setNome('');
    setDescricao('');
    setTipoUnitario('');
    setEstoqueMin(0);
    setAtivo('');
  };

  const validarCampos = () => {
    if (nome !== '' && tipoUnitario !== '') {
      return true;
    }
    return false;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const abrirModalCadastro = () => {
    setModalCadastro(true);
    limparCampos();
  };

  const fecharModalCadastro = () => {
    setModalCadastro(false);
    limparCampos();
  };

  const fecharModalEditar = () => {
    setModalEditar(false);
    limparCampos();
  };

  const fecharModalInserir = () => {
    setModalInserir(false);
    setQuantidade('');
  };

  const fecharModalRetirar = () => {
    setModalRetirar(false);
    setQuantidade('');
  };

  const abrirProduto = produto => {
    limparCampos();
    setIdProduto(produto.id);
    setNome(produto.nome);
    setDescricao(produto.descricao);
    setTipoUnitario(produto.tipoUnitario);
    setEstoqueMin(produto.estoqueMin);
    setEstoqueAtual(produto.estoqueAtual);
    setAtivo(produto.ativo);
    setModalEditar(true);
  };

  const abrirInserirProduto = produto => {
    setQuantidade('');
    setModalInserir(true);
    setProduto(produto);
  };

  const abrirRetirarProduto = produto => {
    setQuantidade('');
    setModalRetirar(true);
    setProduto(produto);
  };

  const cadastrarProduto = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.estCad !== '0' && permissoes !== false) {
        if (validarCampos()) {
          let hoje = new Date();
          await api
            .post('Produto/save.php', {
              pdo: getClinica(),
              nome: nome,
              descricao: descricao,
              tipoUnitario: tipoUnitario,
              estoqueMin: estoqueMin,
              estoqueAtual: 0,
              userCadastro: getToken(),
              dataCadastro:
                hoje.getFullYear() +
                '-' +
                (hoje.getMonth() + 1) +
                '-' +
                hoje.getDate(),
            })
            .then(() => {
              abrirAviso('success', 'Produto cadastrado.');
              limparCampos();
              setDesabilitar(false);
              atualizar();
              fecharModalCadastro();
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          abrirAviso('warning', 'Preencha todos os campos obrigatórios.');
          setDesabilitar(false);
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
        setDesabilitar(false);
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
      setDesabilitar(false);
    }
  };

  const alterarProduto = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.estAlt !== '0' && permissoes !== false) {
        if (validarCampos()) {
          let hoje = new Date();
          await api
            .post('Produto/update.php', {
              pdo: getClinica(),
              nome: nome,
              descricao: descricao,
              tipoUnitario: tipoUnitario,
              estoqueMin: estoqueMin,
              estoqueAtual: estoqueAtual,
              userUltAlteracao: getToken(),
              dataUltAlteracao:
                hoje.getFullYear() +
                '-' +
                (hoje.getMonth() + 1) +
                '-' +
                hoje.getDate(),
              ativo: ativo,
              id: idProduto,
            })
            .then(() => {
              abrirAviso('success', 'Produto alterado.');
              limparCampos();
              setDesabilitar(false);
              atualizar();
              fecharModalEditar();
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          abrirAviso('warning', 'Preencha todos os campos obrigatórios.');
          setDesabilitar(false);
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
        setDesabilitar(false);
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
      setDesabilitar(false);
    }
  };

  const alterarEstoque = async tipo => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.estAlt !== '0' && permissoes !== false) {
        if (quantidade !== '' && quantidade > 0) {
          let hoje = new Date();
          let qtd = parseInt(quantidade);
          let estAtual = parseInt(produto.estoqueAtual);
          let result = 0;
          if (tipo === 'ENTRADA') {
            result = qtd + estAtual;
          } else if (tipo === 'SAIDA') {
            result = estAtual - qtd;
          }
          await api
            .post('Produto/update.php', {
              pdo: getClinica(),
              nome: produto.nome,
              descricao: produto.descricao,
              tipoUnitario: produto.tipoUnitario,
              estoqueMin: produto.estoqueMin,
              estoqueAtual: result,
              userUltAlteracao: getToken(),
              dataUltAlteracao:
                hoje.getFullYear() +
                '-' +
                (hoje.getMonth() + 1) +
                '-' +
                hoje.getDate(),
              ativo: produto.ativo,
              id: produto.id,
            })
            .catch(error => {
              console.log(error);
            });
          await api
            .post('Produto/log.php', {
              pdo: getClinica(),
              idProduto: produto.id,
              quantidade: quantidade,
              movimentacao: tipo,
              idUser: getToken(),
              data:
                hoje.getFullYear() +
                '-' +
                (hoje.getMonth() + 1) +
                '-' +
                hoje.getDate(),
              hora:
                hoje.getHours() +
                ':' +
                hoje.getMinutes() +
                ':' +
                hoje.getSeconds(),
            })
            .then(() => {
              abrirAviso('success', 'Produto alterado.');
              setDesabilitar(false);
              atualizar();
              fecharModalInserir();
              fecharModalRetirar();
            });
        } else {
          abrirAviso('warning', 'Preencha todos os campos obrigatórios.');
          setDesabilitar(false);
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
        setDesabilitar(false);
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
      setDesabilitar(false);
    }
  };

  const abrirMovimentacao = async produto => {
    await api
      .get(
        'Produto/findLog.php?idProduto=' + produto.id + '&pdo=' + getClinica(),
      )
      .then(res => {
        setLogProduto(res.data);
      })
      .catch(error => {
        console.log(error);
      });
    setModalMovimentacao(true);
  };

  const atualizar = async () => {
    await api.get('Produto/list.php?pdo=' + getClinica()).then(res => {
      setProdutos(res.data);
    });
    setLoading(false);
  };

  useEffect(() => {
    atualizar();
  }, []);

  const filtro = produtos.filter(p => {
    return (
      p.nome.toLowerCase().indexOf(buscar.toLowerCase()) !== -1 ||
      p.id.toLowerCase().indexOf(buscar.toLowerCase()) !== -1
    );
  });

  return (
    <div>
      <Menu titulo="Estoque" />
      <Box
        display="flex"
        className={classes.opcoes}
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={abrirModalCadastro}
            startIcon={<AddCircleIcon />}
          >
            Novo
          </Button>
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            onClick={() => window.location.reload()}
            startIcon={<RefreshIcon />}
          >
            Atualizar
          </Button>
        </div>
      </Box>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <Paper className={classes.root}>
          <TextField
            className={classes.textPesquisa}
            value={buscar}
            onChange={e => setBuscar(e.target.value)}
            size="small"
            label="Pesquisar"
            variant="outlined"
          />
          <TableContainer className={classes.container}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Produto</b>
                  </TableCell>
                  <TableCell>
                    <b>Descrição</b>
                  </TableCell>
                  <TableCell>
                    <b>Tipo Un.</b>
                  </TableCell>
                  <TableCell>
                    <b>Estoque Min.</b>
                  </TableCell>
                  <TableCell>
                    <b>Estoque Atual</b>
                  </TableCell>
                  <TableCell>
                    <b>Status</b>
                  </TableCell>
                  <TableCell>
                    <b>Opções</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filtro
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(produto => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={produto.id}
                      >
                        <TableCell>{produto.id}</TableCell>
                        <TableCell>{produto.nome}</TableCell>
                        <TableCell>{produto.descricao}</TableCell>
                        <TableCell>{produto.tipoUnitario}</TableCell>
                        <TableCell>{produto.estoqueMin}</TableCell>
                        <TableCell>{produto.estoqueAtual}</TableCell>
                        <TableCell>
                          {produto.ativo === '0' ? (
                            <Avatar className={classes.ativo} title="Ativo">
                              <CheckCircleIcon />
                            </Avatar>
                          ) : (
                            <Avatar className={classes.inativo} title="Inativo">
                              <CancelIcon />
                            </Avatar>
                          )}
                        </TableCell>
                        <TableCell>
                          {produto.ativo === '0' ? (
                            <div>
                              <Button
                                className={classes.opcoesEstoque}
                                onClick={() => abrirProduto(produto)}
                                title="Editar"
                                variant="contained"
                                color="default"
                              >
                                <EditIcon />
                              </Button>
                              <Button
                                className={classes.opcoesEstoque}
                                onClick={() => abrirInserirProduto(produto)}
                                title="Entrada"
                                variant="contained"
                                color="primary"
                              >
                                <ArrowDownwardIcon />
                              </Button>
                              <Button
                                className={classes.opcoesEstoque}
                                onClick={() => abrirRetirarProduto(produto)}
                                title="Saída"
                                variant="contained"
                                color="secondary"
                              >
                                <ArrowUpwardIcon />
                              </Button>
                              <Button
                                className={classes.opcoesEstoqueInfo}
                                onClick={() => abrirMovimentacao(produto)}
                                title="Movimentação"
                                variant="contained"
                                color="default"
                              >
                                <InfoIcon />
                              </Button>
                            </div>
                          ) : (
                            <div>
                              <Button
                                className={classes.opcoesEstoque}
                                onClick={() => abrirProduto(produto)}
                                title="Editar"
                                variant="contained"
                                color="default"
                              >
                                <EditIcon />
                              </Button>
                              <Button
                                className={classes.opcoesEstoqueInfo}
                                onClick={() => abrirMovimentacao(produto)}
                                title="Movimentação"
                                variant="contained"
                                color="default"
                              >
                                <InfoIcon />
                              </Button>
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={produtos.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por pagina"
          />
        </Paper>
      )}

      {/* cadastrar produto */}
      <Dialog
        fullScreen={fullScreen}
        open={modalCadastro}
        onClose={fecharModalCadastro}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Cadastrar Produto'}
        </DialogTitle>
        <DialogContent>
          <form className={classes.form} noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Nome do produto *"
                  value={nome}
                  onChange={e => setNome(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Descrição do produto"
                  value={descricao}
                  onChange={e => setDescricao(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Tipo unitário *"
                  value={tipoUnitario}
                  onChange={e => setTipoUnitario(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Estoque Min."
                  value={estoqueMin}
                  onChange={e => setEstoqueMin(e.target.value)}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={cadastrarProduto}
            variant="contained"
            color="primary"
            disabled={desabilitar}
          >
            Cadastrar
          </Button>
          <Button
            variant="contained"
            color="default"
            onClick={fecharModalCadastro}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      {/* editar produto */}
      <Dialog
        fullScreen={fullScreen}
        open={modalEditar}
        onClose={fecharModalEditar}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Editar Produto'}
        </DialogTitle>
        <DialogContent>
          <form className={classes.form} noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Nome do produto *"
                  value={nome}
                  onChange={e => setNome(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Descrição do produto"
                  value={descricao}
                  onChange={e => setDescricao(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Tipo unitário *"
                  value={tipoUnitario}
                  onChange={e => setTipoUnitario(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Estoque Min."
                  value={estoqueMin}
                  onChange={e => setEstoqueMin(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  select
                  fullWidth
                  size="small"
                  label="Status"
                  value={ativo}
                  onChange={e => setAtivo(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="0">Ativo</MenuItem>
                  <MenuItem value="-1">Inativo</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={alterarProduto}
            variant="contained"
            color="primary"
            disabled={desabilitar}
          >
            Salvar
          </Button>
          <Button
            variant="contained"
            color="default"
            onClick={fecharModalEditar}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      {/* inserir no estoque */}
      <Dialog
        fullScreen={fullScreen}
        open={modalInserir}
        onClose={fecharModalInserir}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Inserir no estoque'}
        </DialogTitle>
        <DialogContent>
          <form className={classes.form} noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  size="small"
                  label="Quantidade *"
                  value={quantidade}
                  onChange={e => setQuantidade(e.target.value)}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => alterarEstoque('ENTRADA')}
            variant="contained"
            color="primary"
            disabled={desabilitar}
          >
            Salvar
          </Button>
          <Button
            variant="contained"
            color="default"
            onClick={fecharModalInserir}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      {/* retitar do estoque */}
      <Dialog
        fullScreen={fullScreen}
        open={modalRetirar}
        onClose={fecharModalRetirar}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Retirar no estoque'}
        </DialogTitle>
        <DialogContent>
          <form className={classes.form} noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  size="small"
                  label="Quantidade *"
                  value={quantidade}
                  onChange={e => setQuantidade(e.target.value)}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => alterarEstoque('SAIDA')}
            variant="contained"
            color="primary"
            disabled={desabilitar}
          >
            Salvar
          </Button>
          <Button
            variant="contained"
            color="default"
            onClick={fecharModalRetirar}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      {/* movimentacao do produto */}
      <Dialog
        fullScreen={fullScreen}
        open={modalMovimentacao}
        onClose={() => setModalMovimentacao(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Movimentação do produto'}
        </DialogTitle>
        <DialogContent>
          <TableContainer className={classes.container}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Hora</b>
                  </TableCell>
                  <TableCell>
                    <b>Data</b>
                  </TableCell>
                  <TableCell>
                    <b>Qtd.</b>
                  </TableCell>
                  <TableCell>
                    <b>Tipo</b>
                  </TableCell>
                  <TableCell>
                    <b>Usuário</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logProduto.map(produto => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={produto.id}
                    >
                      <TableCell>{produto.hora}</TableCell>
                      <TableCell>{formatData(produto.data)}</TableCell>
                      <TableCell>{produto.quantidade}</TableCell>
                      <TableCell>{produto.movimentacao}</TableCell>
                      <TableCell>{produto.usuario}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="default"
            onClick={() => setModalMovimentacao(false)}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <Notificacao
        aviso={aviso}
        fecharAviso={fecharAviso}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </div>
  );
}
