import React, { useState, useEffect } from 'react';
import Menu from '../Menu';
import { getClinica } from '../../services/auth';
import Notificacao from '../../utils/notificacao';
import { makeStyles } from '@material-ui/core/styles';
import api from '../../services/api';
import {
  Box,
  Grid,
  TextField,
  MenuItem,
  Button,
  Paper,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';
import MenuOpcoes from '../Pacientes/menuOpcoes';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import PrintIcon from '@material-ui/icons/Print';
import { formatData } from '../../utils/funcoes';
import { usePermissoes } from '../../hooks/permissoes';
import { mask } from 'remask';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: '60px',
    margin: theme.spacing(1),
  },
  titulos: {
    margin: '8px 0px',
  },
  menuOpcoes: {
    marginTop: theme.spacing(7.5),
  },
  containerAtestado: {
    marginTop: theme.spacing(2),
  },
  atestadoText: {
    padding: theme.spacing(2),
  },
}));

export default function Atestado() {
  const { id } = useParams();
  const { permissoes } = usePermissoes();
  const parametros = JSON.parse(atob(id));
  const [loading, setLoading] = useState(true);
  const [horaInicio, setHoraInicio] = useState(null);
  const [Paciente, setPaciente] = useState(null);
  const [horaFim, setHoraFim] = useState(null);
  const [data, setData] = useState(null);
  const [diasRepouso, setDiasRepouso] = useState('');
  const [cid, setCid] = useState('');
  const classes = useStyles();
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const [idDentista, setIdDentista] = useState(null);
  const [dentistas, setDentistas] = useState([]);
  const [timbrado, setTrimbrado] = useState(false);

  useEffect(() => {
    atualizar();
  }, []);

  const atualizar = async () => {
    await api
      .get('Usuario/list_func_select.php?pdo=' + getClinica())
      .then(res => {
        setDentistas(res.data);
      })
      .catch(error => {
        console.log(error);
      });
    await api
      .get('Usuario/find.php', {
        params: { id: parametros.id, pdo: getClinica() },
      })
      .then(res => setPaciente(res.data))
      .catch(error => console.log(error));
    setLoading(false);
  };

  const imprimir = () => {
    if (permissoes.pacAtes !== '0' && permissoes !== false) {
      if (validarCampos()) {
        if (validacaoData(data)) {
          let texto = document.getElementById('atestado').textContent;
          let codText = JSON.stringify({
            cid: cid,
            timbrado: timbrado,
            idDentista: idDentista,
            texto: texto,
          });
          sessionStorage.setItem('atestado', btoa(codText));
          window.open('/imprimir_atestado', 'Atestado', 'height=700,width=900');
          sessionStorage.removeItem('atestado');
        } else {
          abrirAviso('warning', 'A data não pode ser futura.');
        }
      } else {
        abrirAviso('warning', 'Preencha os campos obrigatórios.');
      }
    } else {
      abrirAviso('warning', 'Você não tem permissão para realizar essa ação.');
    }
  };

  const validarCampos = () => {
    if (
      horaInicio !== null &&
      horaFim !== null &&
      data !== null &&
      idDentista !== null
    ) {
      return true;
    }
    return false;
  };

  const validacaoData = e => {
    let d = e.split('-');
    let consulta = new Date(d[0], d[1] - 1, d[2]);
    let hoje = new Date();
    if (consulta <= hoje) {
      return true;
    }
    return false;
  };

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const fecharAviso = () => {
    setAviso(false);
  };

  return (
    <div>
      <Menu titulo={`Pacientes ► ${parametros.nome} ► Atestado`} />
      <Box
        display="flex"
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={() => (window.location.pathname = '/pacientes')}
          startIcon={<NavigateBeforeIcon />}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={imprimir}
          startIcon={<PrintIcon />}
        >
          Imprimir
        </Button>
        <div className={classes.menuOpcoes}>
          <MenuOpcoes paciente={Paciente} />
        </div>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <TextField
            type="time"
            size="small"
            label="Hora inicial (obrigatório)"
            fullWidth
            value={horaInicio}
            onChange={e => setHoraInicio(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            type="time"
            size="small"
            label="Hora final (obrigatório)"
            fullWidth
            value={horaFim}
            onChange={e => setHoraFim(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            type="date"
            size="small"
            label="Data (obrigatório)"
            fullWidth
            value={data}
            onChange={e => setData(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            type="number"
            size="small"
            label="Qtd. dias"
            fullWidth
            value={diasRepouso}
            onChange={e => setDiasRepouso(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            label="CID"
            size="small"
            fullWidth
            value={cid}
            onChange={e => setCid(e.target.value)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            select
            size="small"
            label="Profissional (obrigatório)"
            fullWidth
            value={idDentista}
            onChange={e => setIdDentista(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          >
            <MenuItem value="" selected>
              Selecione
            </MenuItem>
            {dentistas.map(dentista => (
              <MenuItem key={dentista.id} value={dentista.id} selected>
                {dentista.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            value={timbrado}
            control={<Checkbox color="primary" />}
            onChange={() => setTrimbrado(!timbrado)}
            label="A4 Timbrado"
            labelPlacement="end"
          />
        </Grid>
      </Grid>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <Paper elevation={2} className={classes.containerAtestado}>
          <div className={classes.atestadoText}>
            <p align="justify" id="atestado">
              Atesto para os devidos fins, que o(a) Sr(a). {Paciente.nome},
              inscrito(a) no CPF sob. nº {mask(Paciente.cpf, '999.999.999-99')}{' '}
              e no RG nº {Paciente.rg}.{' '}
              {Paciente.endLograR && (
                <>
                  Residente à {Paciente.endLograR}, nº {Paciente.endNumR},
                  bairro {Paciente.endBairroR}, cep{' '}
                  {mask(Paciente.endCepR, '99999-999')}, na cidade de{' '}
                  {Paciente.endCidadeR} - {Paciente.endEstadoR.toUpperCase()}.
                </>
              )}{' '}
              Esteve sob tratamento neste consultório, no período das{' '}
              {horaInicio} às {horaFim} horas do dia {formatData(data)},
              necessitando o(a) mesmo(a) de {diasRepouso} dia(s) de repouso.
            </p>
          </div>
        </Paper>
      )}
      <Notificacao
        aviso={aviso}
        fecharAviso={fecharAviso}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </div>
  );
}
