import React, { useState, useEffect } from 'react';
import Menu from '../../Menu';
import api from '../../../services/api';
import { getClinica } from '../../../services/auth';
import './style.css';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import InserirProcedimento from './inserirProcedimento';
import InserirProcedimentosTodos from './inserirProcedimentoTodos';
import TabelaPlanoTratamento from './tabelaPlanoTratamento';
import Notificacao from '../../../utils/notificacao';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: '60px',
    margin: theme.spacing(1),
  },
  buttonAlmagma: {
    backgroundColor: '#dc3545',
    '&:hover': {
      backgroundColor: '#b52c39',
    },
    color: '#FFFF',
    margin: theme.spacing(1),
  },
  buttonCaries: {
    backgroundColor: '#ffff00',
    '&:hover': {
      backgroundColor: '#caca00',
    },
    color: '#000000',
    margin: theme.spacing(1),
  },
  buttonEndodontia: {
    backgroundColor: '#ffa500',
    '&:hover': {
      backgroundColor: '#d38c07',
    },
    color: '#000000',
    margin: theme.spacing(1),
  },
  buttonAusente: {
    backgroundColor: '#ff6347',
    '&:hover': {
      backgroundColor: '#d5523b',
    },
    color: '#ffff',
    margin: theme.spacing(1),
  },
  buttonResina: {
    backgroundColor: '#cc6600',
    '&:hover': {
      backgroundColor: '#a25202',
    },
    color: '#ffff',
    margin: theme.spacing(1),
  },
  buttonImplante: {
    backgroundColor: '#cc66cc',
    '&:hover': {
      backgroundColor: '#9d499d',
    },
    color: '#ffff',
    margin: theme.spacing(1),
  },
  buttonSelante: {
    backgroundColor: '#018001',
    '&:hover': {
      backgroundColor: '#016801',
    },
    color: '#ffff',
    margin: theme.spacing(1),
  },
  buttonCoroa: {
    backgroundColor: '#0000ff',
    '&:hover': {
      backgroundColor: '#0303c7',
    },
    color: '#ffff',
    margin: theme.spacing(1),
  },
  buttonOutros: {
    backgroundColor: '#04CF17',
    '&:hover': {
      backgroundColor: '#049d13',
    },
    color: '#ffff',
    margin: theme.spacing(1),
  },
  buttonTodos: {
    backgroundColor: '#17a2b8',
    '&:hover': {
      backgroundColor: '#127e8f',
    },
    color: '#ffff',
    margin: theme.spacing(1),
  },
  buttonLimpar: {
    margin: theme.spacing(1),
  },
  textFiltro: {
    margin: theme.spacing(1),
    width: '320px',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export default function EditarOrcamentoOdontologico() {
  const { orc } = useParams();
  const Orcamento = JSON.parse(atob(orc));
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const [desabilitar, setDesabilitar] = useState(false);
  const [adultoCima, setAdultoCima] = useState([]);
  const [adultoBaixo, setAdultoBaixo] = useState([]);
  const [criancaCima, setCriancaCima] = useState([]);
  const [criancaBaixo, setCriancaBaixo] = useState([]);
  const [idProcedimento, setIdProcedimento] = useState('');
  const [planoTratamento, setPlanoTratamento] = useState([]);
  const [procedimentos, setProcedimentos] = useState([]);
  const [cor, setCor] = useState('');
  const [arcadaDentaria, setArcadaDentaria] = useState('');
  const [tipoArcada, setTipoArcada] = useState('');
  const [id, setId] = useState('');
  const [dente, setDente] = useState('');
  const [tipo, setTipo] = useState('');
  const [face, setFace] = useState('');
  const [valor, setValor] = useState('');
  const [desconto, setDesconto] = useState(0);
  const [estadoAtual, setEstadoAtual] = useState('');
  const [idTratamento, setIdTratamento] = useState('');
  const [total, setTotal] = useState(0);
  const [tipoDesc, setTipoDesc] = useState('0');
  const [totalFinal, setTotalFinal] = useState(total);
  const [ratear, setRatear] = useState(0);
  const [modalDente, setModalDente] = useState(false);
  const [modalTodos, setModalTodos] = useState(false);

  const [value, setValue] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    atualizar();
  }, []);

  useEffect(() => {
    planoTratamento.map(planoTrat => {
      paint2(planoTrat, getFace(planoTrat));
    });
    soma();
  }, [planoTratamento]);

  const atualizar = async () => {
    await carregarDentes();
    await api
      .get('Procedimento/list.php?pdo=' + getClinica() + '&tipo=1')
      .then(res => setProcedimentos(res.data));
    await api
      .get(
        'PlanoTratamento/list.php?id=' + Orcamento.id + '&pdo=' + getClinica(),
      )
      .then(res => {
        setPlanoTratamento(res.data);
      });
  };

  const soma = () => {
    let soma = planoTratamento.reduce((sum, x) => sum + parseFloat(x.valor), 0);
    let desconto = planoTratamento.reduce(
      (sum, x) => sum + parseFloat(x.desconto),
      0,
    );
    setTotal(soma - desconto);
    setTotalFinal(soma - desconto);
  };

  const valorProcedimento = id => {
    let valor = 0;
    procedimentos.map(procedimento => {
      if (procedimento.id == id) {
        valor = procedimento.valor;
      }
    });
    return valor;
  };

  const carregarDentes = async () => {
    let AdCima = [],
      AdBaixo = [],
      CrCima = [],
      CrBaixo = [];
    for (let i = 1; i < 17; i++) {
      let dente;
      if (i <= 8) {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'adultoCima',
          numDente: 19 - i,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        AdCima.push(dente);
      } else {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'adultoCima',
          numDente: i + 12,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        AdCima.push(dente);
      }
    }
    for (let i = 17; i < 33; i++) {
      let dente;
      if (i <= 24) {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'adultoBaixo',
          numDente: 65 - i,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        AdBaixo.push(dente);
      } else {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'adultoBaixo',
          numDente: i + 6,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        AdBaixo.push(dente);
      }
    }
    for (let i = 33; i < 43; i++) {
      let dente;
      if (i <= 37) {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'criancaCima',
          numDente: 88 - i,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        CrCima.push(dente);
      } else {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'criancaCima',
          numDente: 23 + i,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        CrCima.push(dente);
      }
    }
    for (let i = 43; i < 53; i++) {
      let dente;
      if (i <= 47) {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'criancaBaixo',
          numDente: 128 - i,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        CrBaixo.push(dente);
      } else {
        dente = {
          id: i,
          alterou: 0,
          tipo: 'criancaBaixo',
          numDente: i + 23,
          class1: 'dente',
          class2: 'dente',
          class3: 'dente',
          class4: 'dente',
          class5: 'dente',
          class6: 'ausente',
          class7: 'ausente',
          class8: 'coroa',
          class9: 'endodoncia',
          class10: 'implante',
        };
        CrBaixo.push(dente);
      }
    }
    setAdultoCima(AdCima);
    setAdultoBaixo(AdBaixo);
    setCriancaCima(CrCima);
    setCriancaBaixo(CrBaixo);
  };

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const selecionarAba = (event, newValue) => {
    setValue(newValue);
  };

  const pegarCor = e => {
    setCor(e);
  };

  const paint = (dente, valor) => {
    setValor(valor);
    if (cor !== '') {
      if (dente.tipo === 'adultoCima') {
        adultoCimaPaint(dente, valor);
      } else if (dente.tipo === 'adultoBaixo') {
        adultoBaixoPaint(dente, valor);
      } else if (dente.tipo === 'criancaCima') {
        criancaCimaPaint(dente, valor);
      } else if (dente.tipo === 'criancaBaixo') {
        criancaBaixoPaint(dente, valor);
      }
    } else {
      abrirAviso('info', 'Selecione o estado atual do dente.');
    }
  };

  const adultoCimaPaint = (dente, valor) => {
    adultoCima[dente.id - 1].alterou = 1;
    if (cor === 1) {
      if (valor === 1) {
        adultoCima[dente.id - 1].class1 = 'marcadoVermelho';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoCima');
        setFace('V');
        setEstadoAtual('Amálgama');
      } else if (valor === 2) {
        adultoCima[dente.id - 1].class2 = 'marcadoVermelho';
        if (dente.numDente < 20) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('M');
          setEstadoAtual('Amálgama');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('D');
          setEstadoAtual('Amálgama');
        }
      } else if (valor === 3) {
        adultoCima[dente.id - 1].class3 = 'marcadoVermelho';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoCima');
        setFace('L/P');
        setEstadoAtual('Amálgama');
      } else if (valor === 4) {
        adultoCima[dente.id - 1].class4 = 'marcadoVermelho';
        if (dente.numDente < 20) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('D');
          setEstadoAtual('Amálgama');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('M');
          setEstadoAtual('Amálgama');
        }
      } else if (valor === 5) {
        adultoCima[dente.id - 1].class5 = 'marcadoVermelho';
        if (dente.id >= 6 && dente.id < 12) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('I');
          setEstadoAtual('Amálgama');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('O');
          setEstadoAtual('Amálgama');
        }
      }
    } else if (cor === 2) {
      if (valor === 1) {
        adultoCima[dente.id - 1].class1 = 'marcadoAmarelo';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoCima');
        setFace('V');
        setEstadoAtual('Cáries');
      } else if (valor === 2) {
        adultoCima[dente.id - 1].class2 = 'marcadoAmarelo';
        if (dente.numDente < 20) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('M');
          setEstadoAtual('Cáries');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('D');
          setEstadoAtual('Cáries');
        }
      } else if (valor === 3) {
        adultoCima[dente.id - 1].class3 = 'marcadoAmarelo';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoCima');
        setFace('L/P');
        setEstadoAtual('Cáries');
      } else if (valor === 4) {
        adultoCima[dente.id - 1].class4 = 'marcadoAmarelo';
        if (dente.numDente < 20) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('D');
          setEstadoAtual('Cáries');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('M');
          setEstadoAtual('Cáries');
        }
      } else if (valor === 5) {
        adultoCima[dente.id - 1].class5 = 'marcadoAmarelo';
        if (dente.id >= 6 && dente.id < 12) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('I');
          setEstadoAtual('Cáries');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('O');
          setEstadoAtual('Cáries');
        }
      }
    } else if (cor === 3) {
      adultoCima[dente.id - 1].class9 = 'marcadoLaranja';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('adultoCima');
      setFace('');
      setEstadoAtual('Endodontia');
    } else if (cor === 4) {
      adultoCima[dente.id - 1].class6 = 'marcadoTomate';
      adultoCima[dente.id - 1].class7 = 'marcadoTomate';

      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('adultoCima');
      setFace('');
      setEstadoAtual('Ausente');
    } else if (cor === 5) {
      if (valor === 1) {
        adultoCima[dente.id - 1].class1 = 'marcadoMarrom';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoCima');
        setFace('V');
        setEstadoAtual('Resina');
      } else if (valor === 2) {
        adultoCima[dente.id - 1].class2 = 'marcadoMarrom';
        if (dente.numDente < 20) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('M');
          setEstadoAtual('Resina');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('D');
          setEstadoAtual('Resina');
        }
      } else if (valor === 3) {
        adultoCima[dente.id - 1].class3 = 'marcadoMarrom';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoCima');
        setFace('L/P');
        setEstadoAtual('Resina');
      } else if (valor === 4) {
        adultoCima[dente.id - 1].class4 = 'marcadoMarrom';
        if (dente.numDente < 20) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('D');
          setEstadoAtual('Resina');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('M');
          setEstadoAtual('Resina');
        }
      } else if (valor === 5) {
        adultoCima[dente.id - 1].class5 = 'marcadoMarrom';
        if (dente.id >= 6 && dente.id < 12) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('I');
          setEstadoAtual('Resina');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('O');
          setEstadoAtual('Resina');
        }
      }
    } else if (cor === 6) {
      adultoCima[dente.id - 1].class10 = 'marcadoRoxo';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('adultoCima');
      setFace('');
      setEstadoAtual('Implante');
    } else if (cor === 7) {
      if (valor === 1) {
        adultoCima[dente.id - 1].class1 = 'marcadoVerde';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoCima');
        setFace('V');
        setEstadoAtual('Selante');
      } else if (valor === 2) {
        adultoCima[dente.id - 1].class2 = 'marcadoVerde';
        if (dente.numDente < 20) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('M');
          setEstadoAtual('Selante');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('D');
          setEstadoAtual('Selante');
        }
      } else if (valor === 3) {
        adultoCima[dente.id - 1].class3 = 'marcadoVerde';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoCima');
        setFace('L/P');
        setEstadoAtual('Selante');
      } else if (valor === 4) {
        adultoCima[dente.id - 1].class4 = 'marcadoVerde';
        if (dente.numDente < 20) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('D');
          setEstadoAtual('Selante');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('M');
          setEstadoAtual('Selante');
        }
      } else if (valor === 5) {
        adultoCima[dente.id - 1].class5 = 'marcadoVerde';
        if (dente.id >= 6 && dente.id < 12) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('I');
          setEstadoAtual('Selante');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoCima');
          setFace('O');
          setEstadoAtual('Selante');
        }
      }
    } else if (cor === 8) {
      adultoCima[dente.id - 1].class8 = 'marcadoAzul';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('adultoCima');
      setFace('');
      setEstadoAtual('Coroa');
    } else if (cor === 9) {
      adultoCima[dente.id - 1].class1 = 'dente';
      adultoCima[dente.id - 1].class2 = 'dente';
      adultoCima[dente.id - 1].class3 = 'dente';
      adultoCima[dente.id - 1].class4 = 'dente';
      adultoCima[dente.id - 1].class5 = 'dente';
      adultoCima[dente.id - 1].class6 = 'ausente';
      adultoCima[dente.id - 1].class7 = 'ausente';
      adultoCima[dente.id - 1].class8 = 'coroa';
      adultoCima[dente.id - 1].class9 = 'endodoncia';
      adultoCima[dente.id - 1].class10 = 'implante';
      adultoCima[dente.id - 1].alterou = 0;
    } else if (cor === 11) {
      adultoCima[dente.id - 1].class9 = 'marcadoVerdeClaro';
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('adultoCima');
      setFace('');
      setEstadoAtual('');
      setModalDente(true);
    }
    setAdultoCima([...adultoCima]);
  };

  const adultoBaixoPaint = (dente, valor) => {
    adultoBaixo[dente.id - 17].alterou = 1;
    if (cor === 1) {
      if (valor === 1) {
        adultoBaixo[dente.id - 17].class1 = 'marcadoVermelho';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoBaixo');
        setFace('L/P');
        setEstadoAtual('Amálgama');
      } else if (valor === 2) {
        adultoBaixo[dente.id - 17].class2 = 'marcadoVermelho';
        if (dente.numDente > 40) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('M');
          setEstadoAtual('Amálgama');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('D');
          setEstadoAtual('Amálgama');
        }
      } else if (valor === 3) {
        adultoBaixo[dente.id - 17].class3 = 'marcadoVermelho';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoBaixo');
        setFace('V');
        setEstadoAtual('Amálgama');
      } else if (valor === 4) {
        adultoBaixo[dente.id - 17].class4 = 'marcadoVermelho';
        if (dente.numDente > 40) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('D');
          setEstadoAtual('Amálgama');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('M');
          setEstadoAtual('Amálgama');
        }
      } else if (valor === 5) {
        adultoBaixo[dente.id - 17].class5 = 'marcadoVermelho';
        if (dente.id >= 22 && dente.id < 28) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('I');
          setEstadoAtual('Amálgama');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('O');
          setEstadoAtual('Amálgama');
        }
      }
    } else if (cor === 2) {
      if (valor === 1) {
        adultoBaixo[dente.id - 17].class1 = 'marcadoAmarelo';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoBaixo');
        setFace('L/P');
        setEstadoAtual('Cáries');
      } else if (valor === 2) {
        adultoBaixo[dente.id - 17].class2 = 'marcadoAmarelo';
        if (dente.numDente > 40) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('M');
          setEstadoAtual('Cáries');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('D');
          setEstadoAtual('Cáries');
        }
      } else if (valor === 3) {
        adultoBaixo[dente.id - 17].class3 = 'marcadoAmarelo';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoBaixo');
        setFace('V');
        setEstadoAtual('Cáries');
      } else if (valor === 4) {
        adultoBaixo[dente.id - 17].class4 = 'marcadoAmarelo';
        if (dente.numDente > 40) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('D');
          setEstadoAtual('Cáries');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('M');
          setEstadoAtual('Cáries');
        }
      } else if (valor === 5) {
        adultoBaixo[dente.id - 17].class5 = 'marcadoAmarelo';
        if (dente.id >= 22 && dente.id < 28) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('I');
          setEstadoAtual('Cáries');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('O');
          setEstadoAtual('Cáries');
        }
      }
    } else if (cor === 3) {
      adultoBaixo[dente.id - 17].class9 = 'marcadoLaranja';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('adultoBaixo');
      setFace('');
      setEstadoAtual('Endodontia');
    } else if (cor === 4) {
      adultoBaixo[dente.id - 17].class6 = 'marcadoTomate';
      adultoBaixo[dente.id - 17].class7 = 'marcadoTomate';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('adultoBaixo');
      setFace('');
      setEstadoAtual('Ausente');
    } else if (cor === 5) {
      if (valor === 1) {
        adultoBaixo[dente.id - 17].class1 = 'marcadoMarrom';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoBaixo');
        setFace('L/P');
        setEstadoAtual('Resina');
      } else if (valor === 2) {
        adultoBaixo[dente.id - 17].class2 = 'marcadoMarrom';
        if (dente.numDente > 40) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('M');
          setEstadoAtual('Resina');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('D');
          setEstadoAtual('Resina');
        }
      } else if (valor === 3) {
        adultoBaixo[dente.id - 17].class3 = 'marcadoMarrom';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoBaixo');
        setFace('V');
        setEstadoAtual('Resina');
      } else if (valor === 4) {
        adultoBaixo[dente.id - 17].class4 = 'marcadoMarrom';
        if (dente.numDente > 40) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('D');
          setEstadoAtual('Resina');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('M');
          setEstadoAtual('Resina');
        }
      } else if (valor === 5) {
        adultoBaixo[dente.id - 17].class5 = 'marcadoMarrom';
        if (dente.id >= 22 && dente.id < 28) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('I');
          setEstadoAtual('Resina');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('O');
          setEstadoAtual('Resina');
        }
      }
    } else if (cor === 6) {
      adultoBaixo[dente.id - 17].class10 = 'marcadoRoxo';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('adultoBaixo');
      setFace('');
      setEstadoAtual('Implante');
    } else if (cor === 7) {
      if (valor === 1) {
        adultoBaixo[dente.id - 17].class1 = 'marcadoVerde';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoBaixo');
        setFace('L/P');
        setEstadoAtual('Selante');
      } else if (valor === 2) {
        adultoBaixo[dente.id - 17].class2 = 'marcadoVerde';
        if (dente.numDente > 40) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('M');
          setEstadoAtual('Selante');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('D');
          setEstadoAtual('Selante');
        }
      } else if (valor === 3) {
        adultoBaixo[dente.id - 17].class3 = 'marcadoVerde';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('adultoBaixo');
        setFace('V');
        setEstadoAtual('Selante');
      } else if (valor === 4) {
        adultoBaixo[dente.id - 17].class4 = 'marcadoVerde';
        if (dente.numDente > 40) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('D');
          setEstadoAtual('Selante');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('M');
          setEstadoAtual('Selante');
        }
      } else if (valor === 5) {
        adultoBaixo[dente.id - 17].class5 = 'marcadoVerde';
        if (dente.id >= 22 && dente.id < 28) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('I');
          setEstadoAtual('Selante');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('adultoBaixo');
          setFace('O');
          setEstadoAtual('Selante');
        }
      }
    } else if (cor === 8) {
      adultoBaixo[dente.id - 17].class8 = 'marcadoAzul';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('adultoBaixo');
      setFace('');
      setEstadoAtual('Coroa');
    } else if (cor === 9) {
      adultoBaixo[dente.id - 17].class1 = 'dente';
      adultoBaixo[dente.id - 17].class2 = 'dente';
      adultoBaixo[dente.id - 17].class3 = 'dente';
      adultoBaixo[dente.id - 17].class4 = 'dente';
      adultoBaixo[dente.id - 17].class5 = 'dente';
      adultoBaixo[dente.id - 17].class6 = 'ausente';
      adultoBaixo[dente.id - 17].class7 = 'ausente';
      adultoBaixo[dente.id - 17].class8 = 'coroa';
      adultoBaixo[dente.id - 17].class10 = 'implante';
      adultoBaixo[dente.id - 17].class9 = 'endodoncia';
      adultoBaixo[dente.id - 17].alterou = 0;
    } else if (cor === 11) {
      adultoBaixo[dente.id - 17].class9 = 'marcadoVerdeClaro';
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('adultoBaixo');
      setFace('');
      setEstadoAtual('');
      setModalDente(true);
    }
    setAdultoBaixo([...adultoBaixo]);
  };

  const criancaCimaPaint = (dente, valor) => {
    criancaCima[dente.id - 33].alterou = 1;
    if (cor === 1) {
      if (valor === 1) {
        criancaCima[dente.id - 33].class1 = 'marcadoVermelho';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('V');
        setEstadoAtual('Amálgama');
      } else if (valor === 2) {
        criancaCima[dente.id - 33].class2 = 'marcadoVermelho';
        if (dente.numDente < 60) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('M');
          setEstadoAtual('Amálgama');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('D');
          setEstadoAtual('Amálgama');
        }
      } else if (valor === 3) {
        criancaCima[dente.id - 33].class3 = 'marcadoVermelho';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('L/P');
        setEstadoAtual('Amálgama');
      } else if (valor === 4) {
        criancaCima[dente.id - 33].class4 = 'marcadoVermelho';
        if (dente.numDente < 60) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('D');
          setEstadoAtual('Amálgama');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('M');
          setEstadoAtual('Amálgama');
        }
      } else if (valor === 5) {
        criancaCima[dente.id - 33].class5 = 'marcadoVermelho';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('O');
        setEstadoAtual('Amálgama');
      }
    } else if (cor === 2) {
      if (valor === 1) {
        criancaCima[dente.id - 33].class1 = 'marcadoAmarelo';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('V');
        setEstadoAtual('Cáries');
      } else if (valor === 2) {
        criancaCima[dente.id - 33].class2 = 'marcadoAmarelo';
        if (dente.numDente < 60) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('M');
          setEstadoAtual('Cáries');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('D');
          setEstadoAtual('Cáries');
        }
      } else if (valor === 3) {
        criancaCima[dente.id - 33].class3 = 'marcadoAmarelo';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('L/P');
        setEstadoAtual('Cáries');
      } else if (valor === 4) {
        criancaCima[dente.id - 33].class4 = 'marcadoAmarelo';
        if (dente.numDente < 60) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('D');
          setEstadoAtual('Cáries');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('M');
          setEstadoAtual('Cáries');
        }
      } else if (valor === 5) {
        criancaCima[dente.id - 33].class5 = 'marcadoAmarelo';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('O');
        setEstadoAtual('Cáries');
      }
    } else if (cor === 3) {
      criancaCima[dente.id - 33].class9 = 'marcadoLaranja';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('criancaCima');
      setFace('');
      setEstadoAtual('Endodontia');
    } else if (cor === 4) {
      criancaCima[dente.id - 33].class6 = 'marcadoTomate';
      criancaCima[dente.id - 33].class7 = 'marcadoTomate';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('criancaCima');
      setFace('');
      setEstadoAtual('Ausente');
    } else if (cor === 5) {
      if (valor === 1) {
        criancaCima[dente.id - 33].class1 = 'marcadoMarrom';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('V');
        setEstadoAtual('Resina');
      } else if (valor === 2) {
        criancaCima[dente.id - 33].class2 = 'marcadoMarrom';
        if (dente.numDente < 60) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('M');
          setEstadoAtual('Resina');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('D');
          setEstadoAtual('Resina');
        }
      } else if (valor === 3) {
        criancaCima[dente.id - 33].class3 = 'marcadoMarrom';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('L/P');
        setEstadoAtual('Resina');
      } else if (valor === 4) {
        criancaCima[dente.id - 33].class4 = 'marcadoMarrom';
        if (dente.numDente < 60) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('D');
          setEstadoAtual('Resina');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('M');
          setEstadoAtual('Resina');
        }
      } else if (valor === 5) {
        criancaCima[dente.id - 33].class5 = 'marcadoMarrom';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('O');
        setEstadoAtual('Resina');
      }
    } else if (cor === 6) {
      criancaCima[dente.id - 33].class10 = 'marcadoRoxo';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('criancaCima');
      setFace('');
      setEstadoAtual('Implante');
    } else if (cor === 7) {
      if (valor === 1) {
        criancaCima[dente.id - 33].class1 = 'marcadoVerde';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('V');
        setEstadoAtual('Selante');
      } else if (valor === 2) {
        criancaCima[dente.id - 33].class2 = 'marcadoVerde';
        if (dente.numDente < 60) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('M');
          setEstadoAtual('Selante');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('D');
          setEstadoAtual('Selante');
        }
      } else if (valor === 3) {
        criancaCima[dente.id - 33].class3 = 'marcadoVerde';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('L/P');
        setEstadoAtual('Selante');
      } else if (valor === 4) {
        criancaCima[dente.id - 33].class4 = 'marcadoVerde';
        if (dente.numDente < 60) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('D');
          setEstadoAtual('Selante');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaCima');
          setFace('M');
          setEstadoAtual('Selante');
        }
      } else if (valor === 5) {
        criancaCima[dente.id - 33].class5 = 'marcadoVerde';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaCima');
        setFace('O');
        setEstadoAtual('Selante');
      }
    } else if (cor === 8) {
      criancaCima[dente.id - 33].class8 = 'marcadoAzul';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('criancaCima');
      setFace('');
      setEstadoAtual('Coroa');
    } else if (cor === 9) {
      criancaCima[dente.id - 33].class1 = 'dente';
      criancaCima[dente.id - 33].class2 = 'dente';
      criancaCima[dente.id - 33].class3 = 'dente';
      criancaCima[dente.id - 33].class4 = 'dente';
      criancaCima[dente.id - 33].class5 = 'dente';
      criancaCima[dente.id - 33].class6 = 'ausente';
      criancaCima[dente.id - 33].class7 = 'ausente';
      criancaCima[dente.id - 33].class8 = 'coroa';
      criancaCima[dente.id - 33].class10 = 'implante';
      criancaCima[dente.id - 33].class9 = 'endodoncia';
      criancaCima[dente.id - 33].alterou = 0;
    } else if (cor === 11) {
      criancaCima[dente.id - 33].class9 = 'marcadoVerdeClaro';
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('criancaCima');
      setFace('');
      setEstadoAtual('');
      setModalDente(true);
    }
    setCriancaCima([...criancaCima]);
  };

  const criancaBaixoPaint = (dente, valor) => {
    criancaBaixo[dente.id - 43].alterou = 1;
    if (cor === 1) {
      if (valor === 1) {
        criancaBaixo[dente.id - 43].class1 = 'marcadoVermelho';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('L/P');
        setEstadoAtual('Amálgama');
      } else if (valor === 2) {
        criancaBaixo[dente.id - 43].class2 = 'marcadoVermelho';
        if (dente.numDente > 80) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('M');
          setEstadoAtual('Amálgama');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('D');
          setEstadoAtual('Amálgama');
        }
      } else if (valor === 3) {
        criancaBaixo[dente.id - 43].class3 = 'marcadoVermelho';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('V');
        setEstadoAtual('Amálgama');
      } else if (valor === 4) {
        criancaBaixo[dente.id - 43].class4 = 'marcadoVermelho';
        if (dente.numDente > 80) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('D');
          setEstadoAtual('Amálgama');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('M');
          setEstadoAtual('Amálgama');
        }
      } else if (valor === 5) {
        criancaBaixo[dente.id - 43].class5 = 'marcadoVermelho';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('O');
        setEstadoAtual('Amálgama');
      }
    } else if (cor === 2) {
      if (valor === 1) {
        criancaBaixo[dente.id - 43].class1 = 'marcadoAmarelo';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('L/P');
        setEstadoAtual('Cáries');
      } else if (valor === 2) {
        criancaBaixo[dente.id - 43].class2 = 'marcadoAmarelo';
        if (dente.numDente > 80) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('M');
          setEstadoAtual('Cáries');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('D');
          setEstadoAtual('Cáries');
        }
      } else if (valor === 3) {
        criancaBaixo[dente.id - 43].class3 = 'marcadoAmarelo';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('V');
        setEstadoAtual('Cáries');
      } else if (valor === 4) {
        criancaBaixo[dente.id - 43].class4 = 'marcadoAmarelo';
        if (dente.numDente > 80) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('D');
          setEstadoAtual('Cáries');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('M');
          setEstadoAtual('Cáries');
        }
      } else if (valor === 5) {
        criancaBaixo[dente.id - 43].class5 = 'marcadoAmarelo';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('O');
        setEstadoAtual('Cáries');
      }
    } else if (cor === 3) {
      criancaBaixo[dente.id - 43].class9 = 'marcadoLaranja';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('criancaBaixo');
      setFace('');
      setEstadoAtual('Endodontia');
    } else if (cor === 4) {
      criancaBaixo[dente.id - 43].class6 = 'marcadoTomate';
      criancaBaixo[dente.id - 43].class7 = 'marcadoTomate';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('criancaBaixo');
      setFace('');
      setEstadoAtual('Ausente');
    } else if (cor === 5) {
      if (valor === 1) {
        criancaBaixo[dente.id - 43].class1 = 'marcadoMarrom';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('L/P');
        setEstadoAtual('Resina');
      } else if (valor === 2) {
        criancaBaixo[dente.id - 43].class2 = 'marcadoMarrom';
        if (dente.numDente > 80) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('M');
          setEstadoAtual('Resina');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('D');
          setEstadoAtual('Resina');
        }
      } else if (valor === 3) {
        criancaBaixo[dente.id - 43].class3 = 'marcadoMarrom';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('V');
        setEstadoAtual('Resina');
      } else if (valor === 4) {
        criancaBaixo[dente.id - 43].class4 = 'marcadoMarrom';
        if (dente.numDente > 80) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('D');
          setEstadoAtual('Resina');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('M');
          setEstadoAtual('Resina');
        }
      } else if (valor === 5) {
        criancaBaixo[dente.id - 43].class5 = 'marcadoMarrom';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('O');
        setEstadoAtual('Resina');
      }
    } else if (cor === 6) {
      criancaBaixo[dente.id - 43].class10 = 'marcadoRoxo';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('criancaBaixo');
      setFace('');
      setEstadoAtual('Implante');
    } else if (cor === 7) {
      if (valor === 1) {
        criancaBaixo[dente.id - 43].class1 = 'marcadoVerde';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('L/P');
        setEstadoAtual('Selante');
      } else if (valor === 2) {
        criancaBaixo[dente.id - 43].class2 = 'marcadoVerde';
        if (dente.numDente > 80) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('M');
          setEstadoAtual('Selante');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('D');
          setEstadoAtual('Selante');
        }
      } else if (valor === 3) {
        criancaBaixo[dente.id - 43].class3 = 'marcadoVerde';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('V');
        setEstadoAtual('Selante');
      } else if (valor === 4) {
        criancaBaixo[dente.id - 43].class4 = 'marcadoVerde';
        if (dente.numDente > 80) {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('D');
          setEstadoAtual('Selante');
        } else {
          setModalDente(true);
          setId(dente.id);
          setDente(dente.numDente);
          setTipo('criancaBaixo');
          setFace('M');
          setEstadoAtual('Selante');
        }
      } else if (valor === 5) {
        criancaBaixo[dente.id - 43].class5 = 'marcadoVerde';
        setModalDente(true);
        setId(dente.id);
        setDente(dente.numDente);
        setTipo('criancaBaixo');
        setFace('O');
        setEstadoAtual('Selante');
      }
    } else if (cor === 8) {
      criancaBaixo[dente.id - 43].class8 = 'marcadoAzul';
      setModalDente(true);
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('criancaBaixo');
      setFace('');
      setEstadoAtual('Coroa');
    } else if (cor === 9) {
      criancaBaixo[dente.id - 43].class1 = 'dente';
      criancaBaixo[dente.id - 43].class2 = 'dente';
      criancaBaixo[dente.id - 43].class3 = 'dente';
      criancaBaixo[dente.id - 43].class4 = 'dente';
      criancaBaixo[dente.id - 43].class5 = 'dente';
      criancaBaixo[dente.id - 43].class6 = 'ausente';
      criancaBaixo[dente.id - 43].class7 = 'ausente';
      criancaBaixo[dente.id - 43].class8 = 'coroa';
      criancaBaixo[dente.id - 43].class10 = 'implante';
      criancaBaixo[dente.id - 43].class9 = 'endodoncia';
      criancaBaixo[dente.id - 43].alterou = 0;
    } else if (cor === 11) {
      criancaBaixo[dente.id - 43].class9 = 'marcadoVerdeClaro';
      setId(dente.id);
      setDente(dente.numDente);
      setTipo('criancaBaixo');
      setFace('');
      setEstadoAtual('');
      setModalDente(true);
    }
    setCriancaBaixo([...criancaBaixo]);
  };

  const adultoCimaPaint2 = (dente, valor, cor) => {
    adultoCima[dente.idDente - 1].alterou = 1;
    if (cor === 1) {
      if (valor === 1) {
        adultoCima[dente.idDente - 1].class1 = 'marcadoVermelho';
      } else if (valor === 2) {
        adultoCima[dente.idDente - 1].class2 = 'marcadoVermelho';
      } else if (valor === 3) {
        adultoCima[dente.idDente - 1].class3 = 'marcadoVermelho';
      } else if (valor === 4) {
        adultoCima[dente.idDente - 1].class4 = 'marcadoVermelho';
      } else if (valor === 5) {
        adultoCima[dente.idDente - 1].class5 = 'marcadoVermelho';
      }
    } else if (cor === 2) {
      if (valor === 1) {
        adultoCima[dente.idDente - 1].class1 = 'marcadoAmarelo';
      } else if (valor === 2) {
        adultoCima[dente.idDente - 1].class2 = 'marcadoAmarelo';
      } else if (valor === 3) {
        adultoCima[dente.idDente - 1].class3 = 'marcadoAmarelo';
      } else if (valor === 4) {
        adultoCima[dente.idDente - 1].class4 = 'marcadoAmarelo';
      } else if (valor === 5) {
        adultoCima[dente.idDente - 1].class5 = 'marcadoAmarelo';
      }
    } else if (cor === 3) {
      adultoCima[dente.idDente - 1].class9 = 'marcadoLaranja';
    } else if (cor === 4) {
      adultoCima[dente.idDente - 1].class6 = 'marcadoTomate';
      adultoCima[dente.idDente - 1].class7 = 'marcadoTomate';
    } else if (cor === 5) {
      if (valor === 1) {
        adultoCima[dente.idDente - 1].class1 = 'marcadoMarrom';
      } else if (valor === 2) {
        adultoCima[dente.idDente - 1].class2 = 'marcadoMarrom';
      } else if (valor === 3) {
        adultoCima[dente.idDente - 1].class3 = 'marcadoMarrom';
      } else if (valor === 4) {
        adultoCima[dente.idDente - 1].class4 = 'marcadoMarrom';
      } else if (valor === 5) {
        adultoCima[dente.idDente - 1].class5 = 'marcadoMarrom';
      }
    } else if (cor === 6) {
      adultoCima[dente.idDente - 1].class10 = 'marcadoRoxo';
    } else if (cor === 7) {
      if (valor === 1) {
        adultoCima[dente.idDente - 1].class1 = 'marcadoVerde';
      } else if (valor === 2) {
        adultoCima[dente.idDente - 1].class2 = 'marcadoVerde';
      } else if (valor === 3) {
        adultoCima[dente.idDente - 1].class3 = 'marcadoVerde';
      } else if (valor === 4) {
        adultoCima[dente.idDente - 1].class4 = 'marcadoVerde';
      } else if (valor === 5) {
        adultoCima[dente.idDente - 1].class5 = 'marcadoVerde';
      }
    } else if (cor === 8) {
      adultoCima[dente.idDente - 1].class8 = 'marcadoAzul';
    } else if (cor === 9) {
      adultoCima[dente.idDente - 1].class1 = 'dente';
      adultoCima[dente.idDente - 1].class2 = 'dente';
      adultoCima[dente.idDente - 1].class3 = 'dente';
      adultoCima[dente.idDente - 1].class4 = 'dente';
      adultoCima[dente.idDente - 1].class5 = 'dente';
      adultoCima[dente.idDente - 1].class6 = 'ausente';
      adultoCima[dente.idDente - 1].class7 = 'ausente';
      adultoCima[dente.idDente - 1].class8 = 'coroa';
      adultoCima[dente.idDente - 1].class9 = 'endodoncia';
      adultoCima[dente.idDente - 1].class10 = 'implante';
      adultoCima[dente.idDente - 1].alterou = 0;
    } else if (cor === 11) {
      adultoCima[dente.idDente - 1].class9 = 'marcadoVerdeClaro';
    }
    setAdultoCima([...adultoCima]);
  };

  const adultoBaixoPaint2 = (dente, valor, cor) => {
    adultoBaixo[dente.idDente - 17].alterou = 1;
    if (cor === 1) {
      if (valor === 1) {
        adultoBaixo[dente.idDente - 17].class1 = 'marcadoVermelho';
      } else if (valor === 2) {
        adultoBaixo[dente.idDente - 17].class2 = 'marcadoVermelho';
      } else if (valor === 3) {
        adultoBaixo[dente.idDente - 17].class3 = 'marcadoVermelho';
      } else if (valor === 4) {
        adultoBaixo[dente.idDente - 17].class4 = 'marcadoVermelho';
      } else if (valor === 5) {
        adultoBaixo[dente.idDente - 17].class5 = 'marcadoVermelho';
      }
    } else if (cor === 2) {
      if (valor === 1) {
        adultoBaixo[dente.idDente - 17].class1 = 'marcadoAmarelo';
      } else if (valor === 2) {
        adultoBaixo[dente.idDente - 17].class2 = 'marcadoAmarelo';
      } else if (valor === 3) {
        adultoBaixo[dente.idDente - 17].class3 = 'marcadoAmarelo';
      } else if (valor === 4) {
        adultoBaixo[dente.idDente - 17].class4 = 'marcadoAmarelo';
      } else if (valor === 5) {
        adultoBaixo[dente.idDente - 17].class5 = 'marcadoAmarelo';
      }
    } else if (cor === 3) {
      adultoBaixo[dente.idDente - 17].class9 = 'marcadoLaranja';
    } else if (cor === 4) {
      adultoBaixo[dente.idDente - 17].class6 = 'marcadoTomate';
      adultoBaixo[dente.idDente - 17].class7 = 'marcadoTomate';
    } else if (cor === 5) {
      if (valor === 1) {
        adultoBaixo[dente.idDente - 17].class1 = 'marcadoMarrom';
      } else if (valor === 2) {
        adultoBaixo[dente.idDente - 17].class2 = 'marcadoMarrom';
      } else if (valor === 3) {
        adultoBaixo[dente.idDente - 17].class3 = 'marcadoMarrom';
      } else if (valor === 4) {
        adultoBaixo[dente.idDente - 17].class4 = 'marcadoMarrom';
      } else if (valor === 5) {
        adultoBaixo[dente.idDente - 17].class5 = 'marcadoMarrom';
      }
    } else if (cor === 6) {
      adultoBaixo[dente.idDente - 17].class10 = 'marcadoRoxo';
    } else if (cor === 7) {
      if (valor === 1) {
        adultoBaixo[dente.idDente - 17].class1 = 'marcadoVerde';
      } else if (valor === 2) {
        adultoBaixo[dente.idDente - 17].class2 = 'marcadoVerde';
      } else if (valor === 3) {
        adultoBaixo[dente.idDente - 17].class3 = 'marcadoVerde';
      } else if (valor === 4) {
        adultoBaixo[dente.idDente - 17].class4 = 'marcadoVerde';
      } else if (valor === 5) {
        adultoBaixo[dente.idDente - 17].class5 = 'marcadoVerde';
      }
    } else if (cor === 8) {
      adultoBaixo[dente.idDente - 17].class8 = 'marcadoAzul';
    } else if (cor === 9) {
      adultoBaixo[dente.idDente - 17].class1 = 'dente';
      adultoBaixo[dente.idDente - 17].class2 = 'dente';
      adultoBaixo[dente.idDente - 17].class3 = 'dente';
      adultoBaixo[dente.idDente - 17].class4 = 'dente';
      adultoBaixo[dente.idDente - 17].class5 = 'dente';
      adultoBaixo[dente.idDente - 17].class6 = 'ausente';
      adultoBaixo[dente.idDente - 17].class7 = 'ausente';
      adultoBaixo[dente.idDente - 17].class8 = 'coroa';
      adultoBaixo[dente.idDente - 17].class10 = 'implante';
      adultoBaixo[dente.idDente - 17].class9 = 'endodoncia';
      adultoBaixo[dente.idDente - 17].alterou = 0;
    } else if (cor === 11) {
      adultoBaixo[dente.idDente - 17].class9 = 'marcadoVerdeClaro';
    }
    setAdultoBaixo([...adultoBaixo]);
  };

  const criancaCimaPaint2 = (dente, valor, cor) => {
    criancaCima[dente.idDente - 33].alterou = 1;
    if (cor === 1) {
      if (valor === 1) {
        criancaCima[dente.idDente - 33].class1 = 'marcadoVermelho';
      } else if (valor === 2) {
        criancaCima[dente.idDente - 33].class2 = 'marcadoVermelho';
      } else if (valor === 3) {
        criancaCima[dente.idDente - 33].class3 = 'marcadoVermelho';
      } else if (valor === 4) {
        criancaCima[dente.idDente - 33].class4 = 'marcadoVermelho';
      } else if (valor === 5) {
        criancaCima[dente.idDente - 33].class5 = 'marcadoVermelho';
      }
    } else if (cor === 2) {
      if (valor === 1) {
        criancaCima[dente.idDente - 33].class1 = 'marcadoAmarelo';
      } else if (valor === 2) {
        criancaCima[dente.idDente - 33].class2 = 'marcadoAmarelo';
      } else if (valor === 3) {
        criancaCima[dente.idDente - 33].class3 = 'marcadoAmarelo';
      } else if (valor === 4) {
        criancaCima[dente.idDente - 33].class4 = 'marcadoAmarelo';
      } else if (valor === 5) {
        criancaCima[dente.idDente - 33].class5 = 'marcadoAmarelo';
      }
    } else if (cor === 3) {
      criancaCima[dente.idDente - 33].class9 = 'marcadoLaranja';
    } else if (cor === 4) {
      criancaCima[dente.idDente - 33].class6 = 'marcadoTomate';
      criancaCima[dente.idDente - 33].class7 = 'marcadoTomate';
    } else if (cor === 5) {
      if (valor === 1) {
        criancaCima[dente.idDente - 33].class1 = 'marcadoMarrom';
      } else if (valor === 2) {
        criancaCima[dente.idDente - 33].class2 = 'marcadoMarrom';
      } else if (valor === 3) {
        criancaCima[dente.idDente - 33].class3 = 'marcadoMarrom';
      } else if (valor === 4) {
        criancaCima[dente.idDente - 33].class4 = 'marcadoMarrom';
      } else if (valor === 5) {
        criancaCima[dente.idDente - 33].class5 = 'marcadoMarrom';
      }
    } else if (cor === 6) {
      criancaCima[dente.idDente - 33].class10 = 'marcadoRoxo';
    } else if (cor === 7) {
      if (valor === 1) {
        criancaCima[dente.idDente - 33].class1 = 'marcadoVerde';
      } else if (valor === 2) {
        criancaCima[dente.idDente - 33].class2 = 'marcadoVerde';
      } else if (valor === 3) {
        criancaCima[dente.idDente - 33].class3 = 'marcadoVerde';
      } else if (valor === 4) {
        criancaCima[dente.idDente - 33].class4 = 'marcadoVerde';
      } else if (valor === 5) {
        criancaCima[dente.idDente - 33].class5 = 'marcadoVerde';
      }
    } else if (cor === 8) {
      criancaCima[dente.idDente - 33].class8 = 'marcadoAzul';
    } else if (cor === 9) {
      criancaCima[dente.idDente - 33].class1 = 'dente';
      criancaCima[dente.idDente - 33].class2 = 'dente';
      criancaCima[dente.idDente - 33].class3 = 'dente';
      criancaCima[dente.idDente - 33].class4 = 'dente';
      criancaCima[dente.idDente - 33].class5 = 'dente';
      criancaCima[dente.idDente - 33].class6 = 'ausente';
      criancaCima[dente.idDente - 33].class7 = 'ausente';
      criancaCima[dente.idDente - 33].class8 = 'coroa';
      criancaCima[dente.idDente - 33].class10 = 'implante';
      criancaCima[dente.idDente - 33].class9 = 'endodoncia';
      criancaCima[dente.idDente - 33].alterou = 0;
    } else if (cor === 11) {
      criancaCima[dente.idDente - 33].class9 = 'marcadoVerdeClaro';
    }
    setCriancaCima([...criancaCima]);
  };

  const criancaBaixoPaint2 = (dente, valor, cor) => {
    criancaBaixo[dente.idDente - 43].alterou = 1;
    if (cor === 1) {
      if (valor === 1) {
        criancaBaixo[dente.idDente - 43].class1 = 'marcadoVermelho';
      } else if (valor === 2) {
        criancaBaixo[dente.idDente - 43].class2 = 'marcadoVermelho';
      } else if (valor === 3) {
        criancaBaixo[dente.idDente - 43].class3 = 'marcadoVermelho';
      } else if (valor === 4) {
        criancaBaixo[dente.idDente - 43].class4 = 'marcadoVermelho';
      } else if (valor === 5) {
        criancaBaixo[dente.idDente - 43].class5 = 'marcadoVermelho';
      }
    } else if (cor === 2) {
      if (valor === 1) {
        criancaBaixo[dente.idDente - 43].class1 = 'marcadoAmarelo';
      } else if (valor === 2) {
        criancaBaixo[dente.idDente - 43].class2 = 'marcadoAmarelo';
      } else if (valor === 3) {
        criancaBaixo[dente.idDente - 43].class3 = 'marcadoAmarelo';
      } else if (valor === 4) {
        criancaBaixo[dente.idDente - 43].class4 = 'marcadoAmarelo';
      } else if (valor === 5) {
        criancaBaixo[dente.idDente - 43].class5 = 'marcadoAmarelo';
      }
    } else if (cor === 3) {
      criancaBaixo[dente.idDente - 43].class9 = 'marcadoLaranja';
    } else if (cor === 4) {
      criancaBaixo[dente.idDente - 43].class6 = 'marcadoTomate';
      criancaBaixo[dente.idDente - 43].class7 = 'marcadoTomate';
    } else if (cor === 5) {
      if (valor === 1) {
        criancaBaixo[dente.idDente - 43].class1 = 'marcadoMarrom';
      } else if (valor === 2) {
        criancaBaixo[dente.idDente - 43].class2 = 'marcadoMarrom';
      } else if (valor === 3) {
        criancaBaixo[dente.idDente - 43].class3 = 'marcadoMarrom';
      } else if (valor === 4) {
        criancaBaixo[dente.idDente - 43].class4 = 'marcadoMarrom';
      } else if (valor === 5) {
        criancaBaixo[dente.idDente - 43].class5 = 'marcadoMarrom';
      }
    } else if (cor === 6) {
      criancaBaixo[dente.idDente - 43].class10 = 'marcadoRoxo';
    } else if (cor === 7) {
      if (valor === 1) {
        criancaBaixo[dente.idDente - 43].class1 = 'marcadoVerde';
      } else if (valor === 2) {
        criancaBaixo[dente.idDente - 43].class2 = 'marcadoVerde';
      } else if (valor === 3) {
        criancaBaixo[dente.idDente - 43].class3 = 'marcadoVerde';
      } else if (valor === 4) {
        criancaBaixo[dente.idDente - 43].class4 = 'marcadoVerde';
      } else if (valor === 5) {
        criancaBaixo[dente.idDente - 43].class5 = 'marcadoVerde';
      }
    } else if (cor === 8) {
      criancaBaixo[dente.idDente - 43].class8 = 'marcadoAzul';
    } else if (cor === 9) {
      criancaBaixo[dente.idDente - 43].class1 = 'dente';
      criancaBaixo[dente.idDente - 43].class2 = 'dente';
      criancaBaixo[dente.idDente - 43].class3 = 'dente';
      criancaBaixo[dente.idDente - 43].class4 = 'dente';
      criancaBaixo[dente.idDente - 43].class5 = 'dente';
      criancaBaixo[dente.idDente - 43].class6 = 'ausente';
      criancaBaixo[dente.idDente - 43].class7 = 'ausente';
      criancaBaixo[dente.idDente - 43].class8 = 'coroa';
      criancaBaixo[dente.idDente - 43].class10 = 'implante';
      criancaBaixo[dente.idDente - 43].class9 = 'endodoncia';
      criancaBaixo[dente.idDente - 43].alterou = 0;
    } else if (cor === 11) {
      criancaBaixo[dente.idDente - 43].class9 = 'marcadoVerdeClaro';
    }
    setCriancaBaixo([...criancaBaixo]);
  };

  const paint2 = (dente, valor) => {
    if (dente.tipo === 'adultoCima') {
      adultoCimaPaint2(dente, valor, getCor(dente.estadoAtual));
    } else if (dente.tipo === 'adultoBaixo') {
      adultoBaixoPaint2(dente, valor, getCor(dente.estadoAtual));
    } else if (dente.tipo === 'criancaCima') {
      criancaCimaPaint2(dente, valor, getCor(dente.estadoAtual));
    } else if (dente.tipo === 'criancaBaixo') {
      criancaBaixoPaint2(dente, valor, getCor(dente.estadoAtual));
    } else if (dente.tipo === 'todosA') {
      for (let i = 0; i < adultoCima.length; i++) {
        adultoCima[i].class9 = 'marcadoAzulClaro';
        adultoCima[i].alterou = 1;
        adultoBaixo[i].class9 = 'marcadoAzulClaro';
        adultoBaixo[i].alterou = 1;
      }
    } else if (dente.tipo === 'todosI') {
      for (let i = 0; i < criancaCima.length; i++) {
        criancaCima[i].class9 = 'marcadoAzulClaro';
        criancaCima[i].alterou = 1;
        criancaBaixo[i].class9 = 'marcadoAzulClaro';
        criancaBaixo[i].alterou = 1;
      }
    } else if (dente.tipo === 'superiorA') {
      for (let i = 0; i < adultoCima.length; i++) {
        adultoCima[i].class9 = 'marcadoAzulClaro';
        adultoCima[i].alterou = 1;
      }
    } else if (dente.tipo === 'inferiorA') {
      for (let i = 0; i < adultoBaixo.length; i++) {
        adultoBaixo[i].class9 = 'marcadoAzulClaro';
        adultoBaixo[i].alterou = 1;
      }
    } else if (dente.tipo === 'superiorI') {
      for (let i = 0; i < criancaCima.length; i++) {
        criancaCima[i].class9 = 'marcadoAzulClaro';
        criancaCima[i].alterou = 1;
      }
    } else if (dente.tipo === 'inferiorI') {
      for (let i = 0; i < criancaBaixo.length; i++) {
        criancaBaixo[i].class9 = 'marcadoAzulClaro';
        criancaBaixo[i].alterou = 1;
      }
    }
    setAdultoCima([...adultoCima]);
    setAdultoBaixo([...adultoBaixo]);
    setCriancaCima([...criancaCima]);
    setCriancaBaixo([...criancaBaixo]);
  };

  const getCor = e => {
    if (e === 'Amálgama') {
      return 1;
    }
    if (e === 'Cáries') {
      return 2;
    }
    if (e === 'Endodontia') {
      return 3;
    }
    if (e === 'Ausente') {
      return 4;
    }
    if (e === 'Resina') {
      return 5;
    }
    if (e === 'Implante') {
      return 6;
    }
    if (e === 'Selante') {
      return 7;
    }
    if (e === 'Coroa') {
      return 8;
    } else {
      return 11;
    }
  };

  const getFace = e => {
    if (e.face === 'V') {
      return 1;
    }
    if (e.face === 'D') {
      return 2;
    }
    if (e.face === 'L/P') {
      return 3;
    }
    if (e.face === 'M') {
      return 4;
    }
    if (e.face === 'O') {
      return 5;
    }
    if (e.face === 'I') {
      return 5;
    } else {
      return 0;
    }
  };

  return (
    <>
      <Menu
        titulo={`Pacientes ► ${Orcamento.Cliente} ► Orçamentos Odontológicos ► Editar Orçamento Nº ${Orcamento.id}`}
      />
      <Box
        display="flex"
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={() => window.history.back()}
          startIcon={<NavigateBeforeIcon />}
        >
          Voltar
        </Button>
      </Box>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={selecionarAba}
          variant="fullWidth"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Odontograma" {...a11yProps(0)} />
          <Tab label="Plano de Tratamento" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <center>
          <div>
            {adultoCima.map(dente => (
              <svg key={dente.id} height="50" width="50" id={dente.id}>
                <text x="22" y="8" fontFamily="Verdana" fontSize="11">
                  {dente.numDente}
                </text>
                <polygon
                  points="10,15 15,10 50,45 45,50"
                  className={dente.class6}
                  onClick={() => paint(dente, 6)}
                />
                <polygon
                  points="45,10 50,15 15,50 10,45"
                  className={dente.class7}
                  onClick={() => paint(dente, 7)}
                />
                <circle
                  cx="30"
                  cy="30"
                  r="16"
                  className={dente.class8}
                  onClick={() => paint(dente, 8)}
                />
                <circle
                  cx="30"
                  cy="30"
                  r="20"
                  className={dente.class9}
                  onClick={() => paint(dente, 9)}
                />
                <polygon
                  points="50,10 40,10 10,26 10,32 46,32 10,50 20,50 50,36 50,28 14,28"
                  className={dente.class10}
                  onClick={() => paint(dente, 10)}
                />
                <polygon
                  points="10,10 50,10 40,20 20,20"
                  className={dente.class1}
                  onClick={() => paint(dente, 1)}
                />
                <polygon
                  points="50,10 50,50 40,40 40,20"
                  className={dente.class2}
                  onClick={() => paint(dente, 2)}
                />
                <polygon
                  points="50,50 10,50 20,40 40,40"
                  className={dente.class3}
                  onClick={() => paint(dente, 3)}
                />
                <polygon
                  points="10,50 20,40 20,20 10,10"
                  className={dente.class4}
                  onClick={() => paint(dente, 4)}
                />
                <polygon
                  points="20,20 40,20 40,40 20,40"
                  className={dente.class5}
                  onClick={() => paint(dente, 5)}
                />
              </svg>
            ))}
          </div>
          <br />
          <div>
            {criancaCima.map(dente => (
              <svg key={dente.id} height="50" width="50" id={dente.id}>
                <text x="22" y="8" fontFamily="Verdana" fontSize="11">
                  {dente.numDente}
                </text>
                <polygon
                  points="10,15 15,10 50,45 45,50"
                  className={dente.class6}
                  onClick={() => paint(dente, 6)}
                />
                <polygon
                  points="45,10 50,15 15,50 10,45"
                  className={dente.class7}
                  onClick={() => paint(dente, 7)}
                />
                <circle
                  cx="30"
                  cy="30"
                  r="16"
                  className={dente.class8}
                  onClick={() => paint(dente, 8)}
                />
                <circle
                  cx="30"
                  cy="30"
                  r="20"
                  className={dente.class9}
                  onClick={() => paint(dente, 9)}
                />
                <polygon
                  points="50,10 40,10 10,26 10,32 46,32 10,50 20,50 50,36 50,28 14,28"
                  className={dente.class10}
                  onClick={() => paint(dente, 10)}
                />
                <polygon
                  points="10,10 50,10 40,20 20,20"
                  className={dente.class1}
                  onClick={() => paint(dente, 1)}
                />
                <polygon
                  points="50,10 50,50 40,40 40,20"
                  className={dente.class2}
                  onClick={() => paint(dente, 2)}
                />
                <polygon
                  points="50,50 10,50 20,40 40,40"
                  className={dente.class3}
                  onClick={() => paint(dente, 3)}
                />
                <polygon
                  points="10,50 20,40 20,20 10,10"
                  className={dente.class4}
                  onClick={() => paint(dente, 4)}
                />
                <polygon
                  points="20,20 40,20 40,40 20,40"
                  className={dente.class5}
                  onClick={() => paint(dente, 5)}
                />
              </svg>
            ))}
          </div>
          <div>
            {criancaBaixo.map(dente => (
              <svg key={dente.id} height="50" width="50" id={dente.id}>
                <text x="22" y="8" fontFamily="Verdana" fontSize="11">
                  {dente.numDente}
                </text>
                <polygon
                  points="10,15 15,10 50,45 45,50"
                  className={dente.class6}
                  onClick={() => paint(dente, 6)}
                />
                <polygon
                  points="45,10 50,15 15,50 10,45"
                  className={dente.class7}
                  onClick={() => paint(dente, 7)}
                />
                <circle
                  cx="30"
                  cy="30"
                  r="16"
                  className={dente.class8}
                  onClick={() => paint(dente, 8)}
                />
                <circle
                  cx="30"
                  cy="30"
                  r="20"
                  className={dente.class9}
                  onClick={() => paint(dente, 9)}
                />
                <polygon
                  points="50,10 40,10 10,26 10,32 46,32 10,50 20,50 50,36 50,28 14,28"
                  className={dente.class10}
                  onClick={() => paint(dente, 10)}
                />
                <polygon
                  points="10,10 50,10 40,20 20,20"
                  className={dente.class1}
                  onClick={() => paint(dente, 1)}
                />
                <polygon
                  points="50,10 50,50 40,40 40,20"
                  className={dente.class2}
                  onClick={() => paint(dente, 2)}
                />
                <polygon
                  points="50,50 10,50 20,40 40,40"
                  className={dente.class3}
                  onClick={() => paint(dente, 3)}
                />
                <polygon
                  points="10,50 20,40 20,20 10,10"
                  className={dente.class4}
                  onClick={() => paint(dente, 4)}
                />
                <polygon
                  points="20,20 40,20 40,40 20,40"
                  className={dente.class5}
                  onClick={() => paint(dente, 5)}
                />
              </svg>
            ))}
          </div>
          <br />
          <div>
            {adultoBaixo.map(dente => (
              <svg key={dente.id} height="50" width="50" id={dente.id}>
                <text x="22" y="8" fontFamily="Verdana" fontSize="11">
                  {dente.numDente}
                </text>
                <polygon
                  points="10,15 15,10 50,45 45,50"
                  className={dente.class6}
                  onClick={() => paint(dente, 6)}
                />
                <polygon
                  points="45,10 50,15 15,50 10,45"
                  className={dente.class7}
                  onClick={() => paint(dente, 7)}
                />
                <circle
                  cx="30"
                  cy="30"
                  r="16"
                  className={dente.class8}
                  onClick={() => paint(dente, 8)}
                />
                <circle
                  cx="30"
                  cy="30"
                  r="20"
                  className={dente.class9}
                  onClick={() => paint(dente, 9)}
                />
                <polygon
                  points="50,10 40,10 10,26 10,32 46,32 10,50 20,50 50,36 50,28 14,28"
                  className={dente.class10}
                  onClick={() => paint(dente, 10)}
                />
                <polygon
                  points="10,10 50,10 40,20 20,20"
                  className={dente.class1}
                  onClick={() => paint(dente, 1)}
                />
                <polygon
                  points="50,10 50,50 40,40 40,20"
                  className={dente.class2}
                  onClick={() => paint(dente, 2)}
                />
                <polygon
                  points="50,50 10,50 20,40 40,40"
                  className={dente.class3}
                  onClick={() => paint(dente, 3)}
                />
                <polygon
                  points="10,50 20,40 20,20 10,10"
                  className={dente.class4}
                  onClick={() => paint(dente, 4)}
                />
                <polygon
                  points="20,20 40,20 40,40 20,40"
                  className={dente.class5}
                  onClick={() => paint(dente, 5)}
                />
              </svg>
            ))}
          </div>
        </center>
        <div style={{ width: '100%' }}>
          <Box display="flex" justifyContent="center">
            <Button
              onClick={() => pegarCor(1)}
              variant="contained"
              className={classes.buttonAlmagma}
            >
              Amálgama
            </Button>
            <Button
              onClick={() => pegarCor(2)}
              variant="contained"
              className={classes.buttonCaries}
            >
              Cáries
            </Button>
            <Button
              onClick={() => pegarCor(3)}
              variant="contained"
              className={classes.buttonEndodontia}
            >
              Endodontia
            </Button>
            <Button
              onClick={() => pegarCor(4)}
              variant="contained"
              className={classes.buttonAusente}
            >
              Ausente
            </Button>
            <Button
              onClick={() => pegarCor(5)}
              variant="contained"
              className={classes.buttonResina}
            >
              Resina
            </Button>
            <Button
              onClick={() => pegarCor(6)}
              variant="contained"
              className={classes.buttonImplante}
            >
              Implante
            </Button>
            <Button
              onClick={() => pegarCor(7)}
              variant="contained"
              className={classes.buttonSelante}
            >
              Selante
            </Button>
            <Button
              onClick={() => pegarCor(8)}
              variant="contained"
              className={classes.buttonCoroa}
            >
              Coroa
            </Button>
            <Button
              onClick={() => pegarCor(11)}
              variant="contained"
              className={classes.buttonOutros}
            >
              Outros
            </Button>
            <Button
              variant="contained"
              className={classes.buttonTodos}
              onClick={() => setModalTodos(true)}
            >
              Todos
            </Button>
            <Button
              onClick={() => pegarCor(9)}
              variant="contained"
              className={classes.buttonLimpar}
              color="default"
            >
              Limpar
            </Button>
          </Box>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TabelaPlanoTratamento
          idOrcamento={Orcamento.id}
          planoTratamento={planoTratamento}
          setPlanoTratamento={setPlanoTratamento}
          listaProcedimentos={procedimentos}
          abrirAviso={abrirAviso}
          tipoDesc={tipoDesc}
          setTipoDesc={setTipoDesc}
          total={total}
          setTotal={setTotal}
          totalFinal={totalFinal}
          setTotalFinal={setTotalFinal}
          desconto={desconto}
          setDesconto={setDesconto}
          ratear={ratear}
          setRatear={setRatear}
        />
      </TabPanel>
      {modalDente && (
        <InserirProcedimento
          idOrcamento={Orcamento.id}
          listaProcedimentos={procedimentos}
          planoTratamento={planoTratamento}
          setPlanoTratamento={setPlanoTratamento}
          idProcedimento={idProcedimento}
          setIdProcedimento={setIdProcedimento}
          valorProcedimento={valorProcedimento}
          modal={modalDente}
          setModal={setModalDente}
          id={id}
          setId={setId}
          dente={dente}
          setDente={setDente}
          tipo={tipo}
          setTipo={setTipo}
          face={face}
          setFace={setFace}
          estadoAtual={estadoAtual}
          setEstadoAtual={setEstadoAtual}
          abrirAviso={abrirAviso}
          cor={cor}
          pegarCor={pegarCor}
          paint={paint}
          valor={valor}
        />
      )}
      {modalTodos && (
        <InserirProcedimentosTodos
          idOrcamento={Orcamento.id}
          listaProcedimentos={procedimentos}
          planoTratamento={planoTratamento}
          setPlanoTratamento={setPlanoTratamento}
          idProcedimento={idProcedimento}
          setIdProcedimento={setIdProcedimento}
          valorProcedimento={valorProcedimento}
          modal={modalTodos}
          setModal={setModalTodos}
          tipoArcada={tipoArcada}
          setTipoArcada={setTipoArcada}
          arcadaDentaria={arcadaDentaria}
          setArcadaDentaria={setArcadaDentaria}
          estadoAtual={estadoAtual}
          setEstadoAtual={setEstadoAtual}
          abrirAviso={abrirAviso}
          adultoCima={adultoCima}
          setAdultoCima={setAdultoCima}
          adultoBaixo={adultoBaixo}
          setAdultoBaixo={setAdultoBaixo}
          criancaCima={criancaCima}
          setCriancaCima={setCriancaCima}
          criancaBaixo={criancaBaixo}
          setCriancaBaixo={setCriancaBaixo}
        />
      )}
      <Notificacao
        aviso={aviso}
        fecharAviso={() => setAviso(false)}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </>
  );
}
