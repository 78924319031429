import React, { useState, useEffect } from 'react';
import Cabecalho from '../../utils/cabecalho';
import Rodape from '../../utils/rodape';
import { formatReal, formatData } from '../../utils/funcoes';
import { CircularProgress, makeStyles } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import PrintIcon from '@material-ui/icons/Print';

const useStyles = makeStyles(theme => ({
  styleBorder: {
    border: ' solid',
    borderWidth: '1px',
    borderRadius: '4px',
    fontSize: '20px',
    padding: '4px 10px',
  },
  styleTable: {
    border: ' solid',
    borderWidth: '1px',
    borderRadius: '5px',
    width: '100%',
  },
  rowColStyle: {
    border: ' 1px solid black',
  },
  dadosOrcamento: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titulo: {
    textAlign: 'center',
    margin: '5px 5px',
    fontSize: '20px',
  },
  dadosPaciente: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '5px 5px',
  },
  assinatura: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '5px 10px',
  },
  assCol: {
    padding: '15px 25px',
    display: 'flex',
    flexDirection: 'row',
  },
  footer: {
    position: 'fixed',
    bottom: '2',
    width: '100%',
  },
  containerPrint: {
    minHeight: '100%',
    position: 'relative',
    width: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(1.5),
    right: theme.spacing(1.5),
  },
}));

export default function ImprimirOrdemServico(props) {
  const reg = JSON.parse(atob(sessionStorage.getItem('os')));
  const os = JSON.parse(reg.registros);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const imprimir = () => {
    window.document.getElementById('os-print').style.display = 'none';
    window.print();
    window.document.getElementById('os-print').style.display = 'block';
    window.close();
  };

  const carregando = async () => {
    await new Promise(r => setTimeout(() => r(), 2000));
    setLoading(false);
  };

  useEffect(() => {
    carregando();
  }, [os]);

  return (
    <>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <div className={classes.containerPrint}>
          <Cabecalho />
          <div style={{ border: '2px solid #000', width: '100%' }}></div>
          <br />
          <div className={classes.styleBorder}>
            <div className={classes.dadosOrcamento}>
              <div>
                <p>O.S. nº {os.id}</p>
              </div>
              <div>
                <p>Emitido em: {formatData(os.dataCadastro)}</p>
              </div>
              <div>
                <p>Entrega em: {formatData(os.dataEntrega)}</p>
              </div>
            </div>
          </div>
          <br />
          <h4 className={classes.titulo}>ORDEM DE SERVIÇO</h4>
          <div className={classes.styleBorder}>
            <div className={classes.dadosPaciente}>
              <div>
                <b>Dentista: Dr(a).</b> {os.dentista}
              </div>
            </div>
            <div className={classes.dadosPaciente}>
              <div>
                <b>Paciente:</b> {os.paciente}
              </div>
            </div>
          </div>
          <br />
          <h4 className={classes.titulo}>SERVIÇO A SER EXECUTADO</h4>
          <div className={classes.styleBorder} style={{ textAlign: 'justify' }}>
            {os.descricao}
            <p>Cor: {os.cor}</p>
          </div>
          <br />
          <div className={classes.styleBorder}>
            <div className={classes.dadosOrcamento}>
              <div>
                <b>TOTAL:</b>
              </div>
              <div>
                <b> {formatReal(os.valor)}</b>
              </div>
            </div>
          </div>
          <br />
          <div className={classes.styleBorder}>
            <div className={classes.assinatura}>
              <div className={classes.assCol}>
                Autorizo: _______________________________________
              </div>
            </div>
          </div>
          <div className={classes.footer}>
            <Rodape />
          </div>
          <div id="os-print">
            <Fab
              className={classes.fab}
              color="primary"
              onClick={() => imprimir()}
            >
              <PrintIcon />
            </Fab>
          </div>
        </div>
      )}
    </>
  );
}
