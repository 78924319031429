import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { getClinica } from '../../services/auth';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

export default function CadastroOrto({
  IdPaciente,
  modal,
  setOpenModal,
  setTratamentos,
  tratamentos,
  abrirAviso,
}) {
  const [dataInicial, setDataInicial] = useState(null);
  const [dataFinal, setDataFinal] = useState(null);
  const [desabilitar, setDesabilitar] = useState(false);
  const [idDentista, setIdDentista] = useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [dentistas, setDentistas] = useState([]);

  useEffect(() => {
    atualizar();
  }, [IdPaciente]);

  const atualizar = async () => {
    await api
      .post('Usuario/list_func_select.php?pdo=' + getClinica())
      .then(res => {
        setDentistas(res.data);
      })
      .catch(error => {
        console.log(error);
      });
  };
  const fecharModal = () => {
    setOpenModal(false);
  };

  const validacaoCampos = () => {
    if (dataInicial && dataFinal) {
      return true;
    }
    return false;
  };

  const salvar = async () => {
    setDesabilitar(true);
    let date = new Date();
    if (validacaoCampos()) {
      await api
        .post('Ortodontia/save.php', {
          pdo: getClinica(),
          dataCriacao: `${date.getFullYear()}-${
            date.getMonth() + 1
          }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
          idCliente: IdPaciente,
          idDentista: idDentista,
          dataInicial: dataInicial,
          dataFinal: dataFinal,
          status: 0,
        })
        .then(res => {
          setTratamentos([...tratamentos, res.data]);
          setDesabilitar(false);
          abrirAviso('success', 'Tratamento cadastrado.');
          setOpenModal(false);
        });
    } else {
      abrirAviso('warning', 'Preencha os campos obrigatórios.');
      setDesabilitar(false);
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={modal}
        onClose={fecharModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Cadastrar Tratamento Orto'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                type="date"
                label="Data Inicial (obrigatório)"
                fullWidth
                value={dataInicial}
                onChange={e => setDataInicial(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="date"
                label="Data Final (obrigatório)"
                fullWidth
                value={dataFinal}
                onChange={e => setDataFinal(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                label="Dentista"
                type="date"
                fullWidth
                value={idDentista}
                onChange={e => setIdDentista(e.target.value)}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              >
                <MenuItem value="">Selecione...</MenuItem>
                {dentistas.map(c => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.nome}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={salvar}
            variant="contained"
            color="primary"
            disabled={desabilitar}
          >
            Salvar
          </Button>
          <Button variant="contained" color="default" onClick={fecharModal}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
