import React, { useState, useEffect } from 'react';
import Menu from '../../Menu';
import api from '../../../services/api';
import { getClinica } from '../../../services/auth';
import { formatData, formatReal, formaPagamento } from '../../../utils/funcoes';
import MenuOpcoes from '../../Pacientes/menuOpcoes';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 'auto',
  },
  tableCell: {
    fontSize: '12px',
    padding: '4px 4px',
  },
  button: {
    marginTop: '65px',
    margin: theme.spacing(1),
  },
  opcoes: {
    marginBottom: '0px',
    marginTop: '60px',
    width: '100%',
  },
  buttonOpcoes: {
    marginRight: '8px',
  },
  textPesquisa: {
    margin: theme.spacing(1),
  },
  textFiltro: {
    margin: theme.spacing(1),
    width: '170px',
  },
  buttonFiltro: {
    margin: theme.spacing(1),
  },
  amarelo: {
    backgroundColor: '#FFC107',
  },
  verde: {
    backgroundColor: '#4caf50',
  },
  vermelho: {
    backgroundColor: '#FF0000',
  },
  contaVencida: {
    backgroundColor: '#f8beb7',
  },
}));

export default function FichaPaciente() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const Paciente = JSON.parse(atob(id));
  const [contas, setContas] = useState([]);
  const [buscar, setBuscar] = useState('');
  const [filtro, setFiltro] = useState('');
  const [status, setStatus] = useState('');
  const [totalReceber, setTotalReceber] = useState(0);
  const [totalRecebido, setTotalRecebido] = useState(0);
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    atualizar();
  }, []);

  const atualizar = async () => {
    let consulta = ` AND idCliente=${Paciente.id} ORDER BY status ASC`;
    listarContas(consulta);
    setLoading(false);
  };

  const filtrar = () => {
    setLoading(true);
    let filtroBusca = ` AND idCliente=${Paciente.id}`;
    if (status !== '' && status !== ' ') {
      filtroBusca += ` AND ${status}`;
    }

    if (filtro !== '') {
      filtroBusca += ` AND ${filtro}`;
    } else if (filtro === '') {
      filtroBusca += ' AND emissao';
    }

    if (dataInicio !== '' && dataFim !== '') {
      filtroBusca += ` BETWEEN '${dataInicio}' AND '${dataFim}' ORDER BY ${filtro} ASC`;
    }
    listarContas(filtroBusca);
    setLoading(false);
  };

  const listarContas = consulta => {
    setContas([]);
    setPage(0);
    api
      .post('Receber/search.php', { pdo: getClinica(), consulta: consulta })
      .then(res => {
        if (res.data.length > 0) {
          setContas(res.data);
          totalAReceber(res.data);
        }
      })
      .catch(error => console.log(error));
  };

  const ehContaVencida = (situacao, e) => {
    let vencimento = e.split('-');
    let data = new Date(vencimento[0], vencimento[1] - 1, vencimento[2]);
    let hoje = new Date();
    if (data < hoje && situacao === '0') {
      return classes.contaVencida;
    }
  };

  const totalAReceber = contas => {
    let totalRecebido = contas
      .filter(c => c.status === '1')
      .reduce((sum, li) => sum + Number(li.valor), 0);
    let totalReceber = contas
      .filter(c => c.status === '0')
      .reduce((sum, li) => sum + Number(li.valor), 0);

    setTotalReceber(totalReceber);
    setTotalRecebido(totalRecebido);
  };

  const situacaoConta = valor => {
    if (valor === '0') {
      return (
        <Avatar title="Conta Aberta" className={classes.amarelo}>
          AB
        </Avatar>
      );
    }
    if (valor === '1') {
      return (
        <Avatar title="Conta Paga" className={classes.verde}>
          PG
        </Avatar>
      );
    } else {
      return (
        <Avatar title="Conta Cancelada" className={classes.vermelho}>
          CA
        </Avatar>
      );
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <Menu titulo={`Pacientes ► ${Paciente.nome} ► Ficha Financeira`} />
      <div className={classes.opcoes}>
        <Box display="flex" p={1} bgcolor="background.paper">
          <Box p={2} width="100%">
            <b>Total a receber:{formatReal(totalReceber)}</b>
          </Box>
          <Box p={2} width="100%">
            <b>Total recebido:{formatReal(totalRecebido)}</b>
          </Box>
          <Box p={1} flexShrink={0}>
            <Button
              variant="contained"
              color="default"
              onClick={() => window.location.reload()}
              startIcon={<RefreshIcon />}
            >
              Atualizar
            </Button>
          </Box>
          <Box p={1} flexShrink={0}>
            <MenuOpcoes paciente={Paciente} />
          </Box>
        </Box>
      </div>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <Paper className={classes.root}>
          <TextField
            className={classes.textPesquisa}
            value={buscar}
            onChange={e => setBuscar(e.target.value)}
            size="small"
            label="Pesquisar"
            variant="outlined"
          />
          <TextField
            select
            className={classes.textFiltro}
            size="small"
            label="Status"
            value={status}
            onChange={e => setStatus(e.target.value)}
            variant="outlined"
          >
            <MenuItem value=" ">Todas</MenuItem>
            <MenuItem value="status=0">Aberto</MenuItem>
            <MenuItem value="status=1">Pago</MenuItem>
            <MenuItem value="status=-1">Cancelado</MenuItem>
          </TextField>
          <TextField
            select
            className={classes.textFiltro}
            size="small"
            label="Filtro por"
            value={filtro}
            onChange={e => setFiltro(e.target.value)}
            variant="outlined"
          >
            <MenuItem value="">Selecione...</MenuItem>
            <MenuItem value="emissao">Emissão</MenuItem>
            <MenuItem value="vencimento">Vencimento</MenuItem>
            <MenuItem value="pagoDia">Pagamento</MenuItem>
          </TextField>
          <TextField
            type="date"
            size="small"
            className={classes.textFiltro}
            label="Periodo Inicial"
            value={dataInicio}
            onChange={e => setDataInicio(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            type="date"
            size="small"
            className={classes.textFiltro}
            label="Periodo Final"
            value={dataFim}
            onChange={e => setDataFim(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <Button
            className={classes.buttonFiltro}
            onClick={filtrar}
            variant="contained"
            color="secondary"
          >
            <SearchIcon />
          </Button>
          <TableContainer className={classes.container}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Emissao</b>
                  </TableCell>
                  <TableCell>
                    <b>Vencimento</b>
                  </TableCell>
                  <TableCell>
                    <b>Valor</b>
                  </TableCell>
                  <TableCell>
                    <b>Nº Doc.</b>
                  </TableCell>
                  <TableCell>
                    <b>Nº Trat.</b>
                  </TableCell>
                  <TableCell>
                    <b>Status</b>
                  </TableCell>
                  <TableCell>
                    <b>Forma Pgto</b>
                  </TableCell>
                  <TableCell>
                    <b>Data Pgto</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contas
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(conta => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={conta.id}
                        className={ehContaVencida(
                          conta.status,
                          conta.vencimento,
                        )}
                      >
                        <TableCell>{conta.id}</TableCell>
                        <TableCell>{formatData(conta.emissao)}</TableCell>
                        <TableCell>{formatData(conta.vencimento)}</TableCell>
                        <TableCell>{formatReal(conta.valor)}</TableCell>
                        <TableCell>{conta.nDoc}</TableCell>
                        <TableCell>{conta.idTratamento}</TableCell>
                        <TableCell>{situacaoConta(conta.status)}</TableCell>
                        <TableCell>{formaPagamento(conta.formaPag)}</TableCell>
                        <TableCell>{formatData(conta.pagoDia)}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={contas.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por pagina"
          />
        </Paper>
      )}
    </div>
  );
}
