import React, { useState, useEffect } from 'react';
import Menu from '../Menu';
import api from '../../services/api';
import { getClinica } from '../../services/auth';
import { formatData } from '../../utils/funcoes';
import { makeStyles } from '@material-ui/core/styles';
import MenuOpcoes from '../Pacientes/menuOpcoes';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    marginTop: '60px',
    margin: theme.spacing(1),
  },
  menuOpcoes: {
    marginTop: theme.spacing(7.5),
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography
          variant="body2"
          color="textPrimary"
        >{`${props.value}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function TratamentosOdontologicos(props) {
  const Paciente = JSON.parse(atob(props.match.params.id));
  const [loading, setLoading] = useState(true);
  const [tratamentos, setTratamentos] = useState([]);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    atualizar();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const abrirTratamento = tratamento => {
    const dados = {
      tratamento: tratamento,
      paciente: Paciente,
    };
    const cod = JSON.stringify(dados);
    sessionStorage.setItem('dados', btoa(cod));
    window.location.pathname = `/tratamento_odonto`;
  };

  const atualizar = async () => {
    await api
      .post('Tratamento/search.php', {
        pdo: getClinica(),
        consulta:
          'AND t.status > 0 AND t.status < 3 AND t.idCliente=' + Paciente.id,
      })
      .then(res => {
        res.data.length > 0 ? setTratamentos(res.data) : setTratamentos([]);
      });
    setLoading(false);
  };

  return (
    <>
      <Menu
        titulo={`Pacientes ► ${Paciente.nome} ► Tratamentos Odontológicos`}
      />
      <Box
        display="flex"
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={() => (window.location.pathname = '/pacientes')}
          startIcon={<NavigateBeforeIcon />}
        >
          Voltar
        </Button>
        <div className={classes.menuOpcoes}>
          <MenuOpcoes paciente={Paciente} />
        </div>
      </Box>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Nº Tratamento</b>
                  </TableCell>
                  <TableCell>
                    <b>Data Cadastro</b>
                  </TableCell>
                  <TableCell>
                    <b>Dentista</b>
                  </TableCell>
                  <TableCell>
                    <b>Progresso</b>
                  </TableCell>
                  <TableCell>
                    <b>Opção</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tratamentos
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(tratamento => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={tratamento.id}
                      >
                        <TableCell>{tratamento.id}</TableCell>
                        <TableCell>
                          {formatData(tratamento.dataCriacao)}
                        </TableCell>
                        <TableCell>{tratamento.Dentista}</TableCell>
                        <TableCell>
                          {tratamento.progresso ? (
                            <LinearProgressWithLabel
                              value={parseFloat(tratamento.progresso)}
                            />
                          ) : (
                            <LinearProgressWithLabel value={0} />
                          )}
                        </TableCell>
                        <TableCell>
                          <div>
                            <Button
                              startIcon={<OpenInNewIcon />}
                              variant="contained"
                              color="primary"
                              onClick={() => abrirTratamento(tratamento)}
                            >
                              Abrir
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={tratamentos.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por pagina"
          />
        </Paper>
      )}
    </>
  );
}
