import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import Menu from '../Menu';
import { getClinica } from '../../services/auth';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { mask, unMask } from 'remask';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import RoomIcon from '@material-ui/icons/Room';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import FormControl from '@material-ui/core/FormControl';
import { Avatar, Checkbox, FormControlLabel } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockIcon from '@material-ui/icons/Lock';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import PersonIcon from '@material-ui/icons/Person';
import Notificacao from '../../utils/notificacao';
import { useConfig } from '../../hooks/config';
import { usePermissoes } from '../../hooks/permissoes';
import avatarDefault from '../../assets/avatar.png';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    marginTop: '60px',
    margin: theme.spacing(1),
  },
  titulos: {
    margin: '8px 0px',
  },
  paper: {
    padding: theme.spacing(1),
    marginTop: '8px',
  },
  large: {
    border: '6px solid',
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  input: {
    display: 'none',
  },
}));

export default function CadastarEditarFuncionario() {
  const Funcionario = JSON.parse(atob(sessionStorage.getItem('funcionario')));

  const [loading, setLoading] = useState(true);
  const [desabilitar, setDesabilitar] = useState(false);
  const [aviso, setAviso] = useState(false);
  const [tipoAviso, setTipoAviso] = useState('');
  const [mensagemAviso, setMensagemAviso] = useState('');
  const { configuracoes } = useConfig();
  const { permissoes } = usePermissoes();
  const [idFunc, setIdFunc] = useState('');
  const [cpf, setCpf] = useState('');
  const [rg, setRg] = useState('');
  const [nome, setNome] = useState('');
  const [dataNasc, setDataNasc] = useState('');
  const [sexo, setSexo] = useState('');
  const [naturalidade, setNaturalidade] = useState('');
  const [nacionalidade, setNacionalidade] = useState('');
  const [estadoCivil, setEstadoCivil] = useState('');
  const [profissao, setProfissao] = useState('');
  const [telefone, setTelefone] = useState('');
  const [login, setLogin] = useState('');
  const [vLogin, setVLogin] = useState('');
  const [senha, setSenha] = useState('');
  const [tipoConta, setTipoConta] = useState('1');
  const [vTipoConta, setVTipoConta] = useState('');
  const [cro, setCro] = useState('');
  const [crm, setCrm] = useState('');
  const [rqe, setRqe] = useState('');
  const [comissao, setComissao] = useState(0);
  const [celular, setCelular] = useState('');
  const [conv, setConv] = useState('');
  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [complemento, setComplemento] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [cep2, setCep2] = useState('');
  const [logradouro2, setLogradouro2] = useState('');
  const [numero2, setNumero2] = useState('');
  const [bairro2, setBairro2] = useState('');
  const [complemento2, setComplemento2] = useState('');
  const [cidade2, setCidade2] = useState('');
  const [estado2, setEstado2] = useState('');
  const [modalDesativarResetar, setModalDesativarResetar] = useState(false);
  const [execFuncao, setExecFuncao] = useState(0);

  //permissoes
  const [agenda, setAgenda] = useState(true);
  const [agenCad, setAgenCad] = useState(false);
  const [agenAlt, setAgenAlt] = useState(false);
  const [agenDel, setAgenDel] = useState(false);
  const [paciente, setPaciente] = useState(false);
  const [pacCad, setPacCad] = useState(false);
  const [pacAlt, setPacAlt] = useState(false);
  const [pacDes, setPacDes] = useState(false);
  const [pacAtes, setPacAtes] = useState(false);
  const [pacRec, setPacRec] = useState(false);
  const [funcionario, setFuncionario] = useState(false);
  const [funcCad, setFuncCad] = useState(false);
  const [funcAlt, setFuncAlt] = useState(false);
  const [funcDes, setFuncDes] = useState(false);
  const [orcamento, setOrcamento] = useState(false);
  const [orcCad, setOrcCad] = useState(false);
  const [orcAlt, setOrcAlt] = useState(false);
  const [orcDesabilitarValores, setOrcDesabilitarValores] = useState(false);
  const [procedimento, setProcedimento] = useState(false);
  const [procCad, setProcCad] = useState(false);
  const [procAlt, setProcAlt] = useState(false);
  const [financeiro, setFinanceiro] = useState(false);
  const [finCad, setFinCad] = useState(false);
  const [finAlt, setFinAlt] = useState(false);
  const [finDes, setFinDes] = useState(false);
  const [centroCusto, setCentroCusto] = useState(false);
  const [centroCad, setCentroCad] = useState(false);
  const [centroAlt, setCentroAlt] = useState(false);
  const [estoque, setEstoque] = useState(false);
  const [estCad, setEstCad] = useState(false);
  const [estAlt, setEstAlt] = useState(false);
  const [convenio, setConvenio] = useState(false);
  const [convCad, setConvCad] = useState(false);
  const [convAlt, setConvAlt] = useState(false);
  const [relatorios, setRelatorios] = useState(false);
  const [empresa, setEmpresa] = useState(false);
  const [empAlt, setEmpAlt] = useState(false);
  const [anexoCad, setAnexoCad] = useState(false);
  const [anexoDel, setAnexoDel] = useState(false);
  const [ordemServico, setOrdemServico] = useState(false);
  const [osCad, setOsCad] = useState(false);
  const [osAlt, setOsAlt] = useState(false);
  const [idPermissao, setIdPermissao] = useState('');
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [value, setValue] = useState(0);
  const [foto, setFoto] = useState(null);
  const [avatar, setAvatar] = useState(null);

  const abrirAviso = (tipo, mensagem) => {
    setTipoAviso(tipo);
    setMensagemAviso(mensagem);
    setAviso(true);
  };

  const fecharAviso = () => {
    setAviso(false);
  };

  const selecionarAba = (event, newValue) => {
    setValue(newValue);
  };

  const buscarPermissoes = async () => {
    if (Funcionario !== null) {
      await api
        .get(
          'Permissoes/find.php?id=' + Funcionario.id + '&pdo=' + getClinica(),
        )
        .then(res => {
          if (res.data !== false) {
            setAgenda(Boolean(parseInt(res.data.agenda)));
            setAgenCad(Boolean(parseInt(res.data.agenCad)));
            setAgenAlt(Boolean(parseInt(res.data.agenAlt)));
            setAgenDel(Boolean(parseInt(res.data.agenDel)));
            setPaciente(Boolean(parseInt(res.data.paciente)));
            setPacCad(Boolean(parseInt(res.data.pacCad)));
            setPacAlt(Boolean(parseInt(res.data.pacAlt)));
            setPacDes(Boolean(parseInt(res.data.pacDes)));
            setPacAtes(Boolean(parseInt(res.data.pacAtes)));
            setPacRec(Boolean(parseInt(res.data.pacRec)));
            setFuncionario(Boolean(parseInt(res.data.funcionario)));
            setFuncCad(Boolean(parseInt(res.data.funcCad)));
            setFuncAlt(Boolean(parseInt(res.data.funcAlt)));
            setFuncDes(Boolean(parseInt(res.data.funcDes)));
            setOrcamento(Boolean(parseInt(res.data.orcamento)));
            setOrcCad(Boolean(parseInt(res.data.orcCad)));
            setOrcAlt(Boolean(parseInt(res.data.orcAlt)));
            setOrcDesabilitarValores(
              Boolean(parseInt(res.data.orcDesabilitarValores)),
            );
            setProcedimento(Boolean(parseInt(res.data.procedimento)));
            setProcCad(Boolean(parseInt(res.data.procCad)));
            setProcAlt(Boolean(parseInt(res.data.procAlt)));
            setFinanceiro(Boolean(parseInt(res.data.financeiro)));
            setFinCad(Boolean(parseInt(res.data.finCad)));
            setFinAlt(Boolean(parseInt(res.data.finAlt)));
            setFinDes(Boolean(parseInt(res.data.finDes)));
            setCentroCusto(Boolean(parseInt(res.data.centroCusto)));
            setCentroCad(Boolean(parseInt(res.data.centroCad)));
            setCentroAlt(Boolean(parseInt(res.data.centroAlt)));
            setEstoque(Boolean(parseInt(res.data.estoque)));
            setEstCad(Boolean(parseInt(res.data.estCad)));
            setEstAlt(Boolean(parseInt(res.data.estAlt)));
            setConvenio(Boolean(parseInt(res.data.convenio)));
            setConvCad(Boolean(parseInt(res.data.convCad)));
            setConvAlt(Boolean(parseInt(res.data.convAlt)));
            setRelatorios(Boolean(parseInt(res.data.relatorios)));
            setEmpresa(Boolean(parseInt(res.data.empresa)));
            setEmpAlt(Boolean(parseInt(res.data.empAlt)));
            setAnexoCad(Boolean(parseInt(res.data.anexoCad)));
            setAnexoDel(Boolean(parseInt(res.data.anexoDel)));
            setOrdemServico(Boolean(parseInt(res.data.ordemServico)));
            setOsCad(Boolean(parseInt(res.data.osCad)));
            setOsAlt(Boolean(parseInt(res.data.osAlt)));
            setIdPermissao(res.data.id);
          } else {
            setIdPermissao(false);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const consultarCepR = () => {
    axios
      .get('https://api.postmon.com.br/v1/cep/' + cep)
      .then(res => {
        setCep(res.data.cep);
        setLogradouro(res.data.logradouro);
        setBairro(res.data.bairro);
        setCidade(res.data.cidade);
        setEstado(res.data.estado);
      })
      .catch(() => abrirAviso('error', 'CEP não encontrado'));
  };

  const validarCampos = () => {
    if (
      cpf !== '' &&
      rg !== '' &&
      nome !== '' &&
      sexo !== '' &&
      dataNasc !== '' &&
      login !== '' &&
      profissao !== ''
    ) {
      return true;
    }
    return false;
  };

  const consultarCepP = () => {
    axios
      .get('https://api.postmon.com.br/v1/cep/' + cep2)
      .then(res => {
        setCep2(res.data.cep);
        setLogradouro2(res.data.logradouro);
        setBairro2(res.data.bairro);
        setCidade2(res.data.cidade);
        setEstado2(res.data.estado);
      })
      .catch(() => abrirAviso('error', 'CEP não encontrado'));
  };

  const atualizar = async () => {
    if (Funcionario !== null) {
      encontrarFuncionario(Funcionario);
    }
    setLoading(false);
  };

  const encontrarFuncionario = async funcionario => {
    setIdFunc(funcionario.id);
    setCpf(funcionario.cpf);
    setRg(funcionario.rg);
    setNome(funcionario.nome);
    setDataNasc(funcionario.dataNasc);
    setSexo(funcionario.sexo);
    setNaturalidade(funcionario.naturalidade);
    setNacionalidade(funcionario.nacionalidade);
    setEstadoCivil(funcionario.estadoCivil);
    setProfissao(funcionario.profissao);
    setTelefone(funcionario.telefone);
    setCelular(funcionario.celular);
    setLogin(funcionario.login);
    setVLogin(funcionario.login);
    setCro(funcionario.cro);
    setCrm(funcionario.crm);
    setRqe(funcionario.rqe);
    setSenha(funcionario.senha);
    setTipoConta(funcionario.tipo);
    setVTipoConta(funcionario.tipo);
    setCep(funcionario.endCepR);
    setLogradouro(funcionario.endLograR);
    setNumero(funcionario.endNumR);
    setBairro(funcionario.endBairroR);
    setCidade(funcionario.endCidadeR);
    setEstado(funcionario.endEstadoR);
    setComplemento(funcionario.endCompleR);
    setCep2(funcionario.endCepP);
    setLogradouro2(funcionario.endLograP);
    setNumero2(funcionario.endNumP);
    setBairro2(funcionario.endBairroP);
    setCidade2(funcionario.endCidadeP);
    setEstado2(funcionario.endEstadoP);
    setComplemento2(funcionario.endCompleP);
    setComissao(funcionario.comissao);
    setAvatar(funcionario.foto);
  };

  const limparCampos = () => {
    setIdFunc('');
    setCpf('');
    setRg('');
    setNome('');
    setDataNasc('');
    setSexo('');
    setNaturalidade('');
    setNacionalidade('');
    setEstadoCivil('');
    setProfissao('');
    setTelefone('');
    setCelular('');
    setLogin('');

    setCep('');
    setLogradouro('');
    setNumero('');
    setBairro('');
    setComplemento('');
    setCidade('');
    setEstado('');
    setCep2('');
    setLogradouro2('');
    setNumero2('');
    setBairro2('');
    setComplemento2('');
    setCidade2('');
    setEstado2('');
    setComissao(0);
  };

  const salvar = () => {
    Funcionario !== null ? editar() : cadastrar();
  };

  const cadastrar = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.funcCad !== '0' && permissoes !== false) {
        if (validarCampos()) {
          let existeCPF = await verificarCpf(cpf);
          if (!existeCPF) {
          } else {
            abrirAviso('warning', 'CPF já cadastrado.');
            setDesabilitar(false);
            return;
          }

          const fd = new FormData();
          fd.append('pdo', getClinica());
          fd.append('cpf', cpf);
          fd.append('rg', rg);
          fd.append('nome', nome);
          fd.append('dataNasc', dataNasc);
          fd.append('sexo', sexo);
          fd.append('naturalidade', naturalidade);
          fd.append('nacionalidade', nacionalidade);
          fd.append('estadoCivil', estadoCivil);
          fd.append('profissao', profissao);
          fd.append('telefone', telefone);
          fd.append('celular', celular);
          fd.append('instagram', '');
          fd.append('convenio', convenio);
          fd.append('nCartao', '');
          fd.append('login', login);
          fd.append('responsavel', '');
          fd.append('respCpf', '');
          fd.append('respRg', 0);
          fd.append('ativo', 0);
          fd.append('senha', '123456');
          fd.append('tipo', tipoConta);
          fd.append('cro', cro);
          fd.append('crm', crm);
          fd.append('rqe', rqe);
          fd.append('endCepR', cep);
          fd.append('endLograR', logradouro);
          fd.append('endNumR', numero);
          fd.append('endBairroR', bairro);
          fd.append('endCidadeR', cidade);
          fd.append('endEstadoR', estado);
          fd.append('endCompleR', complemento);
          fd.append('endCepP', cep2);
          fd.append('endLograP', logradouro2);
          fd.append('endNumP', numero2);
          fd.append('endBairroP', bairro2);
          fd.append('endCidadeP', cidade2);
          fd.append('endEstadoP', estado2);
          fd.append('endCompleP', complemento2);
          fd.append('comissao', 0);
          fd.append('foto', foto);
          await api.post('Usuario/save.php', fd).then(res => {
            if (res.data.Cod === 0) {
              api
                .post('Permissoes/save.php', {
                  pdo: getClinica(),
                  idUser: res.data.Data.id,
                  agenda: Number(agenda),
                  agenCad: Number(agenCad),
                  agenAlt: Number(agenAlt),
                  agenDel: Number(agenDel),
                  paciente: Number(paciente),
                  pacCad: Number(pacCad),
                  pacAlt: Number(pacAlt),
                  pacDes: Number(pacDes),
                  pacAtes: Number(pacAtes),
                  pacRec: Number(pacRec),
                  funcionario: Number(funcionario),
                  funcCad: Number(funcCad),
                  funcAlt: Number(funcAlt),
                  funcDes: Number(funcDes),
                  orcamento: Number(orcamento),
                  orcCad: Number(orcCad),
                  orcAlt: Number(orcAlt),
                  orcDesabilitarValores: Number(orcDesabilitarValores),
                  procedimento: Number(procedimento),
                  procCad: Number(procCad),
                  procAlt: Number(procAlt),
                  financeiro: Number(financeiro),
                  finCad: Number(finCad),
                  finAlt: Number(finAlt),
                  finDes: Number(finDes),
                  centroCusto: Number(centroCusto),
                  centroCad: Number(centroCad),
                  centroAlt: Number(centroAlt),
                  estoque: Number(estoque),
                  estCad: Number(estCad),
                  estAlt: Number(estAlt),
                  convenio: Number(convenio),
                  convCad: Number(convCad),
                  convAlt: Number(convAlt),
                  relatorios: Number(relatorios),
                  empresa: Number(empresa),
                  empAlt: Number(empAlt),
                  anexoCad: Number(anexoCad),
                  anexoDel: Number(anexoDel),
                  ordemServico: Number(ordemServico),
                  osCad: Number(osCad),
                  osAlt: Number(osAlt),
                })
                .then(() => {
                  abrirAviso('success', 'Funcionário cadastrado.');
                })
                .catch(error => {
                  console.log(error);
                  abrirAviso('error', 'Erro ao cadastrar funcionario.');
                });
              limparCampos();
            } else {
              abrirAviso('error', res.data.Data);
            }
          });
        } else {
          abrirAviso('warning', 'Preencha todos os campos obrigatórios.');
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
    }
    setDesabilitar(false);
  };

  const editar = async () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.funcAlt !== '0' && permissoes !== false) {
        if (vLogin !== login) {
          let existeLogin = await verificarLogin(login);
          if (!existeLogin) {
            await api
              .post('Usuario/updateLogin.php', {
                pdo: getClinica(),
                login: login,
                id: idFunc,
              })
              .catch(error => {
                console.log(error);
              });
            setVLogin(login);
          } else {
            abrirAviso('warning', 'Login já existe.');
            setLogin(vLogin);
            setDesabilitar(false);
            return 'Já existe';
          }
        }
        if (tipoConta !== vTipoConta) {
          await api
            .post('Usuario/updateTipo.php', {
              pdo: getClinica(),
              tipo: tipoConta,
              id: idFunc,
            })
            .catch(error => {
              console.log(error);
            });
        }
        const fd = new FormData();
        fd.append('pdo', getClinica());
        fd.append('cpf', cpf);
        fd.append('rg', rg);
        fd.append('nome', nome);
        fd.append('dataNasc', dataNasc);
        fd.append('sexo', sexo);
        fd.append('naturalidade', naturalidade);
        fd.append('nacionalidade', nacionalidade);
        fd.append('estadoCivil', estadoCivil);
        fd.append('profissao', profissao);
        fd.append('telefone', telefone);
        fd.append('celular', celular);
        fd.append('instagram', '');
        fd.append('convenio', '');
        fd.append('nCartao', '');
        fd.append('responsavel', '');
        fd.append('respCpf', '');
        fd.append('respRg', 0);
        fd.append('login', login);
        fd.append('cro', cro);
        fd.append('crm', crm);
        fd.append('rqe', rqe);
        fd.append('endCepR', cep);
        fd.append('endLograR', logradouro);
        fd.append('endNumR', numero);
        fd.append('endBairroR', bairro);
        fd.append('endCidadeR', cidade);
        fd.append('endEstadoR', estado);
        fd.append('endCompleR', complemento);
        fd.append('endCepP', cep2);
        fd.append('endLograP', logradouro2);
        fd.append('endNumP', numero2);
        fd.append('endBairroP', bairro2);
        fd.append('endCidadeP', cidade2);
        fd.append('endEstadoP', estado2);
        fd.append('endCompleP', complemento2);
        fd.append('comissao', comissao ? comissao : 0);
        fd.append('foto', foto);
        fd.append('id', idFunc);

        await api
          .post('Usuario/update.php', fd)
          .then(() => {})
          .catch(error =>
            console.log('Erro ao editar funcionário. Erro: ' + error),
          );

        if (idPermissao !== false) {
          await api
            .post('Permissoes/update.php', {
              pdo: getClinica(),
              agenda: Number(agenda),
              agenCad: Number(agenCad),
              agenAlt: Number(agenAlt),
              agenDel: Number(agenDel),
              paciente: Number(paciente),
              pacCad: Number(pacCad),
              pacAlt: Number(pacAlt),
              pacDes: Number(pacDes),
              pacAtes: Number(pacAtes),
              pacRec: Number(pacRec),
              funcionario: Number(funcionario),
              funcCad: Number(funcCad),
              funcAlt: Number(funcAlt),
              funcDes: Number(funcDes),
              orcamento: Number(orcamento),
              orcCad: Number(orcCad),
              orcAlt: Number(orcAlt),
              orcDesabilitarValores: Number(orcDesabilitarValores),
              procedimento: Number(procedimento),
              procCad: Number(procCad),
              procAlt: Number(procAlt),
              financeiro: Number(financeiro),
              finCad: Number(finCad),
              finAlt: Number(finAlt),
              finDes: Number(finDes),
              centroCusto: Number(centroCusto),
              centroCad: Number(centroCad),
              centroAlt: Number(centroAlt),
              estoque: Number(estoque),
              estCad: Number(estCad),
              estAlt: Number(estAlt),
              convenio: Number(convenio),
              convCad: Number(convCad),
              convAlt: Number(convAlt),
              relatorios: Number(relatorios),
              empresa: Number(empresa),
              empAlt: Number(empAlt),
              anexoCad: Number(anexoCad),
              anexoDel: Number(anexoDel),
              ordemServico: Number(ordemServico),
              osCad: Number(osCad),
              osAlt: Number(osAlt),
              idUser: idFunc,
            })
            .then(() => {
              abrirAviso('success', 'Salvo.');
              setDesabilitar(false);
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          await api
            .post('Permissoes/save.php', {
              pdo: getClinica(),
              idUser: idFunc,
              agenda: Number(agenda),
              agenCad: Number(agenCad),
              agenAlt: Number(agenAlt),
              agenDel: Number(agenDel),
              paciente: Number(paciente),
              pacCad: Number(pacCad),
              pacAlt: Number(pacAlt),
              pacDes: Number(pacDes),
              pacAtes: Number(pacAtes),
              pacRec: Number(pacRec),
              funcionario: Number(funcionario),
              funcCad: Number(funcCad),
              funcAlt: Number(funcAlt),
              funcDes: Number(funcDes),
              orcamento: Number(orcamento),
              orcCad: Number(orcCad),
              orcAlt: Number(orcAlt),
              orcDesabilitarValores: Number(orcDesabilitarValores),
              procedimento: Number(procedimento),
              procCad: Number(procCad),
              procAlt: Number(procAlt),
              financeiro: Number(financeiro),
              finCad: Number(finCad),
              finAlt: Number(finAlt),
              finDes: Number(finDes),
              centroCusto: Number(centroCusto),
              centroCad: Number(centroCad),
              centroAlt: Number(centroAlt),
              estoque: Number(estoque),
              estCad: Number(estCad),
              estAlt: Number(estAlt),
              convenio: Number(convenio),
              convCad: Number(convCad),
              convAlt: Number(convAlt),
              relatorios: Number(relatorios),
              empresa: Number(empresa),
              empAlt: Number(empAlt),
              anexoCad: Number(anexoCad),
              anexoDel: Number(anexoDel),
              ordemServico: Number(ordemServico),
              osCad: Number(osCad),
              osAlt: Number(osAlt),
            })
            .then(() => {
              abrirAviso('success', 'Salvo.');
            })
            .catch(error => console.log(error));
        }
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
        setDesabilitar(false);
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
      setDesabilitar(false);
    }
  };

  const verificarCpf = async cpf => {
    let ret = false;
    await api
      .get('Usuario/verificarCpf.php?pdo=' + getClinica() + '&cpf=' + cpf)
      .then(res => {
        ret = res.data;
      });
    return ret;
  };

  const verificarLogin = async login => {
    let ret = false;
    await api
      .get('Usuario/verificarLogin.php?pdo=' + getClinica() + '&login=' + login)
      .then(res => {
        ret = res.data;
      });
    return ret;
  };

  const resetar = () => {
    if (permissoes.funcAlt !== '0') {
      api
        .post('Usuario/resetSenha.php', {
          pdo: getClinica(),
          senha: '123',
          id: idFunc,
        })
        .then(() => {
          abrirAviso('success', 'Senha resetada.');
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
    }
  };

  const ativar = () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.funcDes !== '0' && permissoes !== false) {
        api
          .post('Usuario/disable_user.php', {
            pdo: getClinica(),
            ativo: 0,
            id: idFunc,
          })
          .then(() => {
            abrirAviso('success', 'Funcionário ativado.');
            setDesabilitar(false);
            setTimeout(function () {
              window.location.pathname = '/funcionarios';
            }, 1100);
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
        setDesabilitar(false);
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
      setDesabilitar(false);
    }
  };

  const desativar = () => {
    setDesabilitar(true);
    if (configuracoes.bloqueio !== '1') {
      if (permissoes.funcDes !== '0' && permissoes !== false) {
        api
          .post('Usuario/disable_user.php', {
            pdo: getClinica(),
            ativo: -1,
            id: idFunc,
          })
          .then(() => {
            abrirAviso('success', 'Funcionário desativado.');
            setTimeout(function () {
              window.location.pathname = '/funcionarios';
            }, 1100);
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        abrirAviso('error', 'Você não tem permissão para realizar essa ação.');
        setDesabilitar(false);
      }
    } else {
      abrirAviso(
        'error',
        'Sistema disponível apenas para consulta. Entre em contato com o suporte.',
      );
      setDesabilitar(false);
    }
  };

  const executarFuncao = valor => {
    setExecFuncao(valor);
    setModalDesativarResetar(true);
  };

  const desativarResetarConta = () => {
    if (execFuncao === 1) {
      desativar();
    }
    if (execFuncao === 2) {
      resetar();
    }
    if (execFuncao === 3) {
      ativar();
    }
    setExecFuncao(0);
    setModalDesativarResetar(false);
  };

  const onChangeFoto = event => {
    setFoto(event.target.files[0]);
  };

  useEffect(() => {
    buscarPermissoes();
    atualizar();
  }, []);

  return (
    <div>
      <Menu
        titulo={
          Funcionario !== null
            ? `Funcionários ► Editar ► ${Funcionario.nome}`
            : 'Funcionários ► Cadastro'
        }
      />
      <Box
        display="flex"
        justifyContent="flex-end"
        m={1}
        p={1}
        bgcolor="background.paper"
      >
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={() => (window.location.pathname = '/funcionarios')}
          startIcon={<NavigateBeforeIcon />}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={salvar}
          startIcon={<SaveIcon />}
          disabled={desabilitar}
        >
          Salvar
        </Button>
        {Funcionario !== null && Funcionario.ativo === '0' && (
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => executarFuncao(1)}
            startIcon={<PersonAddDisabledIcon />}
          >
            Desativar
          </Button>
        )}
        {Funcionario !== null && Funcionario.ativo === '0' && (
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            onClick={() => executarFuncao(2)}
            startIcon={<VpnKeyIcon />}
          >
            Resetar Senha
          </Button>
        )}
        {Funcionario !== null && Funcionario.ativo === '-1' && (
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            onClick={() => executarFuncao(3)}
            startIcon={<PersonIcon />}
          >
            Ativar
          </Button>
        )}
      </Box>
      {loading && (
        <CircularProgress
          style={{
            marginTop: '250px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      )}
      {!loading && (
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={selecionarAba}
              variant="fullWidth"
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                label="Dados Cadastrais"
                icon={<PersonPinIcon />}
                {...a11yProps(0)}
              />
              <Tab label="Endereços" icon={<RoomIcon />} {...a11yProps(1)} />
              <Tab
                label="Conta de Usuário"
                icon={<LockIcon />}
                {...a11yProps(2)}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Box
              display="flex"
              justifyContent="center"
              m={1}
              p={1}
              bgcolor="background.paper"
            >
              <Avatar
                alt="Avatar"
                src={avatar ? `https://sigecli.com.br${avatar}` : avatarDefault}
                className={classes.large}
                style={{ borderColor: foto ? '#4caf50' : '#DDD' }}
              />
              <input
                accept="image/png,image/jpeg"
                className={classes.input}
                id="icon-button-file"
                onChange={onChangeFoto}
                type="file"
              />
              <label htmlFor="icon-button-file">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <PhotoCamera />
                </IconButton>
              </label>
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  label="CPF (obrigatório)"
                  fullWidth
                  value={cpf}
                  onChange={e =>
                    setCpf(mask(unMask(e.target.value), ['99999999999']))
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Rg (obrigatório)"
                  fullWidth
                  value={rg}
                  onChange={e => setRg(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  label="Nome (obrigatório)"
                  fullWidth
                  value={nome}
                  onChange={e => setNome(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Nascimento (obrigatório)"
                  type="date"
                  fullWidth
                  value={dataNasc}
                  onChange={e => setDataNasc(e.target.value)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  select
                  label="Sexo (obrigatório)"
                  fullWidth
                  value={sexo}
                  onChange={e => setSexo(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="">Selecione</MenuItem>
                  <MenuItem value="m">Masculino</MenuItem>
                  <MenuItem value="f">Feminino</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Naturalidade"
                  fullWidth
                  value={naturalidade}
                  onChange={e => setNaturalidade(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Nacionalidade"
                  fullWidth
                  value={nacionalidade}
                  onChange={e => setNacionalidade(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  select
                  label="Estado civil"
                  fullWidth
                  value={estadoCivil}
                  onChange={e => setEstadoCivil(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="">Selecione</MenuItem>
                  <MenuItem value="1">Solteiro(a)</MenuItem>
                  <MenuItem value="2">Casado(a)</MenuItem>
                  <MenuItem value="3">Separado(a)</MenuItem>
                  <MenuItem value="4">Divorciado(a)</MenuItem>
                  <MenuItem value="5">Viúvo(a)</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Profissão (obrigatório)"
                  fullWidth
                  value={profissao}
                  onChange={e => setProfissao(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Telefone"
                  fullWidth
                  value={telefone}
                  onChange={e =>
                    setTelefone(mask(unMask(e.target.value), ['(99)9999-9999']))
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Celular"
                  fullWidth
                  value={celular}
                  onChange={e =>
                    setCelular(mask(unMask(e.target.value), ['(99)99999-9999']))
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Login (obrigatório)"
                  fullWidth
                  value={login}
                  onChange={e => setLogin(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Tipo de usuário"
                  select
                  fullWidth
                  value={tipoConta}
                  onChange={e => setTipoConta(e.target.value)}
                  variant="outlined"
                >
                  {/* <MenuItem value="">Selecione</MenuItem> */}
                  <MenuItem value="1">Dentista</MenuItem>
                  <MenuItem value="3">Médico(a)</MenuItem>
                  <MenuItem value="2">Outros</MenuItem>
                </TextField>
              </Grid>
              {tipoConta === '1' && (
                <Grid item xs={3}>
                  <TextField
                    label="CRO"
                    fullWidth
                    value={cro}
                    onChange={e => setCro(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
              )}
              {tipoConta === '3' && (
                <Grid item xs={3}>
                  <TextField
                    label="CRM"
                    fullWidth
                    value={crm}
                    onChange={e => setCrm(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
              )}
              {tipoConta !== '2' && (
                <Grid item xs={3}>
                  <TextField
                    label="Comissão %"
                    type="number"
                    fullWidth
                    value={comissao}
                    onChange={e => setComissao(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
              )}
              <Grid item xs={3}>
                <TextField
                  label="RQE"
                  fullWidth
                  value={rqe}
                  onChange={e => setRqe(e.target.value)}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <h4 className={classes.titulos}>Endereço residencial</h4>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="cep">Cep</InputLabel>
                  <OutlinedInput
                    id="cep"
                    type="text"
                    value={cep}
                    onChange={e =>
                      setCep(mask(unMask(e.target.value), ['99999999']))
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={() => consultarCepR()} edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Logradouro"
                  fullWidth
                  value={logradouro}
                  onChange={e => setLogradouro(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  label="Número"
                  fullWidth
                  value={numero}
                  onChange={e => setNumero(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Bairro"
                  fullWidth
                  value={bairro}
                  onChange={e => setBairro(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Cidade"
                  fullWidth
                  value={cidade}
                  onChange={e => setCidade(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  label="Estado"
                  fullWidth
                  value={estado}
                  onChange={e => setEstado(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Complemento"
                  fullWidth
                  value={complemento}
                  onChange={e => setComplemento(e.target.value)}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <h4 className={classes.titulos}>Endereço profissional</h4>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="cep">Cep</InputLabel>
                  <OutlinedInput
                    id="cep"
                    type="text"
                    value={cep2}
                    onChange={e =>
                      setCep2(mask(unMask(e.target.value), ['99999999']))
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={() => consultarCepP()} edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Logradouro"
                  fullWidth
                  value={logradouro2}
                  onChange={e => setLogradouro2(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  label="Número"
                  fullWidth
                  value={numero2}
                  onChange={e => setNumero2(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Bairro"
                  fullWidth
                  value={bairro2}
                  onChange={e => setBairro2(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Cidade"
                  fullWidth
                  value={cidade2}
                  onChange={e => setCidade2(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  label="Estado"
                  fullWidth
                  value={estado2}
                  onChange={e => setEstado2(e.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Complemento"
                  fullWidth
                  value={complemento2}
                  onChange={e => setComplemento2(e.target.value)}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Paper elevation={2} className={classes.paper}>
              <h4 className={classes.titulos}>1. Permissões Agenda</h4>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={agenda}
                    control={<Checkbox color="primary" />}
                    onChange={() => setAgenda(!agenda)}
                    label="Agenda (Visível)"
                    labelPlacement="end"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={agenCad}
                    control={<Checkbox color="primary" />}
                    onChange={() => setAgenCad(!agenCad)}
                    label="1.1 Cadastar"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={agenAlt}
                    control={<Checkbox color="primary" />}
                    onChange={() => setAgenAlt(!agenAlt)}
                    label="1.2 Alterar"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={agenDel}
                    control={<Checkbox color="primary" />}
                    onChange={() => setAgenDel(!agenDel)}
                    label="1.3 Excluir"
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper elevation={2} className={classes.paper}>
              <h4 className={classes.titulos}>2. Permissões Paciente</h4>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={paciente}
                    control={<Checkbox color="primary" />}
                    onChange={() => setPaciente(!paciente)}
                    label="Paciente (Visível)"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={pacCad}
                    control={<Checkbox color="primary" />}
                    onChange={() => setPacCad(!pacCad)}
                    label="2.1 Cadastar"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={pacAlt}
                    control={<Checkbox color="primary" />}
                    onChange={() => setPacAlt(!pacAlt)}
                    label="2.2 Alterar"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={pacDes}
                    control={<Checkbox color="primary" />}
                    onChange={() => setPacDes(!pacDes)}
                    label="2.3 Ativar/Desativar"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={pacAtes}
                    control={<Checkbox color="primary" />}
                    onChange={() => setPacAtes(!pacAtes)}
                    label="2.4 Emitir Atestado"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={pacRec}
                    control={<Checkbox color="primary" />}
                    onChange={() => setPacRec(!pacRec)}
                    label="2.5 Emitir Receituário"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={anexoCad}
                    control={<Checkbox color="primary" />}
                    onChange={() => setAnexoCad(!anexoCad)}
                    label="2.6 Inserir Anexo"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={anexoDel}
                    control={<Checkbox color="primary" />}
                    onChange={() => setAnexoDel(!anexoDel)}
                    label="2.7 Excluir Anexo"
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper elevation={2} className={classes.paper}>
              <h4 className={classes.titulos}>3. Permissões Funcionário</h4>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={funcionario}
                    control={<Checkbox color="primary" />}
                    onChange={() => setFuncionario(!funcionario)}
                    label="Funcionário (Visível)"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={funcCad}
                    control={<Checkbox color="primary" />}
                    onChange={() => setFuncCad(!funcCad)}
                    label="3.1 Cadastar"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={funcAlt}
                    control={<Checkbox color="primary" />}
                    onChange={() => setFuncAlt(!funcAlt)}
                    label="3.2 Alterar"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={funcDes}
                    control={<Checkbox color="primary" />}
                    onChange={() => setFuncDes(!funcDes)}
                    label="3.3 Ativar/Desativar"
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper elevation={2} className={classes.paper}>
              <h4 className={classes.titulos}>4. Permissões Orçamento</h4>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={orcamento}
                    control={<Checkbox color="primary" />}
                    onChange={() => setOrcamento(!orcamento)}
                    label="Orçamento (Visível)"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={orcCad}
                    control={<Checkbox color="primary" />}
                    onChange={() => setOrcCad(!orcCad)}
                    label="4.1 Cadastar"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={orcAlt}
                    control={<Checkbox color="primary" />}
                    onChange={() => setOrcAlt(!orcAlt)}
                    label="4.2 Alterar"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={orcDesabilitarValores}
                    control={<Checkbox color="primary" />}
                    onChange={() =>
                      setOrcDesabilitarValores(!orcDesabilitarValores)
                    }
                    label="4.3 Desabilitar Valores"
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper elevation={2} className={classes.paper}>
              <h4 className={classes.titulos}>5. Permissões Procedimento</h4>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <FormControlLabel
                    checked={procedimento}
                    control={<Checkbox color="primary" />}
                    onChange={() => setProcedimento(!procedimento)}
                    label="Procedimento (Visível)"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    checked={procCad}
                    control={<Checkbox color="primary" />}
                    onChange={() => setProcCad(!procCad)}
                    label="5.1 Cadastar"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    checked={procAlt}
                    control={<Checkbox color="primary" />}
                    onChange={() => setProcAlt(!procAlt)}
                    label="5.2 Alterar/Desativar"
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper elevation={2} className={classes.paper}>
              <h4 className={classes.titulos}>6. Permissões Financeiro</h4>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={financeiro}
                    control={<Checkbox color="primary" />}
                    onChange={() => setFinanceiro(!financeiro)}
                    label="Financeiro (Visível)"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={finCad}
                    control={<Checkbox color="primary" />}
                    onChange={() => setFinCad(!finCad)}
                    label="6.1 Cadastar"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={finAlt}
                    control={<Checkbox color="primary" />}
                    onChange={() => setFinAlt(!finAlt)}
                    label="6.2 Alterar"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    checked={finDes}
                    control={<Checkbox color="primary" />}
                    onChange={() => setFinDes(!finDes)}
                    label="6.3 Desativar"
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper elevation={2} className={classes.paper}>
              <h4 className={classes.titulos}>7. Permissões Estoque</h4>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <FormControlLabel
                    checked={estoque}
                    control={<Checkbox color="primary" />}
                    onChange={() => setEstoque(!estoque)}
                    label="Estoque (Visível)"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    checked={estCad}
                    control={<Checkbox color="primary" />}
                    onChange={() => setEstCad(!estCad)}
                    label="7.1 Cadastar"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    checked={estAlt}
                    control={<Checkbox color="primary" />}
                    onChange={() => setEstAlt(!estAlt)}
                    label="7.2 Alterar/Desativar"
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper elevation={2} className={classes.paper}>
              <h4 className={classes.titulos}>8. Permissões Convênio</h4>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <FormControlLabel
                    checked={convenio}
                    control={<Checkbox color="primary" />}
                    onChange={() => setConv(!convenio)}
                    label="Convênio (Visível)"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    checked={convCad}
                    control={<Checkbox color="primary" />}
                    onChange={() => setConvCad(!convCad)}
                    label="8.1 Cadastar"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    checked={convAlt}
                    control={<Checkbox color="primary" />}
                    onChange={() => setConvAlt(!convAlt)}
                    label="8.2 Alterar/Desativar"
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper elevation={2} className={classes.paper}>
              <h4 className={classes.titulos}>9. Permissões Empresa</h4>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <FormControlLabel
                    checked={empresa}
                    control={<Checkbox color="primary" />}
                    onChange={() => setEmpresa(!empresa)}
                    label="Dados da Empresa (Visível)"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    checked={empAlt}
                    control={<Checkbox color="primary" />}
                    onChange={() => setEmpAlt(!empAlt)}
                    label="9.1 Cadastar/Alterar"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    checked={relatorios}
                    control={<Checkbox color="primary" />}
                    onChange={() => setRelatorios(!relatorios)}
                    label="9.2 Relatórios"
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper elevation={2} className={classes.paper}>
              <h4 className={classes.titulos}>10. Centro de Custo</h4>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <FormControlLabel
                    checked={centroCusto}
                    control={<Checkbox color="primary" />}
                    onChange={() => setCentroCusto(!centroCusto)}
                    label="Centro de Custo (Visível)"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    checked={centroCad}
                    control={<Checkbox color="primary" />}
                    onChange={() => setCentroCad(!centroCad)}
                    label="10.1 Cadastar"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    checked={centroAlt}
                    control={<Checkbox color="primary" />}
                    onChange={() => setCentroAlt(!centroAlt)}
                    label="10.2 Alterar"
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper elevation={2} className={classes.paper}>
              <h4 className={classes.titulos}>11. Ordem de Serviço</h4>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <FormControlLabel
                    checked={ordemServico}
                    control={<Checkbox color="primary" />}
                    onChange={() => setOrdemServico(!ordemServico)}
                    label="Ordem de Serviço (Visível)"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    checked={osCad}
                    control={<Checkbox color="primary" />}
                    onChange={() => setOsCad(!osCad)}
                    label="11.1 Cadastar"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    checked={osAlt}
                    control={<Checkbox color="primary" />}
                    onChange={() => setOsAlt(!osAlt)}
                    label="11.2 Alterar"
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
            </Paper>
          </TabPanel>

          {/* confirmar  reset senha/desativar conta */}
          <Dialog
            fullScreen={fullScreen}
            open={modalDesativarResetar}
            onClose={() => setModalDesativarResetar(false)}
            aria-labelledby="responsive-dialog-title"
          >
            {execFuncao === 1 && (
              <DialogTitle id="responsive-dialog-title">
                {'Desativar usuário'}
              </DialogTitle>
            )}
            {execFuncao === 2 && (
              <DialogTitle id="responsive-dialog-title">
                {'Resetar senha'}
              </DialogTitle>
            )}
            {execFuncao === 3 && (
              <DialogTitle id="responsive-dialog-title">
                {'Ativar usuário'}
              </DialogTitle>
            )}
            <DialogContent>
              {execFuncao === 1 && (
                <DialogContentText id="alert-dialog-slide-description">
                  Tem certeza que deseja desativar conta do(a){' '}
                  <b>{Funcionario.nome}</b> ?
                </DialogContentText>
              )}
              {execFuncao === 2 && (
                <DialogContentText id="alert-dialog-slide-description">
                  Tem certeza que deseja resetar senha do(a){' '}
                  <b>{Funcionario.nome}</b> ?
                </DialogContentText>
              )}
              {execFuncao === 3 && (
                <DialogContentText id="alert-dialog-slide-description">
                  Tem certeza que deseja ativar conta do(a){' '}
                  <b>{Funcionario.nome}</b> ?
                </DialogContentText>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={desativarResetarConta}
                disabled={desabilitar}
                variant="contained"
                color="primary"
              >
                Sim
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setModalDesativarResetar(false)}
              >
                Não
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
      <Notificacao
        aviso={aviso}
        fecharAviso={fecharAviso}
        tipoAviso={tipoAviso}
        mensagemAviso={mensagemAviso}
      />
    </div>
  );
}
